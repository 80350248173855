import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import axios from 'axios';
import moment from 'moment';
import DatePicker from 'react-datepicker/es';
import Mapa from '../../Mapa';
import './style.css';

class AddClass extends Component {
    constructor(props) {
        super(props);
        this.state = {
            step : 1,
            default: new Date(moment().format('YYYY-MM-DD HH:mm:ss')),
            start: new Date(moment().format('YYYY-MM-DD HH:00')),
            duration: 60,
            instructor: 1,
            instructors: [],
            places: 10,
            isOnline: 0,
            studio: 0,
            studios: [],
            maxPlaces: 30,
            pickerTimes: [],
            errors: {
                start: false,
                instructor: false,
                places: false,
                studio: false
            },
            category: 1,
            categories : [],
            blocked : [],
            estudioId: 0
        };

        this.changeHandler = this.changeHandler.bind(this);
        this.changeHandlerEstudio = this.changeHandlerEstudio.bind(this)
        this.save = this.save.bind(this);
        this.blockPlace = this.blockPlace.bind(this)
    }

    componentDidMount() {
        setTimeout(() => {
            this.setStudios();
            this.setInstructors();
            this.getCategories()
        }, 1000)
    }

    async setStudios() {
        let studios = this.props.studios || [];
        if(studios.length > 0)
            this.setState({ studios: this.props.studios });
        else
            await axios.get('/api/studio/getSelectStudios').then(res => {
                if (res.status === 200) {
                    this.setState({
                        studios: res.data
                    });
                }
            });
    }

    async getCategories() {
        await axios.get('/api/user/categories').then(res => {
            if (res.status === 200) {
                this.setState({
                    categories: res.data.categories
                });
            }
        });
    }

    async setInstructors() {
        let instructors = this.props.instructors || [];
        if(instructors.length > 0)
            this.setState({ instructors: this.props.instructors });
        else
            await axios.get('/api/instructor/getSelectInstructors').then(res => {
                if (res.status === 200) {
                    this.setState({
                        instructors: res.data
                    });
                }
            });
    }

    setStart(date) {
        const times = this.getExcludedTimes(date);
        this.setState({
            pickerTimes: times,
            start: new Date(moment(date).format('YYYY-MM-DD HH:mm:00'))
        });
    }

    getExcludedTimes(date) {
        let times = [];
        let choose = moment(new Date(moment(date).format('YYYY-MM-DD')));
        for (const programedClass of this.props.events){
            let classStart = moment(programedClass.start);
            let classEnd = moment(programedClass.end);
            let days = classEnd.diff(choose,'days');
            if(days === 0) {
                let minutes = classEnd.diff(classStart, 'minutes') + 15;
                for (let t = 0; t <= minutes; t += 15) {
                    let time = moment(classStart).add(t, 'minutes');
                    if (time.isBetween(classStart, classEnd))
                        times.push(new Date(moment(time).format('YYYY-MM-DD HH:mm:00')));
                }
            }
        }
        return times;
    }

    changeHandler(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    changeHandlerEstudio(e) {
        let value = e.target.value.split("-");
        this.setState({
            [e.target.name]: value[0],
            places : value[1],
            estudioId : value[2]
        });
    }

    drawSelectDuration() {
        let options = [];
        for(let h = 0; h <= 3; h++)
            for(let m = 0; m < 60; m+=15)
                if(h > 0 || m > 0)
                    options.push(
                      <option key={(h * 60) + m} value={ (h * 60) + m}>
                          {h < 1
                            ? m + 'm'
                            : h + 'h ' +
                            (m > 0 ? m + ' m' : '')}
                      </option>
                    );

        return (<select className="select col-12"
                        name="duration"
                        id="duration"
                        onChange={this.changeHandler}
            >
            {options}
        </select>);
    }

    drawSelectStudios() {
        let studios = [...this.state.studios]
        let options = [];
        if (studios.length > 0)
            options = studios.map((el, index) => <option key={el.id} value={el.id + "-" + el.places + "-" + index}>{el.name + " - " + el.places + " lugares"}</option>);

        return (<select className="select col-12"
                        name="studio"
                        id="studio"
                        defaultValue={1}
                        onChange={this.changeHandlerEstudio}
        >
            <option value="0">Elige un estudio</option>
            {options}
        </select>);
    }

    drawSelectInstructors() {
        let instructors = [...this.state.instructors]
        let options = [];
        if (instructors.length > 0)
            options = instructors.map(el => <option key={el.id} value={el.id}>{el.name}</option>);

        return (<select className="select col-12"
                        name="instructor"
                        id="instructor"
                        defaultValue={1}
                        onChange={this.changeHandler}
        >
            {options}
        </select>);
    }

    drawSelectCategories() {
        let categories = [...this.state.categories]
        let options = [];
        if (categories.length > 0)
            options = categories.map(el => <option key={el.id} value={el.id}>{el.name}</option>);

        return (<select className="select col-12"
                        name="category"
                        id="category"
                        defaultValue={1}
                        onChange={this.changeHandler}
        >
            {options}
        </select>);
    }

    async nextStep(prev = false){
        let step = this.state.step;
        let nextStep = prev ? step - 1 : step + 1
        this.setState({
            step : nextStep
        })
    }

    async save() {
        let data = this.state;
        delete data.errors;
        delete data.maxPlaces;
        delete data.default;
        delete data.pickerTimes;
        delete data.instructors;
        delete data.studios;
        delete data.categories;
        delete data.estudioId;
        data.studio = data.isOnline === '1'
            ? 0
            : data.studio;
        await axios.post('/api/clase/create', data).then(res => {
            if (res.status === 200) {
                alert('La clase se ha programado');
                window.location = '';
            }
        });
    }

    async blockPlace(place){
        let blocked = [...this.state.blocked];
        let reservedPlace = blocked.indexOf(place);
        if(reservedPlace >= 0){
            blocked.splice(reservedPlace, 1)
        }
        else{
            blocked.push(place)
        }
        this.setState({
            blocked: blocked
        })
    }

    render() {

        const selectDuration = this.drawSelectDuration();
        const selectInstructors = this.state.instructors.length > 0
            ? this.drawSelectInstructors()
            : "Cargando...";
        const selectStudios = this.state.studios.length > 0
          ? this.drawSelectStudios()
          : "Cargando...";
        const selectCategories = this.state.categories.length > 0
            ? this.drawSelectCategories()
            : "Cargando...";

        return (<Modal show={this.props.show}
                       onHide={this.props.toggleModal}
        >
            <Modal.Header closeButton>
                <Modal.Title>Programar clase</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {this.state.step === 1 ?
                    <div className="row">
                        <div className="input-div col-8">
                            <label>Fecha y hora de inicio</label>
                            <DatePicker selected={this.state.start}
                                        todayButton="Hoy"
                                        minDate={this.state.default}
                                        onChange={date => this.setStart(date)}
                                        highlightDates={this.props.events.flatMap((programedClass) => {
                                            return programedClass.start
                                        })}
                                        showTimeSelect
                                        dateFormat="yyyy-MM-dd HH:mm"
                                        timeFormat="HH:mm"
                                        timeIntervals={15}
                                        excludeTimes={this.state.pickerTimes}
                                        shouldCloseOnSelect={false}
                            />
                        </div>
                        <div className="input-div col-4">
                            <label>Duración</label>
                            {selectDuration}
                            {this.state.userError ?
                                <div className="text-error-class">{this.state.userError}</div>
                                : this.state.nameExist ?
                                    <div className="text-error-class">{this.state.nameExist}</div>
                                    : null}
                        </div>
                        <div className="input-div col-12">
                            <label>Nombre de la clase</label>
                            <input type="text"
                                   id="name"
                                   name="name"
                                   placeholder="Clase principiante"
                                   maxLength={'40'}
                                   defaultValue={this.state.name}
                                   onChange={this.changeHandler}
                            />
                            {this.state.userError ?
                                <div className="text-error-class">{this.state.userError}</div>
                                : this.state.nameExist ?
                                    <div className="text-error-class">{this.state.nameExist}</div>
                                    : null}
                        </div>
                        <div className="input-div col-6">
                            <label>Instructor</label>
                            {selectInstructors}
                        </div>
                        <div className="input-div col-6">
                            <label>Categoria</label>
                            {selectCategories}
                        </div>
                        {this.state.category !== '2'
                            ? <div className="input-div col-6">
                                <label>Estudio</label>
                                {selectStudios}
                            </div>
                            : null}

                    </div>
                    :
                    <Mapa
                        order={this.state.studios[this.state.estudioId].ordering}
                        estudio={this.state.studio}
                        reserved={[]}
                        blocked={this.state.blocked}
                        name={this.state.studios[this.state.estudioId].name}
                        blockPlace={this.blockPlace}
                    />
                }
            </Modal.Body>
            <Modal.Footer>
                {this.state.step === 1 ?
                    <Button variant="light" onClick={this.props.toggleModal}>
                        Cancelar
                    </Button>
                    :
                    <Button variant="info" onClick={() => this.nextStep(true)}>
                        Anterior
                    </Button>
                }
                {this.state.step === 1 ?
                    <Button variant="info" onClick={() => this.nextStep()}>
                        Siguiente
                    </Button>
                    :
                    <Button variant="info" onClick={this.save}>
                        Programar
                    </Button>
                }
            </Modal.Footer>
        </Modal>);
    }
}

export default AddClass;
