import React, { Component } from "react";
import axios from "axios";
import qs from "query-string";
import Loader from "react-loader-spinner";

class PaymentPayPal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.initPayment();
  }

  addPayment = async (checkoutId, redirect) => {
    let dataPago = {
      username: this.props.username,
      pkg: this.props.selectedPackage
        ? this.props.selectedPackage
        : this.props.selectedMembership,
      subtotal: this.props.ammount,
      discount: this.props.discount,
      total: this.props.ammount - this.props.discount,
      code: this.props.discountID,
      currency: this.props.currency,
      description: this.props.description,
      paymentId: checkoutId,
      PayerID: null,
      paymentData: {
        paymentId: checkoutId,
      },
      paymentMethod: 1,
      isMembership: this.props.selectedPackage ? false : true,
      status: 0,
    };
    axios.post("/api/payment/addPayment", dataPago).then((response) => {
      window.location.href = redirect;
    });
  };

  initPayment = async () => {
    let items = [
      {
        name: this.props.description,
        currency: this.props.currency,
        quantity: 1,
        price: this.props.ammount,
      },
    ];
    if (this.props.discount) {
      items.push({
        name: "Descuento",
        currency: "MXN",
        quantity: 1,
        price: this.props.discount * -1,
      });
    }
    let dataDetail = {
      intent: "sale",
      payer: {
        payment_method: "paypal",
      },
      transactions: [
        {
          amount: {
            total: this.props.ammount - this.props.discount,
            currency: this.props.currency,
            details: {
              subtotal: this.props.ammount - this.props.discount,
              tax: "0",
              shipping: "0",
              handling_fee: "0",
              shipping_discount: "0",
              insurance: "0",
            },
          },
          item_list: {
            items: items,
          },
        },
      ],
      redirect_urls: {
        //"return_url": "https://test.bodybarre.com.mx/api/payment/successPaypal",
        //"cancel_url": "https://test.bodybarre.com.mx/api/payment/cancelPaypal"
        return_url: "http://localhost:8080/api/payment/successPaypal",
        cancel_url: "http://localhost:8080/api/payment/cancelPaypal",
        //"return_url": "https://admin.bodybarre.com.mx/api/payment/successPaypal",
        //"cancel_url": "https://admin.bodybarre.com.mx/api/payment/cancelPaypal"
      },
    };

    axios
      .post("/api/payment/getPaypalUrl", dataDetail)
      .then((response) => {
        let data = response.data.token;
        //console.log(response)
        this.addPayment(data.idPayment, data.url);
      })
      .catch((err) => {
        //console.log({ ...err }, 3)
      });
  };

  render() {
    return (
      <div className="packages">
        <Loader type="Oval" color="#01bcb3" height={100} width={100} />
      </div>
    );
  }
}

export default PaymentPayPal;
