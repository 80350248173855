import React, {Component} from 'react';
import {Col, Row, Modal} from 'react-bootstrap';
import Icon from '../../Icon';
import "./style.css";

class ModalClient extends Component {
    constructor(props){
        super(props);
        this.state = {
            username:'',
            email:'',
            password:'',
            userError: false,
            emailError: false,
            pwError: false,
            emailExist: false,
            nameExist: false
        };
        this.changeHandler = this.changeHandler.bind(this);
        this.getSubs = this.getSubs.bind(this);
        
    }
    componentDidMount() {
        this.setState({
            show: this.props.show, 
            close: this.props.close
        });
    }
    changeHandler(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    getSubs= async(id) =>{
        window.location.href="/subscription?id=" + id;
    }
    renderDash(){
        return (
            <Modal 
                className="modal-clients"
                show={this.props.show} 
                onHide={this.props.close}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{this.props.nameCategorie}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xs={12} md={4} lg={4} className='alightCenter'>
                            <Icon
                                iconName="PersonIcon"
                                color="#00bbb1"
                                width="150px"
                                height="150px"
                            />
                        </Col>
                        <Col xs={12} md={8} lg={8}>
                            <div className="label-cl">
                                Nombre:
                            </div>
                            <div className="input-cl">
                                {this.props.name}
                            </div>
                            <div className="label-cl">
                                Fecha de nacimiento:
                            </div>
                            <div className="input-cl">
                                {this.props.birthdate}
                            </div>
                            <div className="label-cl">
                                Miembro desde:
                            </div>
                            <div className="input-cl">
                                {this.props.dateClient}
                            </div>
                            <button 
                                className="subs-btn btn-normal"
                                onClick={(e) => this.getSubs(this.props.id)}
                            >
                                Subscripciones
                            </button>
                        </Col>
                    </Row>

                </Modal.Body>
                
          </Modal>
        );
    }

    render() {
        return this.renderDash();
    };
}

export default ModalClient;
