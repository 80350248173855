import React, {Component} from 'react';
import Sidebar from "../../components/Sidebar";
import Navigation from '../../components/Navigation/Navigation';
import "./styleDashboard.css";
import Home from "../Home";

class Dashboard extends Component {

    constructor(props){
        super(props);
        this.state = {
            loading: true
        };
    }

    renderDash(){
        return (
            <div className="content">
                <Navigation user={this.props.user}/>
                <Sidebar
                    active="home"
                    type={this.props.type}
                />
                <div className="main">
                    <h2>BodyBarre by JB Admin</h2>

                </div>
            </div>
        );
    }


    render() {
            return this.renderDash();
        };

}

export default Dashboard;
