import React, { Component } from "react";
import { Container, Col, Row } from "react-bootstrap";
import Header from "../../components/Header";
import PrePayment from "../../components/Packages/PrePayment";
import PaymentPayPal from "../../components/Packages/PaymentPayPal";
import PaymentCard from "../../components/Packages/PaymentCard";
import "./style.css";
import axios from "axios";
import UserNavBar from "../../components/UserNavBar";
import Swal from "sweetalert2";

class Packages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      packages: [],
      memberships: [],
      selectedPackage: null,
      selectedMembership: null,
      idSelected: null,
      paymentMethod: null,
      discount: 0,
      discountCode: "",
      discountable: true,
      discountError: false,
      discountSuccess: false,
      discountID: null,
      promo: null,
      loadingPromo: false,
      optionMenu: 0,
    };
  }

  componentDidMount() {
    this.getPackages();
    this.getMemberships();
  }

  changeDiscountCode = async (code) => {
    this.setState({
      discountCode: code,
    });
  };

  getDiscountCode = async () => {
    this.setState({
      loadingPromo: true,
    });
    let code = this.state.discountCode;
    let discountable = this.state.discountable;
    if (code === "") {
      this.setState({
        discountError: "ESCRIBE UN CÓDIGO",
        loadingPromo: false,
      });
      return false;
    }
    if (!discountable) {
      this.setState({
        discountError: "YA HAS APLICADO UN CÓDIGO DE DESCUENTO",
        loadingPromo: false,
      });
      return false;
    }
    let data = {
      user: this.props.username,
      code: this.state.discountCode,
    };
    await axios
      .post("/api/payment/validatePromo", data)
      .then((res) => {
        if (res.data.Success) {
          if (res.data.valid) {
            let promo = res.data.promo;
            this.setState({
              promo: promo,
              discount: promo.ammount
                ? promo.ammount
                : promo.percentage
                ? this.state.selectedPackage
                  ? (this.state.packages[this.state.idSelected].price *
                      promo.percentage) /
                    100
                  : (this.state.memberships[this.state.idSelected].price *
                      promo.percentage) /
                    100
                : 0,
              discountID: promo.id,
              discountable: false,
              discountError: false,
              discountSuccess: "SE HA APLICADO EL CÓDIGO",
              loadingPromo: false,
            });
          } else if (res.data.Error) {
            this.setState({
              discountError: res.data.Error,
              loadingPromo: false,
            });
          } else {
            this.setState({
              discountError: "EL CÓDIGO NO ES VALIDO",
              loadingPromo: false,
            });
          }
        }
      })
      .catch((error) => {
        this.setState({
          discountError: "EL CÓDIGO NO ES VALIDO",
          loadingPromo: false,
        });
      });
  };

  getPackages = async () => {
    await axios
      .get("/api/packages/all")
      .then((res) => {
        if (res.data.Success) {
          this.setState({ packages: res.data.packages });
        }
      })
      .catch((error) => {});
  };

  getMemberships = async () => {
    await axios
      .get("/api/packages/memberships")
      .then((res) => {
        if (res.data.Success) {
          this.setState({ memberships: res.data.memberships });
        }
      })
      .catch((error) => {});
  };

  choosePaymentMethod = async (method) => {
    this.setState({
      paymentMethod: method,
    });
  };

  choosePackage = async (pkg, idPkg) => {
    this.setState({
      selectedPackage: pkg,
      idSelected: idPkg,
    });
  };

  chooseMembership = async (pkg, idPkg) => {
    this.setState({
      selectedMembership: pkg,
      idSelected: idPkg,
    });
  };

  formatMoney = (n, c, d, t) => {
    c = isNaN((c = Math.abs(c))) ? 2 : c;
    d = d === undefined ? "." : d;
    t = t === undefined ? "," : t;
    if (isNaN(n)) {
      return n;
    }
    let s = n < 0 ? "-" : "";
    let i = String(parseInt((n = Math.abs(Number(n) || 0).toFixed(c))));
    let k = i.length;
    let j = k > 3 ? k % 3 : 0;
    return (
      s +
      (j ? i.substr(0, j) + t : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) +
      (c
        ? d +
          Math.abs(n - i)
            .toFixed(c)
            .slice(2)
        : "")
    );
  };

  showInfo(nombrePaquete, descripcion) {
    Swal.fire({
      icon: "info",
      title: nombrePaquete,
      text: descripcion,
    });
  }

  drawBuyOptions() {
    let botones = [];
    let menuOption = this.state.optionMenu;
    let packages = [...this.state.packages];
    let memberships = [...this.state.memberships];
    botones.push([
      <div style={{ textAlign: "left" }}>
        <button
          className="btn"
          onClick={() => {
            this.setState({ optionMenu: 0 });
          }}
        >
          REGRESAR
        </button>
      </div>,
      <br></br>,
    ]);
    botones.push(
      memberships.map((el, index) => {
        if (el.menu_option !== menuOption) {
          return null;
        }
        return [
          <button
            className="btn btn-buyable"
            onClick={() => this.chooseMembership(el.id, index)}
            type="button"
          >
            {el.name} por ${this.formatMoney(el.price, 2)}
          </button>,
          <i
            class="ml-2 fas fa-info-circle"
            onClick={() => this.showInfo(el.name, el.descriptionb)}
          ></i>,
        ];
      })
    );
    botones.push(
      packages.map((el, index) => {
        if (el.menu_option !== menuOption) {
          return null;
        }
        return [
          <button
            className="btn btn-buyable"
            onClick={() => this.choosePackage(el.id, index)}
          >
            {el.name} por ${this.formatMoney(el.price, 2)}
          </button>,
          <i
            class="ml-2 fas fa-info-circle"
            onClick={() => this.showInfo(el.name, el.descriptionb)}
          ></i>,
        ];
      })
    );
    return botones;
  }

  chooseOption(op) {
    this.setState({
      optionMenu: op,
    });
  }

  drawOptions() {
    return [
      <h4>¿Qué deseas comprar?</h4>,
      <button className="btn btn-buyable" onClick={() => this.chooseOption(1)}>
        {" "}
        Clases En Estudio
      </button>,
      <button className="btn btn-buyable" onClick={() => this.chooseOption(2)}>
        {" "}
        BodyBarre App
      </button>,
      <button className="btn btn-buyable" onClick={() => this.chooseOption(3)}>
        {" "}
        Clases Teens
      </button>,
      <button className="btn btn-buyable" onClick={() => this.chooseOption(4)}>
        {" "}
        Paquetes Mixtos
      </button>,
    ];
  }

  render() {
    return (
      <div className="user-site-container">
        <UserNavBar
          name={
            this.props.profile
              ? this.props.profile["profile.name"]
              : this.props.user
          }
        />
        <Container
          style={{
            width: "100%",
            maxWidth: "100%",
            minWidth: "100%",
          }}
        >
          <Row
            style={{
              marginLeft: "0",
              marginRight: "0",
            }}
          >
            <Col xs={12} md={12} lg={12}>
              <div className="user-site-content">
                <Header
                  clases={this.props.clases}
                  live={this.props.live}
                  membership={this.props.membership}
                  dueDate={this.props.dueDate}
                />
                {this.state.selectedPackage || this.state.selectedMembership ? (
                  !this.state.paymentMethod ? (
                    <PrePayment
                      choosePaymentMethod={this.choosePaymentMethod}
                      styles={this.props.styles}
                      promotionable={this.state.selectedMembership}
                      ammount={
                        this.state.selectedPackage
                          ? this.state.packages[this.state.idSelected].price
                          : this.state.memberships[this.state.idSelected].price
                      }
                      currency={"MXN"}
                      description={
                        this.state.selectedPackage
                          ? this.state.packages[this.state.idSelected].name
                          : this.state.memberships[this.state.idSelected].name
                      }
                      username={this.props.user}
                      discount={this.state.discount}
                      discountID={this.state.discountID}
                      discountCode={this.state.discountCode}
                      changeDiscountCode={this.changeDiscountCode}
                      getDiscountCode={this.getDiscountCode}
                      discountError={this.state.discountError}
                      discountSuccess={this.state.discountSuccess}
                      choosePackage={this.choosePackage}
                      chooseMembership={this.chooseMembership}
                      loading={this.state.loadingPromo}
                      selectedPackage={this.state.selectedPackage}
                      selectedMembership={this.state.selectedMembership}
                    />
                  ) : this.state.paymentMethod === "card" ? null : (
                    <PaymentPayPal
                      ammount={
                        this.state.selectedPackage
                          ? this.state.packages[this.state.idSelected].price
                          : this.state.memberships[this.state.idSelected].price
                      }
                      currency={"MXN"}
                      description={
                        this.state.selectedPackage
                          ? this.state.packages[this.state.idSelected]
                              .description
                          : this.state.memberships[this.state.idSelected]
                              .description
                      }
                      selectedPackage={this.state.selectedPackage}
                      selectedMembership={this.state.selectedMembership}
                      username={this.props.user}
                      discount={this.state.discount}
                      discountID={this.state.discountID}
                    />
                  )
                ) : (
                  <div className="packages">
                    {this.state.optionMenu
                      ? this.drawBuyOptions()
                      : this.drawOptions()}
                  </div>
                )}
              </div>

              <p className="brand-login text-dark">Powered by DigSerMX</p>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Packages;
