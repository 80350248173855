import React, {Component} from 'react';
import {Form, Button} from 'react-bootstrap';
import axios from 'axios';
import Loading from "../../Utils/Loading";

class Login extends Component {

    constructor(props){
        super(props);

        this.state = {
            loading: false,
            username: '',
            password: '',
            remember: false,
            errors: {
                general: '',
                username: '',
                password: ''
            }
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    validateInput(){
        let username = /^[a-zA-Z ]*$/;
        let minLength = 4;
        return ( username.test(this.state.username) && this.state.password.length >= minLength );
    }


    handleSubmit(e){
        e.preventDefault();

        if(this.validateInput()) {

            this.setState({loading: true});

            axios.post('/api/user/login', {
                user: this.state.username,
                pwd: this.state.password,
                device: {},
                remember: this.state.remember
            })
                .then(response => {
                    if (response.status === 200)
                        window.location.reload();
                })
                .catch(error => {
                    console.log('response-fail: '+ error.response);
                    this.setState({loading: false});
                });
        }
    }

    handleInputChange(e) {
        const target = e.target;
        const value = target.type === 'checkbox'
            ? target.checked
            : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    render() {

        const errorEmail = (this.state.errors.username !== '')
            ? <span className="invalid-feedback" role="alert">
                {this.state.errors.username}
            </span>
            : '';

        const errorPassword = (this.state.errors.password !== '')
            ? <span className="invalid-feedback" role="alert">
                {this.state.errors.password}
            </span>
            : '';

        const errors = (this.state.errors.general !== '')
            ? <span className="invalid-feedback" role="alert">
                {this.state.errors.general}
            </span>
            : '';

        const render = (
            <Form onSubmit={this.handleSubmit}>
                <Form.Group controlId="username">
                    <Form.Label>Correo</Form.Label>
                    <Form.Control
                        onChange={this.handleInputChange}
                        value={this.state.username}
                        name="username"
                        type="username"
                        placeholder="username" />
                    {errorEmail}
                </Form.Group>

                <Form.Group controlId="password">
                    <Form.Label>Contraseña</Form.Label>
                    <Form.Control
                        onChange={this.handleInputChange}
                        value={this.state.password}
                        name="password"
                        type="password"
                        placeholder="******" />
                    {errorPassword}
                    <span className="invalid-feedback" role="alert">
                    </span>
                </Form.Group>
                <Form.Group controlId="formBasicCheckbox">
                    <Form.Check
                        onChange={this.handleInputChange}
                        checked={this.state.remember}
                        name="remember"
                        type="checkbox"
                        label="Recordar mi sesión" />
                </Form.Group>
                <Button className="btn-block" variant="dark" type="submit">
                    Ingresar
                </Button>
            </Form>);

        return (<>
                {(this.state.loading) ? <Loading /> : ''}
                    {errors}
                    {render}
                </>);

    }
}

export default Login;
