import React, { Component } from "react";
import { Container, Col, Row } from "react-bootstrap";
import Menu from "../../components/Menu";
import Header from "../../components/Header";
import UserNavBar from "../../components/UserNavBar";
import { isMobile, isSafari } from "react-device-detect";
import "./style.css";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      b: false,
      params: {},
    };
  }

  componentDidMount() {
    this.searchParams();
  }

  searchParams() {
    let params = {};
    let parts = window.location.search.substring(1).split("&");
    for (let i = 0; i < parts.length; i++) {
      var nv = parts[i].split("=");
      if (!nv[0]) continue;
      params[nv[0]] = nv[1] || true;
      this.setState({
        [nv[0]]: nv[1],
      });
    }
  }

  render() {
    return (
      <div className="user-site-container">
        <UserNavBar
          name={
            this.props.profile
              ? this.props.profile["profile.name"]
              : this.props.user
          }
        />
        <Container
          style={{
            width: "100%",
            maxWidth: "100%",
            minWidth: "100%",
          }}
        >
          <Row
            style={{
              marginLeft: "0",
              marginRight: "0",
            }}
          >
            <Col xs={12} md={12} lg={12}>
              <div className="user-site-content">
                <Header
                  clases={this.props.clases}
                  live={this.props.live}
                  membership={this.props.membership}
                  dueDate={this.props.dueDate}
                />
                <Menu />
                {this.state.b ? (
                  <div>
                    <p className="thanks">GRACIAS POR TU COMPRA</p>
                    <p className="thanks-sm">
                      Tu compra se ha procesado con exito, ahora ya formas parte
                      de la familia BodyBarre by JB.
                    </p>
                    <p className="thanks-sm">
                      Si tienes alguna duda, por favor contáctanos en los
                      canales acostumbrados de Instagram o Whatsapp.
                    </p>
                    {isMobile && isSafari ? (
                      <button
                        className="btn btn-bb"
                        type="bytton"
                        onClick={this.openBodyBarre}
                      >
                        Ir a BodyBarre by JB
                      </button>
                    ) : null}
                  </div>
                ) : null}
              </div>
              <p className="brand-login text-dark">Powered by DigSerMX</p>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Home;
