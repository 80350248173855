import React, {Component} from 'react';
import './style.css'
import {Navbar, Nav, NavDropdown} from "react-bootstrap";

class UserNavBar extends Component {

    constructor(props) {
        super(props);


        this.logout = this.logout.bind(this);

    }

    async logout(e) {
        e.preventDefault();
        return fetch('/api/user/logout', {
            method: 'POST'
        })
            .then(async res => {
                const result = await res.json();
                if (result) {
                    this.logged = !!result;
                    window.location.reload();
                }
                return !!result;
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }

    render() {

        return (
            <Navbar collapseOnSelect expand="md" >
                <Navbar.Brand href="/home" className="navbar-user">
                    <img
                        alt=""
                        src="https://bbarrecnt.sfo3.cdn.digitaloceanspaces.com/app/logo_bb_white.png"
                        width="30"
                        height="30"
                        className="d-inline-block align-top"
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                        <Nav.Link href="/packages">Comprar</Nav.Link>
                        <Nav.Link href="/contact">Contacto</Nav.Link>

                    </Nav>
                    <Nav>
                        <NavDropdown title={"Bienvenido, " + this.props.name} id="basic-nav-dropdown">
                            <NavDropdown.Item href="/rules">Reglamento Interno</NavDropdown.Item>
                            <NavDropdown.Item href="/terms">Términos y Condiciones</NavDropdown.Item>
                            <NavDropdown.Item href="/privacy">Aviso de Privacidad</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item href="#logout" onClick={this.logout}>Cerrar Sesión</NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        );

    }
}

export default UserNavBar;
