import React, { Component } from 'react';
import {Nav, Navbar, NavDropdown} from 'react-bootstrap';
import './styleNavbar.css';

class Navigation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: this.props.user
        };

        this.logout = this.logout.bind(this);

    }

    async logout(e) {
        e.preventDefault();
        return fetch('/api/user/logout', {
            method: 'POST'
        })
          .then(async res => {
              const result = await res.json();
              if (result) {
                  this.logged = !!result;
                  window.location.reload();
              }
              return !!result;
          })
          .catch(error => {
              console.error('Error:', error);
          });
    }

    render() {
        return (
            <Navbar collapseOnSelect expand="sm" fixed="top" variant="dark">

                <Navbar.Brand href="/web">
                    <img
                        alt=""
                        src="https://bbarrecnt.sfo3.cdn.digitaloceanspaces.com/app/logo_bb_white.png"
                        width="30"
                        height="30"
                        className="d-inline-block align-top"
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto"></Nav>
                    <Nav>
                        <NavDropdown
                            title={this.props.user ? this.props.user : 'Erik'}
                            id="basic-nav-dropdown"
                        >
                            <NavDropdown.Item href="#action/3.1">Perfil</NavDropdown.Item>
                            <NavDropdown.Divider/>
                            <NavDropdown.Item onClick={this.logout}>Cerrar sesión</NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        );

    }
}

export default Navigation;
