import React, {Component} from 'react';
import Ionicon from "react-ionicons";
import {Col, Row} from 'react-bootstrap';
import ModalAdd from '../../Admin/ModalAdd';
import axios from 'axios';
import "./styleReceptionist.css";

class Receptionist extends Component {
    constructor(props){
        super(props);
        this.state = {
            loading: true,
            searchtext: '',
            receptionists: [],
            show: false,
            close: false,
            typeUser: 3
        };
        this.changeHandler = this.changeHandler.bind(this);
        this.searchUser = this.searchUser.bind(this);
        this.getAdmin = this.getAdmin.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.convertDateToString = this.convertDateToString.bind(this);
    }
    componentDidMount() {
        this.getAdmin();
    }
    changeHandler = async (e)=> {
        await this.setState({
            [e.target.name]: e.target.value
        });
        this.searchUser(this.state.searchtext);
    }
    searchUser = async (searchText) =>  {
        const body={
            clave: searchText,
            userType: this.state.typeUser
        }
        return await axios.post('/api/user/search', body)
            .then(res => {
                this.setState({receptionists: res.data.admin, loading: false})
            })
            .catch(err => {
                this.setState({loading: false})
            });
    }
    getAdmin= async () => {
        return await axios.post('/api/user/receptionists')
            .then(res => {
                this.setState({receptionists: res.data.admin, loading: false})
            })
            .catch(err => {
                this.setState({loading: false})
            });
    }
    convertDateToString(date) {
            var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;

        return [year, month, day].join('-');
    }
    handleShow(e){
        this.setState({show: true})
    }
    handleClose(e){
        this.setState({show: false})
    }
    renderObject(){
        if (this.state.receptionists.length === 0) return null;
        let clients = this.state.receptionists;
        return clients.map((item, index) => {
        return(
            <tr key={index}>
                <td>{index + 1}</td>
                <td>{item.username}</td>
                <td>{item.email}</td>
                <td>{this.convertDateToString(item.created_at)}</td>
            </tr>
            )
        });
    }
    renderDash(){
        return (
            <div className="main">
                <h4>Recepcionistas</h4>
                <Row>
                    <Col xs={12} md={6} lg={6}>
                        <Ionicon
                            icon="md-search"
                            color="#bbb"
                            fontSize="20px"
                            className="search-icon"
                        />
                        <input
                            type="text"
                            placeholder="Buscar..."
                            className="search"
                            name="searchtext"
                            maxlenght="50"
                            defaultValue={this.state.searchtext}
                            onChange={this.changeHandler}
                        />
                    </Col>
                    <Col xs={12} md={6} lg={6} className="alignRight">
                        <button 
                            className="btn-normal add"
                            onClick={this.handleShow}
                        >
                            Agregar
                        </button>
                        <ModalAdd
                            name="Recepcionista"
                            show={this.state.show}
                            close={this.handleClose}
                            typeUser={this.state.typeUser}
                        />
                    </Col>
                </Row>
                <div className="table-content">
                    <table>
                        <thead>
                            <tr>
                                <th></th>
                                <th>
                                    Nombre
                                </th>
                                <th>
                                    Email
                                </th>
                                <th>
                                    Activo
                                </th>
                                
                            </tr>
                        </thead>
                        <tbody>
                            {this.renderObject()}
                        </tbody>
                    </table>
                </div>
                <hr/>
                
            </div>
        );
    }

    render() {
        return this.renderDash();
    };
}

export default Receptionist;
