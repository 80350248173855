import React, {Component} from 'react';
import {Container, Col, Row} from 'react-bootstrap';
import SignUpUserForm from '../../components/Forms/SignUpUserForm';
import './style.css'

class SignUpUser extends Component {

    render() {

        return (
            <div className="user-login-container">
                <Container
                    style={{
                        width: '100%',
                        maxWidth: '100%',
                        minWidth: '100%'
                    }}
                >
                    <Row
                        style={{
                            marginLeft: '0',
                            marginRight: '0'
                        }}
                    >
                        <Col xs={12} md={12} lg={12}>
                            <div className="user-login">
                                <img src="https://bbarrecnt.sfo3.cdn.digitaloceanspaces.com/app/logo_bb.png" alt="BodyBarre"/>
                                <SignUpUserForm />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        );

    }
}

export default SignUpUser;
