import React, { Component } from "react";
import { Container, Col, Row } from "react-bootstrap";
import LoginForm from "../../components/Forms/Login/LoginForm";
import "./style.css";

class Login extends Component {
  render() {
    return (
      <div className="login-container">
        <Container
          style={{
            width: "100%",
            maxWidth: "100%",
            minWidth: "100%",
          }}
        >
          <Row
            style={{
              marginLeft: "0",
              marginRight: "0",
            }}
          >
            <Col xs={12} md={12} lg={12}>
              <div className="login">
                <img
                  src="https://bbarrecnt.sfo3.cdn.digitaloceanspaces.com/app/logo_bb_white.png"
                  alt="BodyBarre"
                />
                <h3>Admin</h3>
                <LoginForm />
              </div>
              <p className="brand-login text-dark">Powered by DigSerMX</p>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Login;
