import React, {Component} from 'react';
import {Container, Col, Row} from 'react-bootstrap';
import './style.css'
import RecoverPasswordForm from "../../components/Forms/RecoverPasswordForm";
import getParams from "../../components/SearchParams/SearchParams";

class RecoverPassword extends Component {

    constructor(props) {
        super();
        this.state = {
            token : null
        }
    }

    componentDidMount(){
        const params = getParams();
        if(params.t){
            this.setState({
                token : params.t
            })
        }
    }

    render() {

        return (
            <div className="user-login-container">
                <Container
                    style={{
                        width: '100%',
                        maxWidth: '100%',
                        minWidth: '100%'
                    }}
                >
                    <Row
                        style={{
                            marginLeft: '0',
                            marginRight: '0'
                        }}
                    >
                        <Col xs={12} md={12} lg={12}>
                            <div className="user-login">
                                <img src="https://bbarrecnt.sfo3.cdn.digitaloceanspaces.com/app/logo_bb.png" alt="BodyBarre"/>
                                <RecoverPasswordForm
                                    token={this.state.token}
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        );

    }
}

export default RecoverPassword;
