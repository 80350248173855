import React, {Component} from 'react';
import './style.css'
import {Link} from 'react-router-dom'

class Menu extends Component {



    render() {

        return (
            <div className="menu">
                <button
                    className="btn btn-menu"
                >
                    <Link to={"/packages"}>COMPRAR</Link>
                </button>
            </div>
        );

    }
}

export default Menu;
