import React, { Component } from 'react';
import {Col, Form, Row, ButtonGroup} from "react-bootstrap";
import MUIDataTable from "mui-datatables";
import axios from 'axios';
import Button from "@material-ui/core/Button"; 
import Swal from 'sweetalert2';

class ClasesPresenciales extends Component{
    constructor(props){
        super(props);
        this.months = ['','Enero','Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre']
        this.state = {
            clases : [],
            year: new Date().getFullYear(),
            month: (new Date().getMonth() + 1)
        }
    }

    drawYears(){
        let options = [];
        let currYear = new Date().getFullYear();
        for (let index = currYear; index >= 2021; index--) {
            options.push(
                <option key={index} value={index}>{index}</option>
            )
            
        }
        return options;
    }

    drawMonths(){
        let options = [];
        let currMonth = (new Date().getMonth() + 1);
        let limit = (this.state.year == 2021) ? 2 : 1 ;
        for (let index = currMonth; index >= limit; index--) {
            options.push(
                <option key={index} value={index}>{this.months[index]}</option>
            )
        }
        return options;

    }

    componentDidMount(){
        this.getClases();
    }

    getClases = async() => {
        let year = this.state.year;
        let month = this.state.month;
        let classReport = null;

        await axios.post('/api/clase/getAllClasses',{ year: year, month:month})
            .then( res => {
                classReport = res.data.data[0];
                this.setState({
                    clases: classReport
                });
            })
            .catch(err => {
                this.setState({
                    clases:[]
                });
            })
    }

    handleChangeYear = (e) => {
        this.setState({
            year: e.currentTarget.value
        })
    }
    handleChangeMonth = (e) => {
        this.setState({
            month: e.currentTarget.value
        })
    }

    muestraDetalleClases = async(instructor, name) => {
        let year = this.state.year;
        let month = this.state.month;
        await axios.post('/api/reportes/getAllInstructorClassesPerMonth',{ year:year, month:month, instructor:instructor })
        .then(res=>{
            if(res.status == 200){
                var count = 0;
                var table = '';
                
                for (let index = 0; index < res.data.data[0].length; index++) {
                    const element = res.data.data[0][index];
                    table += "<tr><td>"+(index + 1)+"</td><td style=\"text-align:left;\">"+element.clases+"</td><td style=\"text-align:left;\">"+element.name+"</td><td>"+element.TOTALES+"</td><td>"+element.RESERVADAS+"</td><td>"+element.TOMADAS+"</td><td>"+element.CANCELADAS+"</td></tr>";
                }
                
                Swal.fire({
                    title: 'Clases impartidas durante ' + this.months[month] + ' ' + year + ' por ' + name,
                    html: '<div class="table-responsive" style="height:100%;"><table class="table table-sm"><thead><tr><th></th><th>Clase</th><th>Estudio</th><th>Registros</th><th>Reservadas</th><th>Tomadas</th><th>Canceladas</th></tr></thead><tbody>'+table+'</tbody></table></div>',
                    confirmButtonText: '<i class="fa fa-thumbs-up"></i> Cerrar',
                    customClass:'swal-height'
                });
            }else{
                Swal.fire('Ha ocurrido un error', 'Por favor intentalo de nuevo', 'error');
            }
        })
        .catch( err => {
            Swal.fire('Ha ocurrido un error', 'Por favor intentalo de nuevo', 'error');
        })
    }

    renderObject = () =>{let clases = this.state.clases;
        let data = [];
        if( this.state.clases.length === 0){
            return null;
        }else{
            for (let index = 0; index < clases.length; index++) {
                data.push([
                    index+1,
                    clases[index].fullname,
                    clases[index].num_clases,
                    clases[index].clases,
                    clases[index].id_instructor+','+clases[index].fullname
                ]);
            }
        }

        

        
        const columns = ['#', 'Nombre Maestra', 'Clases en el mes', 'Fechas/Horario de clase',
                    {
                        name: 'Detalle',
                        options:{
                            download:false,
                            customBodyRender: (value, tableMeta, updateValue) => {
                                var [id, nombre] = value.split(',');
                                return (
                                    <ButtonGroup>
                                        <Button variant="outlined" color="primary" onClick={(e) => this.muestraDetalleClases(id,nombre)}>
                                            {'Vistas'}
                                        </Button>
                                    </ButtonGroup>
                                    
                                );
                            }
                        }
                    }];
        const options = {
            filter: true,
            filterType: "dropdown",
            selectableRows: 'none',
            rowHover: true,
            print: false,
            download: false,  
            responsive: 'simple',
            tableBodyHeight:"",
            tableBodyMaxHeight:"",
            
            

            textLabels: {
                body: {
                  noMatch: "No se encontraron registros",
                  toolTip: "Ordenar",
                  columnHeaderTooltip: column => `Ordenar por ${column.label}`
                },
                pagination: {
                  next: "Siguiente página",
                  previous: "Página anterior",
                  rowsPerPage: "Registros por página:",
                  displayRows: "de",
                },
                toolbar: {
                  search: "Buscar",
                  downloadCsv: "Descargar CSV",
                  print: "Print",
                  viewColumns: "Ver columnas",
                  filterTable: "Filtrar tabla",
                },
                filter: {
                  all: "TODOS",
                  title: "FILTROS",
                  reset: "RESET",
                },
                viewColumns: {
                  title: "Mostrar columnas",
                  titleAria: "Mostrar/Ocultar columnas",
                },
                selectedRows: {
                  text: "Registro(s) seleccionado(s)",
                  delete: "Delete",
                  deleteAria: "Delete Selected Rows",
                },
            }
        };
        return (
            <MUIDataTable
            title={""}
            data={data}
            columns={columns}
            options={options}></MUIDataTable>
        );
    }

    render(){
        return(
            <div className="main">
                <h4 className="client">Reporte Clases por maestra </h4>
                <Row>
                    <Col xs={12} md={4} lg={2}>
                        <Form.Group controlId='year'>
                            <span>Año: </span>
                            <select name="year" id="year" className="form-control" onChange={this.handleChangeYear}>
                                { this.drawYears() }
                            </select>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={4} lg={2}>
                        <Form.Group controlId='month'>
                            <span>Mes: </span>
                            <select name="month" id="month" className="form-control" onChange={this.handleChangeMonth}>
                                { this.drawMonths() }
                            </select>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={4} lg={2}>
                        <br></br>
                        <button className="btn btn-success btn-block" onClick={(e)=>this.getClases()}>
                            Buscar
                        </button>
                    </Col>
                </Row>
                <Row className="reporte" style={ { height:"90%", width:'98%',display: 'table', tableLayout:'fixed' } }>
                    {this.renderObject()}
                </Row>
            </div>
        )
    }
}

export default ClasesPresenciales;