import React, { Component } from 'react';
import axios from 'axios';
import {ButtonGroup, Row} from "react-bootstrap";
import Swal from "sweetalert2";
import MUIDataTable from "mui-datatables";
import Button from "@material-ui/core/Button";  

class vistasComentarios extends Component{
    constructor(props) {
        super(props);
        this.state = {
            analisis:[]
        };
    }

    componentDidMount() {
        this.analisis()
    }

    analisis = async() => {
        await axios.post('/api/reportes/analisisVideo')
            .then(res=>{
                if(res.status == 200){
                    this.setState({analisis: res.data});
                }else{
                    this.setState({analisis: []});
                }
            })
            .catch(err=>{
                this.setState({analisis: []});
            });
    }

    parseInttoMonth = (date) => {
        var months = { '01' : 'Ene', '02': 'Feb', '03' : 'Mar', '04' : 'Abr', '05' : 'May', '06' : 'Jun', '07' : 'Jul', '08' : 'Ago', '09' : 'Sep', '10' : 'Oct', '11' : 'Nov', '12' : 'Dic'}
        var date = new Date(date)
        var anno = date.getFullYear();
        var mes = ((date.getMonth() + 1) < 10) ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1) ;
        var day = ((date.getDate() ) < 10) ? '0' + (date.getDate() ) : (date.getDate() ) ;
        var hour = ((date.getHours() ) < 10) ? '0' + (date.getHours() ) : (date.getHours() ) ;
        var min = ((date.getMinutes() ) < 10) ? '0' + (date.getMinutes() ) : (date.getMinutes() ) ;
        var sec = ((date.getSeconds() ) < 10) ? '0' + (date.getSeconds() ) : (date.getSeconds() ) ;
        
        return day + "/" + months[mes] + "/" + anno;
    }

    muestraVisitas = async(id, clase)=>{

        await axios.post('/api/reportes/reproducciones/'+id)
            .then(res=>{
                if (res.status == 200) {
                    var count = 0;
                    var table='';
                    for (let index = 0; index < res.data.length; index++) {
                        table += "<tr><td>"+(index + 1)+"</td><td style=\"text-align:left;\">"+res.data[index].nombre+"</td><td style=\"text-align:left;\">"+res.data[index].email+"</td><td>"+res.data[index].repro+"</td></tr>";
                        
                    }
                    Swal.fire({
                        title: 'Reproducciones del video ' + clase,
                        html: '<div class="table-responsive" style="height:100%;"><table class="table table-sm"><thead><tr><th></th><th>Nombre</th><th>Email</th><th>Reproducciones</th></tr></thead><tbody>'+table+'</tbody></table></div>',
                        confirmButtonText: '<i class="fa fa-thumbs-up"></i> Cerrar',
                        customClass:'swal-height'
                    });
                }
            })
            .catch(err=>{
                Swal.fire({
                    icon:'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                });
            });
    }
    
    muestraDetalleComentarios = async(id,clase)=>{
        await axios.post('/api/reportes/comentarios/' + id)
            .then(res=>{
                if (res.status == 200) {
                    var table = '';
                    for (let index = 0; index < res.data.length; index++) {
                        table += "<tr><td>"+(index + 1)+"</td><td style=\"text-align:left;\">"+res.data[index].nombre+"</td><td style=\"text-align:left;\">"+res.data[index].email+"</td><td style=\"text-align:left;\">"+res.data[index].comment+"</td></tr>";
                    }
                
                    Swal.fire({
                        title: 'Comentarios del video ' + clase,
                        html: '<div class="table-responsive" style="height:100%;"><table class="table table-sm"><thead><tr><th></th><th>Nombre</th><th>Email</th><th>Comentario</th></tr></thead><tbody>'+table+'</tbody></table></div>',
                        confirmButtonText: '<i class="fa fa-thumbs-up"></i> Cerrar',
                        width: '800px',
                        customClass:'swal-height'
                    });
                }
            })
            .catch(err=>{
                Swal.fire({
                    icon:'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                });
            });
    } 
    muestraDetallePromedio = async(id,clase)=>{
        await axios.post('/api/reportes/rate/'+id)
            .then(res=>{
                if (res.status == 200) {
                    var table = '';
                    for (let index = 0; index < res.data.length; index++) {
                        table += "<tr><td>"+(index + 1)+"</td><td style=\"text-align:left;\">"+res.data[index].nombre+"</td><td style=\"text-align:left;\">"+res.data[index].email+"</td><td>"+res.data[index].rated+"</td><td style=\"text-align:left;\">"+res.data[index].comment+"</td></tr>";
                    }
                
                    Swal.fire({
                        title: 'Rate del video ' + clase,
                        html: '<div class="table-responsive" style="height:100%;"><table class="table table-sm"><thead><tr><th></th><th>Nombre</th><th>Email</th><th>Calificaci&oacute;n</th><th>Comentario</th></tr></thead><tbody>'+table+'</tbody></table></div>',
                        confirmButtonText: '<i class="fa fa-thumbs-up"></i> Cerrar',
                        width: '800px',
                        customClass:'swal-height'
                    });
                }
            })
            .catch(err=>{
                Swal.fire({
                    icon:'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                });
            });
    }

    renderObject(){
        if (this.state.analisis.length === 0) {
            return null;
        }
        let analisis = this.state.analisis;
        let data = [];

        for (let index = 0; index < analisis.length; index++) {
            const element = analisis[index];
            data.push([
                this.parseInttoMonth(analisis[index].fecha),
                analisis[index].clase,
                analisis[index].maestra,
                analisis[index].vistas,
                analisis[index].nComentarios,
                analisis[index].promedio,
                analisis[index].id+','+analisis[index].clase
            ]);
            
        }

        const columns = ["Fecha", "Clase", "Instructor", "Reproducciones", "Comentarios", "Calificación",{
            name: "Ver",
            options: {
                download: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                var [id, clase] = value.split(',');
                return (
                    <ButtonGroup>
                        <Button variant="outlined" color="primary" onClick={(e) => this.muestraVisitas(id, clase)}>
                            {'Vistas'}
                        </Button>
                        <Button variant="outlined" color="secondary" onClick={(e) => this.muestraDetalleComentarios(id, clase)}>
                            {'Comentarios'}
                        </Button>
                        <Button variant="outlined" color="warning" onClick={(e) => this.muestraDetallePromedio(id, clase)}>
                            {`Rate`}
                        </Button>
                    </ButtonGroup>
                    
                );
              }
            }
          }];

        const options = {
            filter: true,
            filterType: "dropdown",
            selectableRows: 'none',
            rowHover: true,
            print: false,
            download: true,  
            responsive: 'simple',
            tableBodyHeight:"",
            tableBodyMaxHeight:"",
            
            downloadOptions: {
                filename: 'Reportes-VistasComentarios.csv',
                filterOptions: {
                  useDisplayedColumnsOnly: true,
                  useDisplayedRowsOnly: true,
                }
            },

            textLabels: {
                body: {
                  noMatch: "No se encontraron registros",
                  toolTip: "Ordenar",
                  columnHeaderTooltip: column => `Ordenar por ${column.label}`
                },
                pagination: {
                  next: "Siguiente página",
                  previous: "Página anterior",
                  rowsPerPage: "Registros por página:",
                  displayRows: "de",
                },
                toolbar: {
                  search: "Buscar",
                  downloadCsv: "Descargar CSV",
                  print: "Print",
                  viewColumns: "Ver columnas",
                  filterTable: "Filtrar tabla",
                },
                filter: {
                  all: "TODOS",
                  title: "FILTROS",
                  reset: "RESET",
                },
                viewColumns: {
                  title: "Mostrar columnas",
                  titleAria: "Mostrar/Ocultar columnas",
                },
                selectedRows: {
                  text: "Registro(s) seleccionado(s)",
                  delete: "Delete",
                  deleteAria: "Delete Selected Rows",
                },
            }
        };

        return (
            <MUIDataTable
                title={""}
                data={data}
                columns={columns}
                options={options}
            />
        );
    }

    render(){
        return (
            <div className="main">
                <h4 className="client">Vistas y Comentarios</h4>
                <Row className="reporte" style={ { height:"90%", width:'98%',display: 'table', tableLayout:'fixed' } }>
                    {this.renderObject()}
                </Row>
            </div>);
    }
}

export default vistasComentarios;
