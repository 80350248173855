import React, {Component} from 'react';
import 'react-big-calendar/lib/css/react-big-calendar.css'
import './style.css'
import axios from 'axios'

class EventElement extends Component {

    constructor(props){
        super(props);
        this.state = {
            link : null
        }
        this.handleInput = this.handleInput.bind(this)
        this.saveLink = this.saveLink.bind(this)
    }

    handleInput = async (e) => {
        let name = e.target.name;
        let value = e.target.value;
        this.setState({
            [name] : value
        })
    }

    saveLink = async () => {
        let data = {
            clase : this.props.clase,
            link : this.state.link
        }
        await axios.post('/api/clase/setTransmition', data).then( (res) => {
            if(res.data.Success  && res.data.inserted){
                this.props.getClassInfo()
                this.props.triggerPreparar()
            }
        })
    }

    render() {
        return(
            <div
                className="form-link-modal"
            >
                <p>
                    Ingresa la url
                </p>
                <input type="text" value={this.state.link} onChange={this.handleInput} name="link"/>
                <ul>
                    <li>
                        <button
                            onClick={this.props.triggerPreparar}
                        >
                            CANCELAR
                        </button>
                    </li>
                    <li>
                        <button
                            onClick={this.saveLink}
                        >
                            ACEPTAR
                        </button>
                    </li>
                </ul>
            </div>
        )
    };

}

export default EventElement;
