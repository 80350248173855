import React, { Component } from 'react';
import {Col, Form, Row} from "react-bootstrap";
import axios from 'axios';
import moment from "moment";
import DatePicker from "react-datepicker/es";

class ReporteIngresos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ingresos : [],
            start : new Date(moment().startOf("month").format("YYYY-MM-DD HH:mm:ss")),
            end : new Date(moment().format("YYYY-MM-DD HH:mm:ss"))
        };
    }

    componentDidMount() {
        this.search()
    }

    handleInputChange(name, value) {
        this.setState({
            [name]: value
        });
    }

    formatMoney = (n, c, d, t) => {
        c = isNaN(c = Math.abs(c)) ? 2 : c;
        d = d === undefined ? "." : d;
        t = t === undefined ? "," : t;
        if(isNaN(n)){
            return n
        }
        let s = n < 0 ? "-" : "";
        let i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c)));
        let k = (i.length);
        let j = k > 3 ? k % 3 : 0;
        return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
    }

    search = async () => {
        let start = moment(this.state.start).format("YYYY-MM-DD");
        let end = moment(this.state.end).format("YYYY-MM-DD");
        await axios.post('/api/reportes/ingresos', {
            start : start,
            end : end
        }).then( res => {
            if(res.data.Success){
                this.setState({
                    ingresos : res.data.search
                })
            }
            else{
                this.setState({
                    ingresos : []
                })
            }
        }).catch(err => {
            this.setState({
                ingresos : []
            })
        })
    }

    render() {
        let ingresos = this.state.ingresos;
        return (
            <div className="main">
                <h4 className="client">Ingresos</h4>
                <Row className="reporte">
                    <Col xs={12} md={4} lg={2}>
                        <Form.Group controlId="lastname">
                            <DatePicker
                                selected={this.state.start}
                                onChange={(date) => this.handleInputChange("start", date)}
                                className='input-filtros form-control'
                                name="start"
                                dateFormat={"dd/MM/yyyy"}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={4} lg={2}>
                        <Form.Group controlId="lastname">
                            <DatePicker
                                selected={this.state.end}
                                onChange={(date) => this.handleInputChange("end", date)}
                                className='input-filtros form-control'
                                name="end"
                                dateFormat={"dd/MM/yyyy"}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={4} lg={2}>
                        <button className="btn btn-success btn-block" onClick={(e) => this.search()}>
                            Filtrar
                        </button>
                    </Col>
                </Row>
                <Row className="reporte">
                    <Col xs={12} md={4} lg={4} className="reporte-element">
                        <div>
                            <h3>
                                Total
                            </h3>
                            <h4>Ventas</h4>
                            <p>{ingresos[0] ? ingresos[0].total : 0}</p>
                            <h4>Ingresos</h4>
                            <p>$ {this.formatMoney(ingresos[0] ? ingresos[0].ammount : 0)}</p>
                        </div>
                    </Col>
                    <Col xs={12} md={4} lg={4} className="reporte-element">
                        <div>
                            <h3>
                                Paypal
                            </h3>
                            <h4>Ventas</h4>
                            <p>{ingresos[1] ? ingresos[1].total : 0}</p>
                            <h4>Ingresos</h4>
                            <p>$ {this.formatMoney(ingresos[1] ? ingresos[1].ammount : 0)}</p>
                        </div>
                    </Col>
                    <Col xs={12} md={4} lg={4} className="reporte-element">
                        <div>
                            <h3>
                                Billpocket
                            </h3>
                            <h4>Ventas</h4>
                            <p>{ingresos[2] ? ingresos[2].total : 0}</p>
                            <h4>Ingresos</h4>
                            <p>$ {this.formatMoney(ingresos[2] ? ingresos[2].ammount : 0)}</p>
                        </div>
                    </Col>
                </Row>
                <hr/>
            </div>
        )
    };

}

export default ReporteIngresos;
