import React, { Component, Fragment } from 'react';
import Ionicon from 'react-ionicons';
import { Accordion, Card } from 'react-bootstrap';
import './styleSidebar.css';

class Sidebar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            active: '',
            sidebar : true
        };
        this.hideSidebar = this.hideSidebar.bind(this)
    }

    componentDidMount = async () => {
        await this.setState({ active: this.props.active });
    };

    hideSidebar(){
        this.setState({
            sidebar : !this.state.sidebar
        })
    }

    render() {

        if(this.state.sidebar) {
            return (<div className="sidebar">
                <Ionicon
                    icon="md-menu"
                    color="#000"
                    fontSize="25px"
                    className="menu"
                    onClick={this.hideSidebar}
                />
                <h4>Menú</h4>
                <ul className="menubar">
                    {this.props.type <= 1 ?
                        <a className={this.state.active === 'promos' ? 'active' : ''} href="/promos">
                            <li>
                                Códigos de descuento
                            </li>
                        </a>
                        :
                        null
                    }
                    {this.props.type <= 3 ?
                        <a className={this.state.active === 'classes' ? 'active' : ''} href="/classes">
                            <li>
                                Clases
                            </li>
                        </a>
                        :
                        null
                    }
                    {
                        this.props.type <= 3 
                        ?
                            <Fragment>
                                <a className={this.state.active === 'walk-in' ? 'active' : ''} href="/walk-in">
                                    <li>
                                        Walk-In
                                    </li>
                                </a>
                                
                            </Fragment>
                        :
                        null
                    }
                    {this.props.type <= 1 ?
                        <a className={this.state.active === 'promos' ? 'active' : ''} href="/promos">
                            <li>
                                Promociones
                            </li>
                        </a>
                        :
                        null
                    }
                    {
                        this.props.type <= 3 
                        ?
                            <Fragment>
                                <a className={this.state.active === 'InfoAlumnas' ? 'active' : ''} href="/infoalumnas">
                                    <li>
                                        Info Alumnas
                                    </li>
                                </a>
                                
                            </Fragment>
                        :
                        null
                    }
                    {this.props.type <= 1 ?
                        <Accordion className="user-accordion" defaultActiveKey="0">
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="0">
                                    Reportes
                                    <Ionicon
                                        icon="ios-arrow-down"
                                        color="#FFF"
                                        fontSize="20px"
                                        className="down"
                                    />
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>
                                        <ul>
                                            <a className={this.state.active === 'reporte_usuarios' ? 'active' : ''} href="/reporte/usuarios">
                                                <li>
                                                    Usuarios e Ingresos
                                                </li>
                                            </a>
                                            <a className={this.state.active === 'reporte_analisisvideos' ? 'active' : ''} href="/reporte/analisisVideos">
                                                <li>
                                                    Vistas y Comentarios
                                                </li>
                                            </a>
                                            <a className={this.state.active === 'reporte_codigos' ? 'active' : ''} href="/reporte/redenciones">
                                                <li>
                                                    Redenciones
                                                </li>
                                            </a>
                                            <a className={this.state.active === 'reporte_goglow' ? 'active' : ''} href="/reporte/reporteGoGlow">
                                                <li>
                                                    Redenciones códigos GoGlow
                                                </li>
                                            </a>
                                            <a className={this.state.active === 'reporte_presenciales' ? 'active' : ''} href="/reporte/presenciales">
                                                <li>
                                                    Clases Presenciales Maestras
                                                </li>
                                            </a>
                                            <a style={{display: "none"}} className={this.state.active === 'reporte_ingresos_sistemas' ? 'active' : ''} href="/reporte/ingresosReporteSistemas">
                                                <li>
                                                    Usuarios e Ingresos Sistemas
                                                </li>
                                            </a>
                                        </ul>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                        :
                        null
                    }
                </ul>
            </div>);

            /*return (<div className="sidebar">
            <Ionicon
                icon="md-menu"
                color="#000"
                fontSize="25px"
                className="menu"
                onClick={this.hideSidebar}
            />
            <h4>Menú</h4>
            <ul className="menubar">
                <a className={this.state.active === 'home' ? 'active' : ''} href="/web">
                    <li>
                        Inicio
                    </li>
                </a>
                <a className={this.state.active === 'press' ? 'active' : ''} href="/press">
                    <li>
                        Prensa
                    </li>
                </a>
                <Accordion className="user-accordion" defaultActiveKey="0">
                    <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="0">
                            Usuarios
                            <Ionicon
                                icon="ios-arrow-down"
                                color="#FFF"
                                fontSize="20px"
                                className="down"
                            />
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                <ul>
                                    <a className={this.state.active === 'admin' ? 'active' : ''} href="/admins">
                                        <li>
                                            Administradores
                                        </li>
                                    </a>
                                    <a className={this.state.active === 'partner' ? 'active' : ''} href="/partner">
                                        <li>
                                            Socios
                                        </li>
                                    </a>
                                    <a className={this.state.active === 'receptionist' ? 'active' : ''}
                                    href="/receptionist">
                                        <li>
                                            Recepcionistas
                                        </li>
                                    </a>
                                </ul>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
                <a className={this.state.active === 'client' ? 'active' : ''} href="/client">
                    <li>
                        Clientes
                    </li>
                </a>
                <a className={this.state.active === 'routines' ? 'active' : ''} href="/routines">
                    <li>
                        Rutinas
                    </li>
                </a>
                <a className={this.state.active === 'mailing' ? 'active' : ''} href="/mailing">
                    <li>
                        Mailing
                    </li>
                </a>
                <Accordion className="user-accordion" defaultActiveKey="0">
                    <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="0">
                            Reportes
                            <Ionicon
                                icon="ios-arrow-down"
                                color="#FFF"
                                fontSize="20px"
                                className="down"
                            />
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                <ul>
                                    <a className={this.state.active === 'reporte_ingresos' ? 'active' : ''} href="/reporte/ingresos">
                                        <li>
                                            Ingresos
                                        </li>
                                    </a>
                                    <a className={this.state.active === 'reporte_usuarios' ? 'active' : ''} href="/reporte/usuarios">
                                        <li>
                                            Usuarios
                                        </li>
                                    </a>

                                    <a className={this.state.active === 'reporte_analisisvideos' ? 'active' : ''} href="/reporte/analisisVideos">
                                        <li>
                                            Vistas y Comentarios
                                        </li>
                                    </a>
                                    <a className={this.state.active === 'reporte_lives' ? 'active' : ''}
                                    href="/receptionist">
                                        <li>
                                            Lives
                                        </li>
                                    </a>
                                    <a className={this.state.active === 'reporte_videos' ? 'active' : ''} href="/recepcionist">
                                        <li>
                                            Promociones
                                        </li>
                                    </a>
                                    <a className={this.state.active === 'reporte_goglow' ? 'active' : ''} href="/reporte/reporteGoGlow">
                                        <li>
                                            Redenciones códigos GoGlow
                                        </li>
                                    </a>
                                </ul>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            </ul>
            </div>);*/


        }
        else{
            return(
                <div className="showSidebar">
                    <Ionicon
                        icon="md-menu"
                        color="#000"
                        fontSize="25px"
                        className="menu"
                        onClick={this.hideSidebar}
                    />
                </div>
            )
        }

    }

}

export default Sidebar;
