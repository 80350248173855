import React, { Component } from "react";
import { Col, Form, Row } from "react-bootstrap";
import axios from "axios";

class IngresosReporteSistemas extends Component {
  constructor(props) {
    super(props);
    this.months = [
      "",
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];
    this.state = {
      usuarios: [],
      acumulado: [],
      year: new Date().getFullYear(),
      rawBase: [],
      minRaw: 1,
      maxRaw: 12,
      offset: 0,
      loading: true,
    };
  }

  componentDidMount() {
    document
      .querySelector(".main")
      .addEventListener("scroll", this.trackScrolling);
    this.search();
  }

  componentWillUnmount() {
    document
      .querySelector(".main")
      .removeEventListener("scroll", this.trackScrolling);
  }

  trackScrolling = (e) => {
    const container = document.querySelector(".main");
    const wrappedElement = document.querySelector(".table-users");
    if (
      wrappedElement &&
      this.isBottom(container, wrappedElement) &&
      !this.state.loading
    ) {
      this.search();
    }
  };

  isBottom(container, el) {
    return (
      Math.floor(el.getBoundingClientRect().bottom) <=
      Math.floor(container.getBoundingClientRect().bottom)
    );
  }

  handleInputChange(name, value) {
    this.setState({
      [name]: value,
    });
  }

  formatMoney = (n, c, d, t) => {
    c = isNaN((c = Math.abs(c))) ? 2 : c;
    d = d === undefined ? "." : d;
    t = t === undefined ? "," : t;
    if (isNaN(n)) {
      return n;
    }
    let s = n < 0 ? "-" : "";
    let i = String(parseInt((n = Math.abs(Number(n) || 0).toFixed(c))));
    let k = i.length;
    let j = k > 3 ? k % 3 : 0;
    return (
      s +
      (j ? i.substr(0, j) + t : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) +
      (c
        ? d +
          Math.abs(n - i)
            .toFixed(c)
            .slice(2)
        : "")
    );
  };

  getRawBase(arr) {
    let raw = [];
    let rawPayments = [];
    let rawCodes = [];
    let min = 1;
    let max = 12;
    arr.map((el, index) => {
      raw.push(0);
      rawPayments.push("-");
      rawCodes.push("-");
      if (index === 0) {
        min = el.month;
      }
      if (index === arr.length - 1) {
        max = el.month;
      }
    });
    this.setState({
      rawBase: raw,
      rawBasePayments: rawPayments,
      rawBaseCodes: rawCodes,
      minRaw: min,
      maxRaw: max,
    });
  }

  search = async () => {
    this.setState({
      loading: true,
    });
    let year = this.state.year;
    let offset = this.state.offset;
    await axios
      .post("/api/reportes/usuariosSistemas", {
        year: year,
        offset: offset,
      })
      .then((res) => {
        if (res.data.Success) {
          let usuarios = [...this.state.usuarios];
          let newUsuarios = usuarios.concat(res.data.search[1]);
          this.getRawBase(res.data.search[0]);
          this.setState({
            acumulado: res.data.search[0],
            usuarios: newUsuarios,
            offset: offset + 50,
            loading: false,
          });
        } else {
          this.setState({
            usuarios: [],
            acumulado: [],
            loading: false,
          });
        }
      })
      .catch((err) => {
        this.setState({
          usuarios: [],
          acumulado: [],
          loading: false,
        });
      });
  };

  drawYears() {
    let options = [];
    let currYear = new Date().getFullYear();
    for (let k = 2020; k <= currYear; k++) {
      options.push(
        <option key={k} value={k}>
          {k}
        </option>
      );
    }
    return options;
  }

  drawTitles(arr) {
    return arr.map((el, idex) => {
      return (
        <th colSpan="2" key={idex}>
          {this.months[el.month]}
        </th>
      );
    });
  }

  drawData(arr, name, money = false) {
    return arr.map((el, index) => {
      return (
        <td key={index}>
          {money ? "$ " + this.formatMoney(el[name]) : el[name]}
        </td>
      );
    });
  }

  drawAmmounts(arr, arrb) {
    let filas = [];

    return arr.map((el, index) => {
      return (
        <>
          <td key={"p" + index}>{arrb[index]}</td>
          <td key={index}>$ {this.formatMoney(el)}</td>
        </>
      );
    });
  }

  drawUsers() {
    let users = [...this.state.usuarios];
    let id = null;
    let row = null;
    let rowPayments = null;
    let name = null;
    let email = null;
    return users.map((el, index) => {
      let returnEl = null;
      if (el.id !== id) {
        if (id) {
          returnEl = (
            <tr key={"t" + index}>
              <td>{name}</td>
              <td>{email}</td>
              {this.drawAmmounts(row, rowPayments)}
            </tr>
          );
        }
        row = [...this.state.rawBase];
        rowPayments = [...this.state.rawBasePayments];
        id = el.id;
        name = el.name;
        email = el.email;
      }
      row[el.month - this.state.minRaw] = el.total;
      rowPayments[el.month - this.state.minRaw] = el.paymentMethod;

      return returnEl;
    });
  }

  drawAmmountsCodes(arr, arrb) {
    let filas = [];

    return arr.map((el, index) => {
      return (
        <>
          <td key={"p" + index}>{arrb[index]}</td>
          <td key={index}>$ {this.formatMoney(el)}</td>
        </>
      );
    });
  }

  drawUsersCodes() {
    let users = [...this.state.usuarios];
    let id = null;
    let row = null;
    let rowCodes = null;
    let name = null;
    let email = null;
    return users.map((el, index) => {
      let returnEl = null;
      if (el.id !== id) {
        if (id) {
          returnEl = (
            <tr key={"t" + index}>
              <td>{name}</td>
              <td>{email}</td>
              {this.drawAmmountsCodes(row, rowCodes)}
            </tr>
          );
        }
        row = [...this.state.rawBase];
        rowCodes = [...this.state.rawBaseCodes];
        id = el.id;
        name = el.name;
        email = el.email;
      }
      row[el.month - this.state.minRaw] = el.total;
      rowCodes[el.month - this.state.minRaw] = el.codes;

      return returnEl;
    });
  }

  render() {
    let ingresos = this.state.ingresos;
    return (
      <div className="main">
        <h4 className="client">Usuarios</h4>
        <Row className="reporte">
          <Col xs={12} md={4} lg={2}>
            <Form.Group controlId="year">
              <select name="year" id="year" className="form-control">
                {this.drawYears()}
              </select>
            </Form.Group>
          </Col>
          <Col xs={12} md={4} lg={2}>
            <button
              className="btn btn-success btn-block"
              onClick={(e) => this.search()}
            >
              Buscar
            </button>
          </Col>
        </Row>
        <Row className="reporte">
          {/*<Col xs={12} className="reporte-element table-content">
                        <div>
                            <table className="table table-sm">
                                <thead>
                                    <tr>
                                        <th></th>
                                        {this.drawTitles(this.state.acumulado)}
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            Usuarios por periodo
                                        </td>
                                        {this.drawData([...this.state.acumulado],"users")}
                                    </tr>
                                    <tr>
                                        <td>
                                            Total Acumulado
                                        </td>
                                        {this.drawData([...this.state.acumulado],"total",true)}
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </Col>*/}

          <Col xs={12} className="reporte-element table-content">
            <div className="table-responsive">
              <table id="ingresosTable" className="table table-sm table-users">
                <thead>
                  <tr>
                    <th>Usuario</th>
                    <th>Email</th>
                    {this.drawTitles(this.state.acumulado)}
                  </tr>
                </thead>
                <tbody>{this.drawUsers()}</tbody>
              </table>
            </div>
          </Col>

          <h3>Reporte de c&oacute;digos</h3>

          <Col xs={12} className="reporte-element table-content">
            <div className="table-responsive">
              <table id="codesTable" className="table table-sm table-users">
                <thead>
                  <tr>
                    <th>Usuario</th>
                    <th>Email</th>
                    {this.drawTitles(this.state.acumulado)}
                  </tr>
                </thead>
                <tbody>{this.drawUsersCodes()}</tbody>
              </table>
            </div>
          </Col>
        </Row>
        <hr />
      </div>
    );
  }
}

export default IngresosReporteSistemas;
