import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import Sidebar from '../../components/Sidebar';
import Navigation from '../../components/Navigation/Navigation';
import ModalBan from '../../components/Admin/ModalBan';
import ModalClient from '../../components/Admin/ModalClient';
import axios from 'axios';
import './styleUsers.css';

class Client extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            search: '',
            clients: [],
            show: false,
            close: false,
            typeUser: 4,
            idClient: '',
            nameClient: '',
            status: '',
            birthdate: '',
            dateClient: '',
            studio: ''
        };
        this.searchUser = this.searchUser.bind(this);
        this.getClients = this.getClients.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.convertDateToString = this.convertDateToString.bind(this);
    }

    componentDidMount() {
        this.getClients();
    }

    searchUser(e) {
        //console.log(e.target.value);
    }

    getClients = async () => {
        return await axios.post('/api/user/clients')
            .then(res => {
                this.setState({ clients: res.data.client, loading: false });
            })
            .catch(err => {
                this.setState({ loading: false });
            });
    };
    handleShow = async (e, id, username, status) => {
        await this.setState({
            show: true,
            idClient: id,
            nameClient: username,
            status: status
        });
    };
    handleShowInfo = async (e, id, username, birthday, create, name, lastname) => {
        await this.setState({
            showInfo: true,
            idClient: id,
            nameClient: (name && lastname) ? (name + ' ' + lastname) : username,
            birthdate: birthday === null ? 'no data' : this.convertDateToString(birthday),
            dateClient: this.convertDateToString(create)
        });
    };

    handleClose(e) {
        this.setState({ show: false, showInfo: false });
    }

    convertDateToString(date) {
        var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
        return [year, month, day].join('-');
    }

    renderObject() {
        if (this.state.clients.length === 0) return null;
        let clients = this.state.clients;
        return clients.map((item, index) => {
            // eslint-disable-next-line
            if (item.status === 0) return;
            return (
                <tr key={index} className="clientes-body">
                    <td
                        className="client-click"
                        onClick={(e) => this.handleShowInfo(e, item.id, item.username, item["profile.birthday"], item.created_at, item["profile.name"],item["profile.last_name"])}
                    >
                        {item["profile.name"] ? item["profile.name"] + ' ' + item["profile.last_name"] : item.username}
                    </td>
                    <td>{item.email}</td>
                    <td>
                        <button
                            className="btn-normal ban"
                            onClick={(e) => this.handleShow(e, item.id, item.username, item.status)}
                        >
                            Banear
                        </button>
                    </td>
                </tr>
            );
        });
    }

    renderBanned() {
        if (this.state.clients.length === 0) return null;
        let clients = this.state.clients;
        return clients.map((item, index) => {
            // eslint-disable-next-line
            if (item.status === 1) return;
            return (
                <tr key={index} className="clientes-body">
                    <td>{item["profile.name"] ? item["profile.name"] + ' ' + item["profile.last_name"] : item.username}</td>
                    <td>{item.email}</td>
                    <td>
                        <button
                            className="btn-normal activate"
                            onClick={(e) => this.handleShow(e, item.id, item.username, item.status)}
                        >
                            Activar
                        </button>
                    </td>
                </tr>
            );
        });
    }

    renderDash() {
        return (
          <div className="content">
                <Navigation user={this.props.user}/>
                <Sidebar
                    active="client"
                    type={this.props.type}
                />
                <div className="main">
                    <h4 className="client">Clientes</h4>
                    <div className="table-content">
                        <table>
                            <thead>
                            <tr>
                                <th>
                                    Nombre Completo
                                </th>
                                <th>
                                    Email
                                </th>
                                <th>
                                    Acciones
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.renderObject()}
                            </tbody>
                        </table>
                    </div>
                    <hr/>
                    <Row>
                        <Col xs={12} md={6} lg={6}>
                            <h5 className="subtitle-6">
                                Usuarios Baneados
                            </h5>
                        </Col>
                    </Row>
                    <div className="table-content">
                        <table>
                            <thead>
                            <tr>
                                <th>
                                    Nombre Completo
                                </th>
                                <th>
                                    Email
                                </th>
                                <th>
                                    Acciones
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.renderBanned()}
                        </tbody>
                      </table>
                    </div>
                    <ModalBan
                        id={this.state.idClient}
                        name={this.state.nameClient}
                        status={this.state.status === 0 ? 1 : 0}
                        nameCategorie={this.state.status === 0 ? 'activar' : 'bannear'}
                        show={this.state.show}
                        close={this.handleClose}
                        typeUser={this.state.typeUser}
                    />
                    <ModalClient
                        id={this.state.idClient}
                        name={this.state.nameClient}
                        birthdate= {this.state.birthdate}
                        dateClient= {this.state.dateClient}
                        nameCategorie='DATOS USUARIO'
                        show={this.state.showInfo}
                        close={this.handleClose}
                    />
                </div>
            </div>
        );
    }

    render() {
        return this.renderDash();
    };

}

export default Client;
