import React, { Component, Fragment } from "react";
import Sidebar from "../../components/Sidebar";
import Navigation from '../../components/Navigation/Navigation';
import moment from 'moment';
import Swal from 'sweetalert2';
import axios from 'axios';
import {Col, Form,Row} from "react-bootstrap";
import MUIDataTable from "mui-datatables";

class WalkIn extends Component{
    constructor(props){
        super(props);
        this.state = {
            estudios:[],
            classes:[],
            reservations:null,
            showForm:false,
            clase:null,
            titleClass:null,
            placeSelect:null,
            paymentSelect:null,
            mail_alumna:null,
            nombre_alumna: null
        }

        this.changeFormHandler = this.changeFormHandler.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount(){
        this.getStudios();
    }
    componentDidUpdate(prevProps, prevState, snapshot){
        if(this.props.type == 3 && !prevState.estudios.length && this.state.estudios.length === 1){
            this.getClasses();
        }  
    }

    getStudios = async () => {
        await axios.get('/api/studio/getSelectStudios').then(res => {
            if (res.status === 200) {
                this.setState({
                    estudios: res.data
                });
            }
        });
    };

    getClasses = async () =>{
        
        await axios.get('/api/clase/getDayClasses').then(res=>{
            if(res.status === 200){
                this.setState({
                    classes: res.data[0],
                });
            }
        })
    }

    drawDateOptions(){
        let options = [];
        let clases = this.state.classes;
        let currentClasse = [];
        for (let index = 0; index < clases.length; index++) {
            let fecha = moment();
            let start = moment(clases[index].start);

            let duracion = moment.duration(fecha.subtract(start));

            if( (duracion.months() < 1) && (duracion.days() < 1) && (duracion.hours() < 1) ){
             
                if(clases[index].places > clases[index].reserved){
                    currentClasse.push(clases[index].id)
                    options.push(<option key={clases[index].id} value={clases[index].id}>{clases[index].name}</option>);
                }
            }
        }
        
        options.splice(0,0,<option key={0} value={0}>--Selecciona un horario--</option>)

        return (
            <select name="classe" id="classe" className="form-control" style={{width:'auto'}} defaultValue={0} onChange={this.handleSelect}>
            { options}
            </select>
        );
    }
    
    handleSelect = async (e) => {
        let inst = parseInt(e.target.value);
        this.setState({
            clase: inst,
            showForm: false
        });
    };

    getPlaces = async() =>{
        if( this.state.clase === null || this.state.clase === 0){
            Swal.fire('Error', 'Debes seleccionar una opción valida', 'error')
            return false
        }


        let respuesta = await axios.get('/api/clase/getById', {
            params: { clase: this.state.clase }
        });
        if(respuesta.data.Success){

            this.setState({
                titleClass:respuesta.data.clase.name,
                reservations: respuesta.data.clase.reservations,
                showForm:true,
                paymentSelect: null,
                placeSelect: null
            })
        }
    }

    changeFormHandler(e){
        this.setState({
                [e.target.name]: e.target.value

        });
    }

    async handleSubmit(){
        let data = {
            paymentType: this.state.paymentSelect,
            place: this.state.placeSelect,
            nombre_alumna: this.state.nombre_alumna,
            mail_alumna: this.state.mail_alumna,
            clase: this.state.clase
        };
        console.log(data)
        if(data.paymentType == 0 || data.paymentType == null){
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Elige un metodo de pago valido.'
            });
            return false;
        }

        if(data.place == 0 || data.place == null){
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Elige un metodo de lugar valido.'
            });
            return false;
        }
        if(data.mail_alumna === '' || data.nombre_alumna === '' || data.mail_alumna === null || data.nombre_alumna === null ){
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'No se han ingresado todos los datos necesarios.'
            });
            return false;
        }
        let respuesta = await axios.post('/api/clase/saveWalkin',data);
        if(respuesta.data.Success){
            Swal.fire({
                title: 'Registro Completo',
                text: "Registro exitoso, el código de acceso es: " + respuesta.data.reserved,
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
              }).then((result) => {
                if (result.isConfirmed) {
                    window.location.href='/walk-in';
                }
              })
            
        }else{
            Swal.fire('Reservacion sin exito','','error');
            window.location.href='/walk-in';
        }
        return false;
    }

    drawPlacesList(){
        if( this.state.reservations === null ){
            return null;
        }
        
        let usuariosClase = this.state.reservations;
        let data = [];
        let disponibles = [];
        let place = [];

        for (let index = 1; index < (this.state.estudios[0].places + 1); index++) {
            const resultado = usuariosClase.find( lugar => lugar.place === index );
            if(resultado){
                data.push( [ index, resultado.user.profile.name + ' ' + resultado.user.profile.last_name, 'Reservado', resultado.place ] )
            }else{
                place.push(index)
                data.push( [ index, 'Disponible', 'Disponible', index ] );
                disponibles.push( <option key={index} value={index}>{index}</option> );
            }
        }

        disponibles.splice(0,0,<option key={0} value={0}>--Selecciona un lugar--</option>)

        var teens = this.state.titleClass.search('TEENS');

        
        const placeSelect = <select className="form-control" name="placeSelect" style={{width:'auto'}} defaultValue={0}  onChange={this.changeFormHandler}>
            {disponibles}
        </select>

        var paymentSelector = null;
        if(teens > -1){
            paymentSelector = <select className="form-control" name="paymentSelect" style={{width:'auto'}} defaultValue={0}  onChange={this.changeFormHandler}>
            <option value={0}>--Selecciona una opcion--</option>
            <option value={6}>Efectivo</option>
            <option value={8}>Tarjeta</option>
            <option value={4}>VIP</option>
        </select>;
        }else{
            paymentSelector = <select className="form-control" name="paymentSelect" style={{width:'auto'}} defaultValue={0}  onChange={this.changeFormHandler}>
            <option value={0}>--Selecciona una opcion--</option>
            <option value={7}>Efectivo</option>
            <option value={9}>Tarjeta</option>
            <option value={4}>VIP</option>
        </select>;
        }
        

        const columns = ['#', 'Nombre Alumna', 'Status', 'Lugar'];
        const options = {
            filter: false,
            filterType: "dropdown",
            selectableRows: 'none',
            rowHover: true,
            print: false,
            download: false,
            responsive: 'simple',
            onRowClick: (rowData, rowMeta) => {
                
            },
            textLabels: {
                body: {
                  noMatch: "No se encontraron registros",
                  toolTip: "Ordenar",
                  columnHeaderTooltip: column => `Ordenar por ${column.label}`
                },
                pagination: {
                  next: "Siguiente página",
                  previous: "Página anterior",
                  rowsPerPage: "Registros por página:",
                  displayRows: "de",
                },
                toolbar: {
                  search: "Buscar",
                  viewColumns: "Ver columnas",
                  filterTable: "Filtrar tabla",
                },
                filter: {
                  all: "TODOS",
                  title: "FILTROS",
                  reset: "RESET",
                },
                viewColumns: {
                  title: "Mostrar columnas",
                  titleAria: "Mostrar/Ocultar columnas",
                },
                selectedRows: {
                  text: "Registro(s) seleccionado(s)",
                  delete: "Delete",
                  deleteAria: "Delete Selected Rows",
                }
            }
        }
        return(
            <Fragment>
                    <Col md={6}>
                        <MUIDataTable
                            title={this.state.titleClass}
                            data={data}
                            columns={columns}
                            options={options}
                        />
                    </Col>
                    <Col md={6}>
                            <Form.Group>
                                <span>Nombre de la alumna:</span>
                                <input className="form-control" type="text" id="nombre_alumna" name="nombre_alumna" onChange={this.changeFormHandler} required></input>
                            </Form.Group>
                            <Form.Group>
                                <span>Correo de la alumna:</span>
                                <input className="form-control" type="email" id="mail_alumna" name="mail_alumna" onChange={this.changeFormHandler} required></input>
                            </Form.Group>
                            <Form.Group controlId="walking">
                                <span>Seleccionar lugar:</span>
                                {placeSelect}
                            </Form.Group>
                            <Form.Group controlId="walking">
                                <span>M&eacute;todo de pago:</span>
                                {paymentSelector}
                            </Form.Group>
                            <Form.Group controlId="walking">
                                <button type="submit"  className="btn btn-primary btn-block" onClick={this.handleSubmit}>Guardar</button>
                            </Form.Group>
                        
                    </Col>
            </Fragment>
            
        )
    }

    renderDash(){

        const classSelect = this.state.classes.length > 0 ? this.drawDateOptions() : '...cargando' ;
        return(
            <Fragment>
                <div className="row">
                    <Col >
                        <Form.Group controlId='classes'>
                            <span>Selecciona una clase: </span>
                            {classSelect}
                        </Form.Group>
                        
                    </Col>
                    <Col >
                        <br></br>
                        <button className="btn btn-success btn-block" style={{width:'auto'}} onClick={this.getPlaces}>
                            Buscar
                        </button>
                    </Col>
                
                </div>
                <div className="row">
                    {
                        (this.state.showForm) ? this.drawPlacesList() : ''
                    }
                </div>
            </Fragment>
            
        );
    }

    render(){
        return(
            (this.state.estudios.length >= 1) ? 
                <div className="content">                
                    <Navigation user={this.props.user}/>
                    <Sidebar
                        active={this.props.select}
                        type={this.props.type}
                    />
                    <div className="main">
                        <h2>Registro de Walk-In BodyBarre { (this.state.estudios.length >= 1) ? this.state.estudios[0].name : '' }</h2>
                        {this.renderDash()}
                    </div>
                </div>
            :
            null
        );
    }
}

export default WalkIn;