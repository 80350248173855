import React, {Component} from 'react';
import {Modal, Button} from 'react-bootstrap';
import axios from 'axios';
import "./styleBan.css";

class ModalBan extends Component {
    constructor(props){
        super(props);
        this.state = {
            username:'',
            email:'',
            password:'',
            userError: false,
            emailError: false,
            pwError: false,
            emailExist: false,
            nameExist: false
        };
        this.banUser = this.banUser.bind(this);
        this.banPromo = this.banPromo.bind(this);
        this.deleteRoutine = this.deleteRoutine.bind(this);
        this.changeHandler = this.changeHandler.bind(this);

    }
    componentDidMount() {
        this.setState({
            show: this.props.show,
            close: this.props.close
        });
    }
    changeHandler(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    banUser= async () => {

        const user={
            id: this.props.id,
            status: this.props.status
        }
        return await axios.post('/api/user/banUser', user)
        .then(res => {
                window.location.reload();

        })
        .catch(err => {
            //console.log(err)
        });
    }
    banPromo= async () => {
        const promo={
            id: this.props.id,
            status: this.props.status
        }
        return await axios.post('/api/payment/banPromo', promo)
        .then(res => {
                window.location.reload();

        })
        .catch(err => {
            //console.log(err)
        });
    }
    deleteRoutine= async () => {
        const rutina={
            id: this.props.id,
            status: this.props.status
        }
        return await axios.post('/api/routines/deleteRoutine', rutina)
        .then(res => {
            window.location.reload();
        })
        .catch(err => {
            //console.log(err)
        });
    }
    renderDash(){
        return (
            <Modal
                show={this.props.show}
                onHide={this.props.close}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{this.props.nameCategorie}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="input-div">
                    {this.props.section === "promos" ?
                        <div>
                            ¿Está seguro que desea {this.props.nameCategorie} la promoción &nbsp;
                            <b>
                                {this.props.name}
                            </b>?
                        </div>
                    : this.props.section === "rutina" ?
                        <div>
                            ¿Está seguro que desea {this.props.nameCategorie} el video&nbsp;
                            <b>
                                {this.props.name}
                            </b>?
                        </div>
                    :
                        <div>
                            ¿Está seguro que desea {this.props.nameCategorie} al usuario &nbsp;
                            <b>
                                {this.props.name}
                            </b>?
                        </div>
                    }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={this.props.close}>
                        Cerrar
                    </Button>
                    {this.props.section === "promos" ?
                        <Button variant="info" onClick={this.banPromo}>
                            Aceptar
                        </Button>
                    : this.props.section === "rutina" ?
                        <Button variant="info" onClick={this.deleteRoutine}>
                            Aceptar
                        </Button>
                    :
                        <Button variant="info" onClick={this.banUser}>
                            Aceptar
                        </Button>
                    }
                </Modal.Footer>
          </Modal>
        );
    }

    render() {
        return this.renderDash();
    };
}

export default ModalBan;
