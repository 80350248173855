
import React, {Component} from 'react';

class Loading extends Component {

    render() {

        /*Add this.props to change size and class to .loader-inside*/
        const size = {width: "8rem", height: "8rem"};
        const cover = (this.props.inside || false)
            ? "loader-inside"
            : "loader";
        const color = this.props.color || '';
        const type = this.props.type || 'border';

        return (<div className={cover}>
            <div className={'spinner-'+ type +' text-' + color} style={size} role="status">
                <span className="sr-only">Cargando...</span>
            </div>
        </div>);

    }
}

export default Loading;
