import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import Sidebar from '../../components/Sidebar';
import Navigation from '../../components/Navigation/Navigation';
import axios from 'axios';
import './styleSubs.css';

class Subscriptions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            clients: [],
            typeUser: 4,
            idClient: '',
            nameClient: '',
            typeSub: '',
            sub: false
        };
        this.getClient = this.getClient.bind(this);
    }

    componentDidMount() {
        var url_string = window.location.href; //window.location.href
        var url = new URL(url_string);
        var id = url.searchParams.get("id");
        if (id) {
            this.getClient(id);
            this.getSub(id);
        }
        else{
            window.location.href = "/client";
        }
    }

    getClient(id){
        const user={
            id: id
        }
        return axios.post('/api/user/clientName',user)
            .then(res => {
                this.setState({ nameClient: res.data[0].username, loading: false });
            })
            .catch(err => {
                this.setState({ loading: false });
            });
    };

    getSub(id){
        const user={
            id: id
        }
        return axios.post('/api/user/getSubscriptionInfo',user)
            .then(res => {
                this.setState({ typeSub: res.data.sub["membership.name"], loading: false });
            })
            .catch(err => {
                this.setState({ loading: false });
            });
    };


    renderDash() {
        return (
          <div className="content">
                <Navigation user={this.props.user}/>
                <Sidebar
                    active="client"
                    type={this.props.type}
                />
                <div className="main">
                    <h4 className="client">
                        Suscripción - {this.state.nameClient}
                    </h4>
                    <Row>
                        <Col xs={12} md={8} lg={8}>
                            <div className="label-cl">
                                Tipo de subscripci&oacute;n
                            </div>
                            <div className="input-cl">
                                {this.state.typeSub}
                            </div>
                        </Col>
                        <Col xs={12} md={12} lg={12}>
                            <div className="label-cl">
                                Historial:
                            </div>
                            <div className="textarea-cl">
                                {this.props.history ? this.props.history : ''}
                            </div>
                        </Col>
                    </Row>

                </div>
            </div>
        );
    }

    render() {
        return this.renderDash();
    };

}

export default Subscriptions;
