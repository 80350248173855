import React, {Component} from 'react';
import {Modal, Button} from 'react-bootstrap';
import axios from 'axios';
import "./styleAdmin.css";

class ModalAdd extends Component {
    constructor(props){
        super(props);
        this.state = {
            instructors: [],
            username:'',
            email:'',
            password:'',
            codePromotion: '',
            userError: false,
            emailError: false,
            pwError: false,
            codeError: false,
            emailExist: false,
            nameExist: false,
            codeExist: false,
            ammError: false,
            codeDesc: '',
            quantity: '',
            validity: '',
            duration: '',
            percentage: '',
            ammount: '',
            application: true,
            discountType: false,
            nameRoutine: '',
            routDesc: '',
            instructor: -1,
            nameRoutError: false,
            routExist: false,
            descRoutError: false,
            fileRoutError: false,
            selectRoutError: false,
            packageValue: this.props.packageValue ? this.props.packageValue : 0
        };
        this.addNewUser = this.addNewUser.bind(this);
        this.addNewPromo = this.addNewPromo.bind(this);
        this.addNewRoutine = this.addNewRoutine.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
    }
    componentDidMount() {
        this.getInstructors();
        this.setState({
            show: this.props.show,
            close: this.props.close,
            nameCategorie: this.props.name
        });

    }
    changeHandler(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    handleSelect = async (e) => {
        let inst = parseInt(e.target.value);
        this.setState({
            instructor: inst
        });
    };
    handleRadio = async(e, checked) => {
        let name = e.target.name;
        await this.setState({
            [name] : checked,
            quantity: e.target.value
        })
    }
    handleRadioApp = async(e, checked) => {
        let name = e.target.name;
        await this.setState({
            [name] : checked
        })
    }
    addNewUser= async () => {
        let error=false;
        document.getElementById("usernameID").classList.remove("input-error");
        document.getElementById("emailID").classList.remove("input-error");
        document.getElementById("passwordID").classList.remove("input-error");
        this.setState({
            userError: false,
            emailError: false,
            pwError: false,
            emailExist: false,
            nameExist: false
        });
        let username = this.state.username;
        let email = this.state.email;
        let password = this.state.password;
        if (username.length < 1){
            document.getElementById("usernameID").classList.add("input-error");
            document.getElementById("usernameID").focus();
            await this.setState({userError: "Campo vacío"});
            error=true;
        }
        if (email.length < 1 || !email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)){
            document.getElementById("emailID").classList.add("input-error");
            document.getElementById("emailID").focus();
            await this.setState({emailError: "invalid email"});
            error=true;
        }
        if (password.length < 6){
            document.getElementById("passwordID").classList.add("input-error");
            document.getElementById("passwordID").focus();
            await this.setState({pwError: "Min 6 characters"});
            error=true;
        }
        if(error) return;
        const user={
            type: this.props.typeUser,
            username: this.state.username,
            email: this.state.email,
            password: this.state.password
        }
        return await axios.post('/api/user/addNewUser', user)
        .then(res => {
            if(res.data.ErrorName){
                this.setState({nameExist: res.data.ErrorName});
                document.getElementById("usernameID").classList.add("input-error");
            }
            else if(res.data.Error){
                this.setState({emailExist: res.data.Error});
                document.getElementById("emailID").classList.add("input-error");
            }
            else {
                window.location.reload();
            }
        })
        .catch(err => {
            //console.log(err)
        });
    }
    addNewPromo= async () => {
        let error=false;
        document.getElementById("promoCode").classList.remove("input-error");
        document.getElementById("descCode").classList.remove("input-error");

        this.setState({
            codeError: false,
            codeExist: false,
            descError: false,
            ammError: false
        });
        let codePromotion = this.state.codePromotion;
        if (codePromotion.length < 1){
            document.getElementById("promoCode").classList.add("input-error");
            document.getElementById("promoCode").focus();
            await this.setState({codeError: "Campo vacío"});
            error=true;
        }
        if (this.state.codeDesc.length < 1){
            document.getElementById("descCode").classList.add("input-error");
            document.getElementById("descCode").focus();
            await this.setState({descError: "Campo vacío"});
            error=true;
        }
        if (this.state.discountType === true && this.state.ammount > this.state.packageValue){
            document.getElementById("ammountPromo").focus();
            await this.setState({ammError: "El valor del descuento excede el paquete con valor más alto "});
            error=true;
        }
        if(error) return;
        const promo={
            code: this.state.codePromotion,
            codeDesc : this.state.codeDesc,
            ammount : this.state.ammount,
            percentage : this.state.percentage,
            discountType : this.state.discountType
        }
        return await axios.post('/api/payment/addNewPromo', promo)
        .then(res => {
            if(res.data.ErrorCode){
                this.setState({codeExist: res.data.ErrorCode});
                document.getElementById("promoCode").classList.add("input-error");
            }
            else {
                window.location.reload();
            }
        })
        .catch(err => {
            //console.log(err)
        });
    }
    addNewRoutine= async () => {
        let error=false;
        document.getElementById("rutineName").classList.remove("input-error");
        document.getElementById("descRoutine").classList.remove("input-error");
        this.setState({
            nameRoutError: false,
            routExist: false,
            descRoutError: false,
            selectRoutError: false,
            fileRoutError: false
        });
        let nameRoutine = this.state.nameRoutine;
        let description = this.state.routDesc;
        if (nameRoutine.length < 1){
            document.getElementById("rutineName").classList.add("input-error");
            document.getElementById("rutineName").focus();
            await this.setState({nameRoutError: "Campo vacío"});
            error=true;
        }
        if (description.length < 1){
            document.getElementById("descRoutine").classList.add("input-error");
            document.getElementById("descRoutine").focus();
            await this.setState({descRoutError: "Campo vacío"});
            error=true;
        }
        if (this.state.instructor === -1){
            document.getElementById("instructorSelect").focus();
            await this.setState({selectRoutError: "Debe seleccionar un instructor"});
            error=true;
        }
        if(error) return;
        const rutina={
            nameRoutine: this.state.nameRoutine,
            description: this.state.routDesc,
            instructor: this.state.instructor
        }
        return await axios.post('/api/routines/addNewRoutine', rutina)
        .then(res => {
            if(res.data.Error){
                this.setState({routExist: res.data.Error});
                document.getElementById("rutineName").classList.add("input-error");
            }
            else {
                window.location.reload();
            }
        })
        .catch(err => {
            //console.log(err)
        });
    }
    getInstructors = async () => {
        await axios.get('/api/instructor/getAllInstructors').then(res => {
            if (res.status === 200) {
                this.setState({
                    instructors: res.data.instructors
                });
            }
        });
    };
    renderPromotions(){
        return(
            <div>
                <div className="input-div">
                    <label>Código</label>
                    <input
                        type="text"
                        id="promoCode"
                        name="codePromotion"
                        placeholder=""
                        maxLenght={"40"}
                        defaultValue={this.state.codePromotion}
                        onChange={this.changeHandler}
                        validate="true"
                    />
                    {this.state.codeError ?
                        <div className="text-error-class">{this.state.codeError}</div>
                    : this.state.codeExist ?
                        <div className="text-error-class">{this.state.codeExist}</div>
                    : null }
                </div>
                <div className="input-div">
                    <label>Descripci&oacute;n</label>
                    <textarea
                        type="text"
                        id="descCode"
                        name="codeDesc"
                        placeholder="Información del código"
                        maxLenght={"2500"}
                        defaultValue={this.state.codeDesc}
                        onChange={this.changeHandler}
                        validate="true"
                    >
                    </textarea>
                    {this.state.descError ?
                        <div className="text-error-class">{this.state.descError}</div>
                    : null }
                </div>
                <div className="input-radio">
                    <label className="label-title">Tipo de descuento</label>
                    <input
                        type="radio"
                        id="ammount"
                        name="discountType"
                        value="ammount"
                        checked={this.state.discountType}
                        onChange={(e) => this.handleRadio(e, true)}
                    />
                    <label className="radio-label" htmlFor="ammount">
                        Importe
                    </label>
                    <input
                        type="radio"
                        id="percentage"
                        name="discountType"
                        value="percentage"
                        checked={!this.state.discountType}
                        onChange={(e) => this.handleRadio(e, false)}
                    />
                    <label className="radio-label" htmlFor="percentage">
                        Porcentaje
                    </label>
                </div>
                {this.state.quantity === "ammount" ?
                    <div className="input-div">
                        <label>Importe</label>
                        <span className="span-dollar">$</span>
                        <input
                            type="text"
                            id="ammountPromo"
                            name="ammount"
                            placeholder="Importe en pesos"
                            maxLenght={"40"}
                            defaultValue={this.state.ammount}
                            onChange={this.changeHandler}
                            validate="true"
                            style = {{paddingLeft: 15}}
                        />
                        {this.state.ammError ?
                            <div className="text-error-class">{this.state.ammError}</div>
                        : null }
                    </div>
                :
                    <div className="input-div">
                        <label>Porcentaje</label>
                        <span className="span-perc">%</span>
                        <input
                            type="text"
                            id="percentageCode"
                            name="percentage"
                            placeholder="pocentaje en numero entero"
                            maxLenght={"40"}
                            defaultValue={this.state.percentage}
                            onChange={this.changeHandler}
                            validate="true"
                        />
                    </div>
                }

            </div>
        );
    }
    drawSelect() {
        let instructor = [...this.state.instructors];
        let options = [];
        if (instructor.length > 0)
            options = instructor.map(el => <option key={el.id} value={el.id}>{el.fullname}</option>);

        return (
            <select
                className="select"
                name="instructor"
                id="instructorSelect"
                defaultValue={-1}
                onChange={this.handleSelect}
            >
                <option value="-1">Seleccionar</option>
                {options}
            </select>
        );
    }
    renderRoutine(){
        return(
            <div>
                <div className="input-div">
                    <label>Nombre</label>
                    <input
                        type="text"
                        id="rutineName"
                        name="nameRoutine"
                        placeholder="Nombre de la rutina"
                        maxLenght={"40"}
                        defaultValue={this.state.nameRoutine}
                        onChange={this.changeHandler}
                        validate="true"
                    />
                    {this.state.nameRoutError ?
                        <div className="text-error-class">{this.state.nameRoutError}</div>
                    : this.state.routExist ?
                        <div className="text-error-class">{this.state.routExist}</div>
                    : null }
                </div>
                <div className="input-div">
                    <label>Descripci&oacute;n</label>
                    <textarea
                        type="text"
                        id="descRoutine"
                        name="routDesc"
                        placeholder="Información del video"
                        maxLenght={"2500"}
                        defaultValue={this.state.routDesc}
                        onChange={this.changeHandler}
                        validate="true"
                    >
                    </textarea>
                    {this.state.descRoutError ?
                        <div className="text-error-class">{this.state.descRoutError}</div>
                    : null }
                </div>
                <div className="input-div">
                    <label>Instructor</label>
                    {this.drawSelect()}
                    {this.state.selectRoutError ?
                        <div className="text-error-class">{this.state.selectRoutError}</div>
                    : null }
                </div>
                <div className="input-div">
                    <label for="fileRoutine">A&ntilde;adir video</label>
                    <input
                        type="file"
                        id="fileRoutine"
                        name="fileRoutine"
                        defaultValue={this.state.fileRoutine}
                        onChange={this.changeHandler}
                        validate="true"
                    />
                    {this.state.fileRoutError ?
                        <div className="text-error-class">{this.state.fileRoutError}</div>
                    : null }
                </div>
            </div>
        );
    }
    renderDash(){
        return (
            <Modal
                show={this.props.show}
                onHide={this.props.close}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Agregar {this.state.nameCategorie}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    { this.props.name === "Promoción" ?
                        this.renderPromotions()
                    : this.props.name === "Rutina" ?
                        this.renderRoutine()
                    :
                    <div>
                        <div className="input-div">
                            <label>Nombe de usuario</label>
                            <input
                                type="text"
                                id="usernameID"
                                name="username"
                                placeholder="nombre de usuario"
                                maxLenght={"40"}
                                defaultValue={this.state.username}
                                onChange={this.changeHandler}
                                validate="true"
                            />
                            {this.state.userError ?
                                <div className="text-error-class">{this.state.userError}</div>
                            : this.state.nameExist ?
                                <div className="text-error-class">{this.state.nameExist}</div>
                            : null }
                        </div>
                        <div className="input-div">
                            <label>Email</label>
                            <input
                                type="text"
                                id="emailID"
                                name="email"
                                placeholder="email"
                                maxLenght={"70"}
                                defaultValue={this.state.email}
                                onChange={this.changeHandler}
                                validate="true"
                            />
                            {this.state.emailError ?
                                <div className="text-error-class">{this.state.emailError}</div>
                            : this.state.emailExist ?
                                <div className="text-error-class">{this.state.emailExist}</div>
                            : null }
                        </div>
                        <div className="input-div">
                            <label>Contraseña</label>
                            <input
                                type="password"
                                id="passwordID"
                                name="password"
                                minLenght={"6"}
                                maxLenght={"30"}
                                placeholder="min 6 carateres"
                                defaultValue={this.state.password}
                                onChange={this.changeHandler}
                                validate="true"
                            />
                            {this.state.pwError ?
                                <div className="text-error-class">{this.state.pwError}</div>
                            : null}
                        </div>
                    </div> }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={this.props.close}>
                        Cerrar
                    </Button>
                    { this.props.name === "Promoción" ?
                        <Button variant="info" onClick={this.addNewPromo}>
                            Guardar
                        </Button>
                    : this.props.name === "Rutina" ?
                        <Button variant="info" onClick={this.addNewRoutine}>
                            Guardar
                        </Button>
                    :
                        <Button variant="info" onClick={this.addNewUser}>
                            Guardar
                        </Button>
                    }
                </Modal.Footer>
          </Modal>
        );
    }

    render() {
        return this.renderDash();
    };
}

export default ModalAdd;
