import React, { Component } from 'react';
import Sidebar from '../../components/Sidebar';
import Navigation from '../../components/Navigation/Navigation';
import axios from 'axios';

class Classes extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            publishedPress: []
        };

    }

    async getPublishedPress() {

    }

    componentDidMount() {
        this.getPublishedPress();
    }

    render() {

        const thead = [];
        const tbody = [];

        return (
          <div className="content">
              <Navigation user={this.props.user}/>
              <Sidebar
                  active="press"
                  type={this.props.type}
              />

              <div className="main">
                  <h2>Prensa</h2>
                  <div className="content-dashboard">
                      <div className="table-content">
                          <table>
                              {thead}
                              {tbody}
                          </table>
                      </div>
                  </div>
              </div>

          </div>
        );
    };

}

export default Classes;
