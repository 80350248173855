import React, {Component} from 'react';
import {Form, Button} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import axios from 'axios';
import Loading from "../../Utils/Loading";
import './style.css'

class RecoverPasswordForm extends Component {

    constructor(props){
        super(props);

        this.state = {
            loading: false,
            password: '',
            confirmPassword : '',
            success : false,
            errors: {
                general: '',
                password: '',
                confirm_password : ''
            }
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.openBodyBarre = this.openBodyBarre.bind(this)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.token !== prevProps.token){
            this.validateToken()
        }
    }

    validateInput() {
        let approve = true;
        let errors = {
            general: '',
            password: '',
            confirm_password : ''
        }

        if ( this.state.password.length < 4 ) {
            errors.password = "INGRESA UNA CONTRASEÑA VALIDA";
            approve = false;
        }
        if ( this.state.password !== this.state.confirmPassword ) {
            errors.confirm_password = "LAS CONTRASEÑAS NO COINCIDEN";
            approve = false;
        }
        this.setState({
                errors : errors
            }
        )
        return approve
    }

    validateToken(){
        let token = this.props.token
        axios.post('/api/user/validate_token', {
            token : token
        }).then(res => {
            if (!res.data.valid || !res.data.Success) {
                window.location.href="/"
            }
        }).catch( () => {
            window.location.href="/"
        })
    }

    openBodyBarre(){
        window.location.href = "BodyBarreApp://"
    }

    handleSubmit(e){
        e.preventDefault();

        if(this.validateInput()) {

            this.setState({loading: true});

            axios.post('/api/user/change_password', {
                password: this.state.password,
                confirmPassword : this.state.confirmPassword,
                token : this.props.token
            })
                .then(response => {
                    if (response.data.Success && response.data.valid)
                        this.setState({
                            success : true,
                            loading: false
                        });
                })
                .catch(error => {
                    this.setState({loading: false});
                });
        }
    }

    handleInputChange(e) {
        const target = e.target;
        const value = target.type === 'checkbox'
            ? target.checked
            : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    render() {

        const errorPassword = (this.state.errors.password !== '')
            ? <span className="input-error" role="alert">
                {this.state.errors.password}
            </span>
            : '';

        const errorPasswordConfirm = (this.state.errors.confirm_password !== '')
            ? <span className="input-error" role="alert">
                {this.state.errors.confirm_password}
            </span>
            : '';

        const errors = (this.state.errors.general !== '')
            ? <span className="invalid-feedback" role="alert">
                {this.state.errors.general}
            </span>
            : '';

        const render = (
            <Form onSubmit={this.handleSubmit}>
                <Form.Group controlId="password">
                    <Form.Control
                        onChange={this.handleInputChange}
                        value={this.state.password}
                        name="password"
                        type="password"
                        className='input-login'
                        placeholder="PASSWORD" />
                    {errorPassword}
                    <span className="invalid-feedback" role="alert">
                    </span>
                </Form.Group>
                <Form.Group controlId="password">
                    <Form.Control
                        onChange={this.handleInputChange}
                        value={this.state.confirmPassword}
                        name="confirmPassword"
                        type="password"
                        className='input-login'
                        placeholder="CONFIRM PASSWORD" />
                    {errorPasswordConfirm}
                    <span className="invalid-feedback" role="alert">
                    </span>
                </Form.Group>
                {this.state.success ?
                    <p className={"success"}>Se ha cambiado la contraseña exitosamente</p>
                    :
                    <Button className="btn-block btn-login" type="submit">
                        CHANGE PASSWORD
                    </Button>
                }
                <Link
                    to="/"
                    className="signup-link"
                >
                    LOGIN
                </Link>
            </Form>);

        return (<>
            {(this.state.loading) ? <Loading /> : ''}
            {errors}
            {render}
        </>);

    }
}

export default RecoverPasswordForm;
