import React, {Component} from 'react';
import {Modal, Button} from 'react-bootstrap';
import axios from 'axios';
import "./styleEdit.css";

class ModalEdit extends Component {
    constructor(props){
        super(props);
        this.state = {
            nameRoutine:'',
            desc:'',
            nameError: false,
            descError: false,
            nameExist: false
        };
        this.editRoutine = this.editRoutine.bind(this);
        this.changeHandler = this.changeHandler.bind(this);

    }
    componentDidMount() {
        this.setState({
            show: this.props.show,
            close: this.props.close
        });
    }
    changeHandler(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    editRoutine= async () => {
        let error=false;
        document.getElementById("nameid").classList.remove("input-error");
        document.getElementById("descid").classList.remove("input-error");
        this.setState({
            nameError: false,
            descError: false
        });
        if(this.state.nameRoutine.length < 1){
            document.getElementById("nameid").classList.add("input-error");
            document.getElementById("nameid").focus();
            await this.setState({nameError: "No puede ir vacio"});
            error=true;
        }
        if(this.state.desc.length < 1){
            document.getElementById("descid").classList.add("input-error");
            document.getElementById("descid").focus();
            await this.setState({descError: "No puede ir vacio"});
            error=true;
        }
        if(error) return;
        const rutina={
            id: this.props.id,
            name: this.state.nameRoutine,
            description: this.state.desc
        }
        return await axios.post('/api/routines/editRoutine', rutina)
        .then(res => {
            window.location.reload();
        })
        .catch(err => {
            //console.log(err)
        });
    }
    renderDash(){
        return (
            <Modal
                show={this.props.show}
                onHide={this.props.close}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{this.props.nameCategorie}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="input-div">
                        <div className="input-div">
                            <label>Nombre</label>
                            <input
                                type="text"
                                id="nameid"
                                name="nameRoutine"
                                placeholder="Nombre del video"
                                maxLenght={"40"}
                                defaultValue={this.props.name}
                                onChange={this.changeHandler}
                                validate="true"
                            />
                            {this.state.nameError ?
                                <div className="text-error-class">{this.state.nameError}</div>
                            : null }
                        </div>
                        <div className="input-div">
                            <label>Descripci&oacute;n</label>
                            <textarea
                                type="text"
                                id="descid"
                                name="desc"
                                placeholder="Descripción del video"
                                maxLenght={"2500"}
                                defaultValue={this.props.description}
                                onChange={this.changeHandler}
                                validate="true"
                            >
                            </textarea>
                            {this.state.descError ?
                                <div className="text-error-class">{this.state.descError}</div>
                            : null }
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={this.props.close}>
                        Cerrar
                    </Button>
                    <Button variant="info" onClick={this.editRoutine}>
                        Editar
                    </Button>
                </Modal.Footer>
          </Modal>
        );
    }

    render() {
        return this.renderDash();
    };
}

export default ModalEdit;
