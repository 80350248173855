import React, { Component } from 'react';
import Sidebar from '../../components/Sidebar';
import Navigation from '../../components/Navigation/Navigation';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import axios from 'axios';
import AddClass from '../../components/Classes/Modal/AddClass';

//import EventElement from '../../components/Clases/EventElement';

class Classes extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            events: [],
            estudios: [],
            search: -1,
            clases: [],
            showModalAdd: false
        };

        this.localizer = momentLocalizer(moment);
        this.handleSelect = this.handleSelect.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
    }

    componentDidMount() {
        this.getStudios();
        if(this.props.type !== 3){
            this.getClases();
        }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.type == 3 && !prevState.estudios.length && this.state.estudios.length === 1){
            this.getClasesOnUpdate();
        }
    }

    async getClasesOnUpdate(){
        await this.setState({
            search : this.state.estudios[0].id
        })
        this.getClases();
    }

    toggleModal() {
        this.setState({
            showModalAdd: !this.state.showModalAdd
        });
    };

    getStudios = async () => {
        await axios.get('/api/studio/getSelectStudios').then(res => {
            if (res.status === 200) {
                this.setState({
                    estudios: res.data
                });
            }
        });
    };

    drawSelect() {
        let studios = [...this.state.estudios];
        let profile = this.props.profile;
        let type = this.props.type
        let options = [];
        if (studios.length > 0) {
            if (profile || type !== 3) {
                options = studios.map(el => <option key={el.id} value={el.id}>{el.name}</option>);
            }
        }
        return (<select className="select"
                        name="estudio"
                        id="estudioSelect"
                        onChange={this.handleSelect}
        >
            {type !== 3 ?
                <option value="-1">TODOS</option>
                :
                null
            }
            {options}
        </select>);
    }

    handleSelect = async (e) => {
        let studio = parseInt(e.target.value);
        this.setState({
            search: studio
        });
        this.getClases(studio);
    };

    getClases = async (studio = this.state.search) => {
        await axios.get('/api/clase/clasesByStudio', { params: { estudio: studio } })
            .then(async res => {
                if (res.data.Success) {
                    let events = [];
                    let clases = res.data.clases;
                    await clases.map((el, index) => {
                        events.push({
                            id: el.id,
                            title: el.name,
                            start: new Date(moment(el.start).local().format('YYYY-MM-DDTHH:mm:ss')),
                            end: new Date(moment(el.start).add(el.duration, 'minutes').local().format('YYYY-MM-DDTHH:mm:ss'))
                        });
                        return el;
                    });
                    this.setState({
                        events: events,
                        clases: clases
                    });
                }
            });
    };

    chooseEvent = async (obj) => {
        window.location = '/class/detail/' + obj.id;
    };

    render() {

        const modalAdd = this.state.showModalAdd
            ? <AddClass show={this.state.showModalAdd}
                        toggleModal={this.toggleModal}
                        studios={this.state.estudios}
                        events={this.state.events}
            />
            : null;

        return (
            <div className="content">
                <Navigation user={this.props.user}/>
                <Sidebar
                    active="classes"
                    type={this.props.type}
                />
                <div className="main">
                    <h2>Clases</h2>
                    <div className="content-dashboard">
                        <div className="mb-4 d-flex ">
                            {this.drawSelect()}
                            <button className="ml-auto btn-normal add"
                                    onClick={this.toggleModal}
                                    type={'button'}>
                                Programar
                            </button>
                        </div>
                        <div>
                            <Calendar localizer={this.localizer}
                                      events={this.state.events}
                                      startAccessor="start"
                                      endAccessor="end"
                                      style={{ height: 500 }}
                                      onSelectEvent={(e) => this.chooseEvent(e)}
                            />
                        </div>
                    </div>
                </div>
                {modalAdd}


            </div>
        );
    };

}

export default Classes;
