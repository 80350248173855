import React, {Component} from 'react';
import {Form, Button} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import axios from 'axios';
import Loading from "../../Utils/Loading";
import './style.css'

class SignUpUserForm extends Component {

    constructor(props){
        super(props);

        this.state = {
            loading: false,
            name: '',
            lastname: '',
            email: '',
            password: '',
            errors: {
                general: '',
                name: '',
                lastname: '',
                email: '',
                password: ''
            }
        };

        this.emailValidator = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.openBodyBarre = this.openBodyBarre.bind(this)
    }

    validateInput() {
        let approve = true;
        let errors = {
            general: '',
            name: '',
            lastname: '',
            email: '',
            password: ''
        }
        if (this.state.name.length === 0) {
            errors.name = "NOMBRE ES REQUERIDO";
            approve = false;
        }
        if (this.state.lastname.length === 0) {
            errors.lastname = "APELLIDO ES REQUERIDO";
            approve = false;
        }
        if (!this.emailValidator.test(this.state.email) || this.state.email.length === 0 ) {
            errors.email = "INGRESA UN CORREO VALIDO";
            approve = false;
        }
        //console.log(errors)
        this.setState({
            errors : errors
            }
        )
        return approve
    }

    openBodyBarre(){
        window.location.href = "BodyBarreApp://"
    }

    handleSubmit(e){
        e.preventDefault();

        if(this.validateInput()) {

            this.setState({loading: true});

            axios.post('/api/mesasana/register_mesasana', {
                email: this.state.email,
                name: this.state.name,
                last_name: this.state.lastname,
                device: {}
            })
                .then(response => {
                    if (response.status === 200)
                        window.location.replace('https://mesasana.bodybarre.com.mx/');
                })
                .catch(error => {
                    //console.log('response-fail: '+ error.response);
                    this.setState({loading: false});
                });
        }
    }

    handleInputChange(e) {
        const target = e.target;
        const value = target.type === 'checkbox'
            ? target.checked
            : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    render() {

        const errorName = (this.state.errors.name !== '')
            ? <span className="input-error" role="alert">
                {this.state.errors.name}
            </span>
            : '';

        const errorLastname = (this.state.errors.lastname !== '')
            ? <span className="input-error" role="alert">
                {this.state.errors.lastname}
            </span>
            : '';

        const errorEmail = (this.state.errors.email !== '')
            ? <span className="input-error" role="alert">
                {this.state.errors.email}
            </span>
            : '';

        const errorPassword = (this.state.errors.password !== '')
            ? <span className="input-error" role="alert">
                {this.state.errors.password}
            </span>
            : '';

        const errors = (this.state.errors.general !== '')
            ? <span className="invalid-feedback" role="alert">
                {this.state.errors.general}
            </span>
            : '';

        const render = (
            <Form onSubmit={this.handleSubmit}>
                <Form.Group controlId="name">
                    <Form.Control
                        onChange={this.handleInputChange}
                        value={this.state.name}
                        name="name"
                        type="text"
                        className='input-login'
                        placeholder="NOMBRE(S)" />
                    {errorName}
                </Form.Group>
                <Form.Group controlId="lastname">
                    <Form.Control
                        onChange={this.handleInputChange}
                        value={this.state.lastname}
                        name="lastname"
                        type="text"
                        className='input-login'
                        placeholder="APELLIDOS" />
                    {errorLastname}
                </Form.Group>
                <Form.Group controlId="email">
                    <Form.Control
                        onChange={this.handleInputChange}
                        value={this.state.email}
                        name="email"
                        type="text"
                        className='input-login'
                        placeholder="EMAIL" />
                    {errorEmail}
                </Form.Group>
                <Button className="btn-block btn-login" type="submit">
                    SIGN UP
                </Button>
                <Link
                    to="/mesasana"
                    className="signup-link"
                >
                    LOGIN
                </Link>
            </Form>);

        return (<>
            {(this.state.loading) ? <Loading /> : ''}
            {errors}
            {render}
        </>);

    }
}

export default SignUpUserForm;
