import React, { Component, Fragment } from 'react'
import Navigation from '../../components/Navigation/Navigation';
import Sidebar from '../../components/Sidebar';
import {Col, Form,Row, Card} from "react-bootstrap";
import axios from 'axios'
import Swal from 'sweetalert2'
import moment from 'moment';
import MUIDataTable from 'mui-datatables';


class InfoAlumnas extends Component{

    constructor(props){
        super(props);
        this.state = {
            mail: null,
            alumna: null,
            detail: false
        }
        this.handleMailSearch = this.handleMailSearch.bind(this);
    }

    getUserData = async() => {
        let respuesta = await axios.get('/api/user/getUserDats', {
            params: { mail: this.state.mail }
        });
        if(respuesta.data.Success){
            if(respuesta.data.user){
                this.setState({
                    alumna: respuesta.data.user,
                    detail: {
                        clases: respuesta.data.clases,
                        membresia: respuesta.data.membresia,
                        reservaciones: respuesta.data.packages
                    }
                });
                console.log(respuesta.data.packages)
                document.getElementById('usernameinput').value = '';
            }else{
                Swal.fire('Datos de usuario no encontrados', 'Favor de validar el correo ingresado', 'error')
            }
        }
    }

    handleMailSearch(e){
        this.setState({
            [e.target.name] : e.target.value
        });
    }

    drawReservations(titulo, reservationes){
        const options = {
            filterType: "dropdown",
            selectableRows: 'none',
            rowHover: false,
            print: false,
            download: false,
            responsive: 'simple',
            tableBodyHeight:"",
            tableBodyMaxHeight:"",
            filterType: 'checkbox',
            setRowProps: (row, dataIndex, rowIndex) => {
                return {
                    className: (row[6] === 'Cancelada') ? "red-row" : ( row[6] === 'Inasistencia' || row[6] === 'Penalizada' ) ? "yellow-row" : "",
                    style: (row[6] === 'Cancelada') ? {color:'red'} : {},
                }
            },
            textLabels: {
                body: {
                  noMatch: "No se encontraron registros",
                  toolTip: "Ordenar",
                  columnHeaderTooltip: column => `Ordenar por ${column.label}`
                },
                pagination: {
                  next: "Siguiente página",
                  previous: "Página anterior",
                  rowsPerPage: "Registros por página:",
                  displayRows: "de",
                },
                toolbar: {
                  search: "Buscar",
                  viewColumns: "Ver columnas",
                  filterTable: "Filtrar tabla",
                },
                filter: {
                  all: "TODOS",
                  title: "FILTROS",
                  reset: "RESET",
                },
                viewColumns: {
                  title: "Mostrar columnas",
                  titleAria: "Mostrar/Ocultar columnas",
                },
                selectedRows: {
                  text: "Registro(s) seleccionado(s)",
                  delete: "Delete",
                  deleteAria: "Delete Selected Rows",
                }
            }
        }
        let clase = [];

        const columns = [{name:'Nombre Clase',options:{filter:false}},{name:'Studio',options:{filter:false}}, {name:'Categoria'},{name:'No. Mat',options:{filter:false}}, {name:'Código Reservación',options:{filter:false}}, {name:'Fecha Reservación',options:{filter:false}}, {name:'Estado Reservación',options:{filter:false}}, {name:'Fecha Actualización',options:{filter:false}}];
        reservationes.forEach(reser => {
            clase.push([
                reser.class_name, 
                reser.estudio,
                reser.categoria_name,
                reser.mat_place, 
                reser.reservation_code, 
                moment(reser.reservation_date).format('DD MMM YYYY hh:mm:ss A'), 
                reser.reservation_status, 
                moment(reser.reservation_update).format('DD MMM YYYY hh:mm:ss A')                
            ])
        });
        return(
            <MUIDataTable
                data={clase}
                columns={columns}
                options={options}
            />
        )
    }

    async pasaClase(category){  
        let categoria = (category == 1) ? 'Presencial' : (category == 2) ? 'En Linea' : (category == 3) ? 'Teens' : '';
        let alumna = this.state.mail;  
        Swal.fire({
            title: '¡Transferir Clase ' + categoria + '!',
            html: `<input type="mail" id="mailpasaalumna" class="swal2-input" placeholder="Usuaria BodyBarre App" required>`,
            confirmButtonText: '¡Pasar clase!',
            focusConfirm: false,
            allowOutsideClick: false,
            showCancelButton: true,
            preConfirm: async () => {
                const login = Swal.getPopup().querySelector('#mailpasaalumna').value
                if (!login) {
                    Swal.showValidationMessage(`Favor de ingresar un correo`)
                }
                let data = {
                    email: alumna,
                    emailDestino: login,
                    category: category
                };
                if(login != alumna){
                    let resultadito = await axios.post('/api/clase/transferClass', data).then( res => {
                        if (res.status == 200) {
                            
                            if(res.data.Success){
                                this.getUserData();
                                return {icon: 'success', text: res.data.mensaje}
                            }else{
                                return { icon: 'error', text: res.data.error }
                            }
                        }
                    });
                    return resultadito
                }else{
                    return { icon: 'error', text: 'No puedes ingresar el mismo mail' }
                }
            }
          }).then((result) => {
                if(!result.isDismissed){
                    Swal.fire(result.value)
                }
          })
    }
    drawDetail() {
        let cuadros = [];
        let reserve = [];
        let reservaciones = {...this.state.detail.reservaciones};
        let classes = {...this.state.detail.clases};
        let botonesTransfer = {
            1: <button className="btn btn-success" onClick={() => { this.pasaClase(1) } }>{'Transferir'}</button>,
            2: <button className="btn btn-success" onClick={() => { this.pasaClase(2) } }>{'Transferir'}</button>,
            3: <button className="btn btn-success" onClick={() => { this.pasaClase(3) } }>{'Transferir'}</button>
        }
        
        for (var [key, value] of Object.entries(classes)) {            
            moment.locale('es-mx')
            cuadros.push(
                <div key={key} className="col-sm-3 col-lg-3">
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col sm="6">
                                    <div><h4 style={{color:value['category.color']}}>{value['category.name']}</h4></div>
                                    <div>Disponibles: <h4 style={{color:value['category.color']}}>{value['available']}</h4></div>
                                </Col>
                                <Col sm="6">
                                    <div>Vigencia: <h4 style={{color:value['category.color']}}>{ (value['available'] > 0) ? moment(value['due_date']).format('DD  MMM YYYY') : '-' }</h4></div>
                                    <div>Usadas: <h4 style={{color:value['category.color']}}>{value['used']}</h4></div>
                                    <div>{ (value['available'] > 0) ? botonesTransfer[value['category.id']] : '' }</div>                                                                       
                                </Col>
                            </Row>                            
                        </Card.Body>                        
                    </Card>
                </div>
            )
        }

        if(this.state.detail.membresia){
            
            let fecha = moment();
            let start = moment(this.state.detail.membresia['due_time']);
            let duracion = moment.duration(fecha.subtract(start));

            moment.locale('es-mx')
            cuadros.push(
                <div className="col-sm-3 col-lg-3">
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col sm="6">
                                    <div>Membres&iacute;a <h4>{this.state.detail.membresia['membership.name']}</h4></div>
                                </Col>
                                <Col sm="6">
                                    <div>Vigencia: <h4>{ ( (duracion.months() < 1) && (duracion.days() < 1) && (duracion.hours() < 1) ) ? moment(this.state.detail.membresia['due_time']).format('DD MMM YYYY hh:mm:ss a') : '-' }</h4></div>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </div>
            )
        }
        
        if(reservaciones){
            if(reservaciones.estudio.length > 0){
                reserve.push(
                    <div className="col-sm-12 col-lg-12">
                        <Card>
                            <Card.Body style={{padding:'0'}}>
                                <Row>
                                    <Col sm="12">
                                        { this.drawReservations('', reservaciones.estudio)}
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </div>
                );
            }
        }
        

        return(
            <div className="container-fluid">
                <Row>
                    <Col lg="12">
                        <div className="form-row" style={{width:'100%'}}>
                            <div className="form-group col-lg-3">
                                <label for="inptName"> Nombre Alumna:</label>
                                <input id="inptName" className="form-control" disabled value={this.state.alumna['profile.name'] + ' ' + this.state.alumna['profile.last_name']}></input>
                            </div>
                            <div className="form-group col-lg-3">
                                <label for="inptName"> Mail:</label>
                                <input id="inptmeil" className="form-control" disabled value={this.state.alumna['email']}></input>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    {cuadros}
                </Row>
                <Row className="mt-4">
                    <Col lg="12">
                        <h4>Reservaciones</h4>
                            <Row>
                                {reserve}
                            </Row>
                    </Col>
                </Row>
            </div>
        );
    }

    renderDash(){
        return(<div className="content">
                    <Navigation user={this.props.user}/>
                    <Sidebar active={this.props.select} type={this.props.type}/>
                    <div className="main">
                        <h2>Informaci&oacute;n de alumnas</h2>
                        <div className="container-fluid" style={{width:"100%"}}>
                            <div className="row" style={{width:"100%"}}>
                                <Form.Row className="align-items-center" style={{width:"100%"}}>
                                    <label>Correo de la alumna:</label>
                                    <div className="col-lg-3">
                                        <label className="sr-only" for="usernameinput">Correo</label>
                                        <div className="input-group mb-2">
                                            <div className="input-group-prepend">
                                                <div className="input-group-text">@</div>
                                            </div>
                                            <input type="email" className="form-control" id="usernameinput" name="mail" onChange={this.handleMailSearch} placeholder="Correo" required/>
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        <button type="submit" className="btn btn-primary mb-2" onClick={this.getUserData}>Buscar</button>
                                    </div>
                                </Form.Row>
                            </div>
                            <div className="row">{ (this.state.detail) ? this.drawDetail() : '' }
                            </div>
                        </div>
                    </div>
                </div>
        )
    }

    render() {
        return(
            this.renderDash()
        )
    }
}

export default InfoAlumnas;