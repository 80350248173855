import React, {Component} from 'react';
import Sidebar from "../../components/Sidebar";
import Navigation from '../../components/Navigation/Navigation';
import Admin from "../../components/Users/Admin";
import Partner from "../../components/Users/Partner";
import Receptionist from "../../components/Users/Receptionist";

class Users extends Component {

    constructor(props){
        super(props);
        this.state = {
            loading: true,
            selected: '',
            users: []
        };
    }
    componentDidMount() {
        this.setState({selected: this.props.select});
    }

    render(){
        return(
            <div className="content">
                <Navigation user={this.props.user}/>
                <Sidebar
                    active={this.props.select}
                    type={this.props.type}
                />
                    <div className="main">
                    {this.state.selected === "admin" ?
                        <Admin user={this.props.user}/>
                    : this.state.selected === "partner" ?
                        <Partner/>
                    : this.state.selected === "receptionist" ?
                        <Receptionist/>
                    : null }
                </div>
            </div>
        )
    };
}

export default Users;
