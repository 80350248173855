import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import './style.css';
import axios from 'axios';

class Subscriptions extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email : "",
            phone : '',
            description : '',
            success : null
        }

        this.handleChange = this.handleChange.bind(this)
    }

    handleChange (e) {
        this.setState({
            [e.target.name] : e.target.value
        })
    }

    send = async () => {
        let email = this.state.email;
        let description = this.state.description;
        let phone = this.state.phone;
        if(email.length === 0 && description.length === 0){
            return false
        }
        await axios.post('/api/user/contact', {
            email : email,
            phone : phone,
            description : description
        }).then( (res) => {
            if(res.data.Success){
                this.setState({
                    success : "Se ha enviado su mensaje"
                })
            }
            else{
                this.setState({
                    success : "Se ha enviado su mensaje"
                })
            }
        }).catch( () => {
            this.setState({
                success : "Se ha enviado su mensaje"
            })
        })
    }

    render() {
        return (
            <div className="content">
                <div className="main">
                    <Row className="justify-content-md-center">
                        <Col xs={12} md={8} lg={8}>
                            <img className={"contact-logo"} src="https://bbarrecnt.sfo3.cdn.digitaloceanspaces.com/app/brand_alpha.png" alt="BodyBarre"/>
                            <div className="contact-form">
                                <input name="email" type="text" placeholder={"Email"} onChange={this.handleChange}/>
                                <input name="phone" type="text" placeholder={"Teléfono (opcional)"} onChange={this.handleChange}/>
                                <textarea name="description" id="" cols="30" rows="10" placeholder={"Escribe tu mensaje"} onChange={this.handleChange}></textarea>
                                <button
                                    tyoe="button"
                                    onClick={() => this.send()}>
                                    Enviar
                                </button>
                                {this.state.success ?
                                    <p className={"success"}>{this.state.success}</p>
                                    : null
                                }
                            </div>
                            <div className="contatc-info">
                                <p>
                                    o Contáctanos al +52 55 2091 8085
                                </p>
                            </div>
                        </Col>
                    </Row>

                </div>
            </div>
        );
    };

}

export default Subscriptions;
