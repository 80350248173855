import React, { Component } from 'react';
import Sidebar from '../../components/Sidebar';
import Navigation from '../../components/Navigation/Navigation';
import ReporteIngresos from '../../components/Reportes/ReporteIngresos';
import ReporteUsuarios from '../../components/Reportes/ReporteUsuarios';
import VistasComentarios from '../../components/Reportes/VistasComentarios';
import ReporteRedenciones from '../../components/Reportes/Redenciones';
import GoGlowCodes from '../../components/Reportes/goGlowCodes';
import IngresosReporteSistemas from '../../components/Reportes/IngresosReporteSistemas';
import ClasesPresenciales from '../../components/Reportes/ClasesPresenciales';

import './style.css';

class Reportes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reporte : props.reporte,
            active : [
                '',
                'reporte_ingresos',
                'reporte_usuarios',
                'reporte_lives',
                'reporte_codigos',
                'ReporteRedenciones',
                'reporte_goglow',
                'reporte_ingresos_sistemas',
                'reporte_presenciales'
            ]
        };
    }

    componentDidMount() {
    }

    renderReporte() {
        switch (this.state.reporte) {
            case 1:
                return <ReporteIngresos />
                break;
            case 2:
                return <ReporteUsuarios />
                break;
            case 4:
                return <ReporteRedenciones />
                break;
            case 5:
                return <VistasComentarios />
                break;
            case 6:
                return <GoGlowCodes />
                break;

            case 7:
                return <IngresosReporteSistemas />
                break;

            case 8:
                return <ClasesPresenciales/>
                break;
        }
    }

    render() {
        return (
            <div className="content">
                <Navigation user={this.props.user}/>
                <Sidebar
                    active={this.state.active[this.props.reporte]}
                    type={this.props.type}
                />
                {this.renderReporte()}
            </div>
        )
    };

}

export default Reportes;
