import React, { Component } from 'react';
import './style.css';

class Mapa extends Component {
    constructor(props) {
        super(props);
        let order = props.order
        this.state = {
            mapa: order ? typeof order === "object" ? order : JSON.parse(order) : null,
            pickable : true,
            picked: null
        };


    }


    componentDidMount() {
    }

    pickPlace = async (place) => {
        this.props.blockPlace(place)
    }

    drawBack() {
        let lugares = this.state.mapa.back
        let places = [];
        let size = Math.round(10000 / lugares.length) / 100
        let reserved = this.props.reserved;
        let blocked = this.props.blocked;
        let picked = this.state.picked;
        for (let lugar of lugares){
            places.push(
                <div
                    className="verticalPlace"
                    style={{
                        width : size + "%"
                    }}
                >
                    {(lugar === 0) ?
                        <div
                            className="entradaPlace"
                        >
                            <div
                                className="entradaBack"
                            >

                            </div>
                            <p
                                className="entradaTextBack"
                            >
                                ENTRADA
                            </p>
                        </div>
                        :
                        <div
                            onClick={() => (reserved.indexOf(lugar) < 0 && this.state.pickable) ? this.pickPlace(lugar) : null}
                            className={
                                "verticalPlaceIcon "
                                + ((blocked.indexOf(lugar) >= 0) ?
                                    "placeIconOcupado"
                                    :
                                    (lugar === picked) ?
                                        "placeIconSeleccion"
                                        :
                                        "")
                            }
                        >
                            <p
                                className="verticalPlaceText"
                            >
                                {lugar}
                            </p>
                        </div>
                    }
                </div>
            )
        }
        return places;
    }

    drawLeft() {
        let lugares = this.state.mapa.left
        let places = [];
        let size = Math.round(10000 / lugares.length) / 100
        let reserved = this.props.reserved;
        let blocked = this.props.blocked;
        let picked = this.state.picked;
        for (let lugar of lugares){
            places.push(
                <div
                    className="horizontalPlace"
                    style={{
                        height : size + "%"
                    }}
                >
                    <div
                        onClick={() => (reserved.indexOf(lugar) < 0 && this.state.pickable) ? this.pickPlace(lugar) : null}
                        className={
                            "horizontalPlaceIcon "
                            + ((blocked.indexOf(lugar) >= 0) ?
                                "placeIconOcupado"
                                :
                                (lugar === picked) ?
                                    "placeIconSeleccion"
                                    :
                                    "")
                        }
                    >
                        <p
                            className="horizontalPlaceText"
                        >
                            {lugar}
                        </p>
                    </div>

                </div>
            )
        }
        return places;
    }

    drawRight() {
        let lugares = this.state.mapa.right
        let places = [];
        let size = Math.round(10000 / lugares.length) / 100
        let reserved = this.props.reserved;
        let blocked = this.props.blocked;
        let picked = this.state.picked;
        for (let lugar of lugares){
            places.push(
                <div
                    className="horizontalPlace"
                    style={{
                        height : size + "%"
                    }}
                >
                    <div
                        onClick={() => (reserved.indexOf(lugar) < 0 && this.state.pickable) ? this.pickPlace(lugar) : null}
                        className={
                            "horizontalPlaceIcon "
                            + ((blocked.indexOf(lugar) >= 0) ?
                                "placeIconOcupado"
                                :
                                (lugar === picked) ?
                                    "placeIconSeleccion"
                                    :
                                    "")
                        }
                    >
                        <p
                            className="horizontalPlaceText"
                        >
                            {lugar}
                        </p>
                    </div>

                </div>
            )
        }
        return places;
    }

    drawFront() {
        let lugares = this.state.mapa.front
        let places = [];
        let size = Math.round(10000 / lugares.length) / 100
        let reserved = this.props.reserved;
        let blocked = this.props.blocked;
        let picked = this.state.picked;
        for (let lugar of lugares){
            places.push(
                <div
                    className="verticalPlace"
                    style={{
                        width : size + "%"
                    }}
                >
                    {(lugar === 0) ?
                        <div
                            className="entradaPlace"
                        >
                            <div
                                className="entrada"
                            >

                            </div>
                            <p
                                className="entradaText"
                            >
                                ENTRADA
                            </p>
                        </div>
                        :
                        <div
                            onClick={() => (reserved.indexOf(lugar) < 0 && this.state.pickable) ? this.pickPlace(lugar) : null}
                            className={
                                "verticalPlaceIcon "
                                + ((blocked.indexOf(lugar) >= 0) ?
                                    "placeIconOcupado"
                                    :
                                    (lugar === picked) ?
                                        "placeIconSeleccion"
                                        :
                                        "")
                            }
                        >
                            <p
                                className="verticalPlaceText"
                            >
                                {lugar}
                            </p>
                        </div>
                    }
                </div>
            )
        }
        return places;
    }


    render() {
        return(
            <div>
                <h6>Mapa: {this.props.name}</h6>
                <div className="mapa">
                    <div className="back">
                        {this.drawBack()}
                    </div>
                    <div className="middle">
                        <div className="left">
                            {this.drawLeft()}
                        </div>
                        <div className="center">
                            <img src="https://bbarrecnt.sfo3.cdn.digitaloceanspaces.com/app/brand_alpha.png" alt="logo"/>
                        </div>
                        <div className="right">
                            {this.drawRight()}
                        </div>
                    </div>
                    <div className="front">
                        {this.drawFront()}
                    </div>
                </div>
            </div>
        )
    }
}

export default Mapa;
