import React, { Component, useState } from 'react';
import axios from 'axios';
import {Row, Col} from "react-bootstrap";
import MUIDataTable from "mui-datatables";

class goGlowCodes extends Component{
    constructor(props) {
        super(props);
        this.state = {
            codes:[]
        };
    }

    componentDidMount() {
        this.analisis()
    }

    analisis = async() => {
        await axios.post('/api/reportes/goGlowCodes')
            .then(res=>{
                if(res.status == 200){
                    this.setState({codes: res.data});
                }else{
                    this.setState({codes: []});
                }
            })
            .catch(err=>{
                this.setState({codes: []});
            });
    }

    formatMoney = (n, c, d, t) => {
        c = isNaN(c = Math.abs(c)) ? 2 : c;
        d = d === undefined ? "." : d;
        t = t === undefined ? "," : t;
        if(isNaN(n)){
            return n
        }
        let s = n < 0 ? "-" : "";
        let i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c)));
        let k = (i.length);
        let j = k > 3 ? k % 3 : 0;
        return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
    }

    parseInttoMonth = (date) => {
        var months = { '01' : 'Enero', '02': 'Febrero', '03' : 'Marzo', '04' : 'Abril', '05' : 'Mayo', '06' : 'Junio', '07' : 'Julio', '08' : 'Agosto', '09' : 'Septiembre', '10' : 'Octubre', '11' : 'Noviembre', '12' : 'Diciembre'}
        var date = new Date(date)
        var mes = ((date.getMonth() + 1) < 10) ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1) ;
        
        return months[mes];
    }

    parseInttoYear = (date) => {
        var date = new Date(date)
        var anno = date.getFullYear();
        
        return anno;
    }

    renderObject(){ 
        if (this.state.codes.length === 0) {
            return null;
        }
        let codes = this.state.codes;
        let data = [];
        for (let index = 0; index < codes.length; index++) {
            const element = codes[index];
            data.push(
                [codes[index].code,
                codes[index].description,
                codes[index].usName,
                codes[index].email, 
                "$" + this.formatMoney(codes[index].subtotal), 
                "$"+this.formatMoney(codes[index].discount),
                "$"+this.formatMoney(codes[index].total),
                this.parseInttoMonth(codes[index].fecha),
                this.parseInttoYear(codes[index].fecha)]
            )
        }

        const columns = ["Código", "Método de Pago", "Usuario", "Mail", "Subtotal", "Descuento", "Total", "Mes", "Año"];

        const options = {
            filter: true,
            filterType: "dropdown",
            selectableRows: 'none',
            rowHover: true,
            print: false,
            download: true,  
            responsive: 'simple',
            tableBodyHeight:"400px",
            tableBodyMaxHeight:"",
            downloadOptions: {
                filename: 'Reporte-GoGlow.csv',
                filterOptions: {
                  useDisplayedColumnsOnly: true,
                  useDisplayedRowsOnly: true,
                }
            },
            textLabels: {
                body: {
                  noMatch: "No se encontraron registros",
                  toolTip: "Ordenar",
                  columnHeaderTooltip: column => `Ordenar por ${column.label}`
                },
                pagination: {
                  next: "Siguiente página",
                  previous: "Página anterior",
                  rowsPerPage: "Registros por página:",
                  displayRows: "de",
                },
                toolbar: {
                  search: "Buscar",
                  downloadCsv: "Descargar CSV",
                  print: "Print",
                  viewColumns: "Ver columnas",
                  filterTable: "Filtrar tabla",
                },
                filter: {
                  all: "TODOS",
                  title: "FILTROS",
                  reset: "RESET",
                },
                viewColumns: {
                  title: "Mostrar columnas",
                  titleAria: "Mostrar/Ocultar columnas",
                },
                selectedRows: {
                  text: "Registro(s) seleccionado(s)",
                  delete: "Delete",
                  deleteAria: "Delete Selected Rows",
                },
            }
        };

        return (
            <MUIDataTable
                title={""}
                data={data}
                columns={columns}
                options={options}
            />
        );
    }

    render(){
        return (
            <div className="main">
                <h4 className="client">Redenciones GoGlow</h4>
                <Row className="reporte" style={ { height:"90%", width:'98%',display: 'table', tableLayout:'fixed' } }>
                    {this.renderObject()}                    
                </Row>
            </div>);
    }
}

export default goGlowCodes;
