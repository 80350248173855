import React, {Component} from 'react';
import {Form, Button} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import axios from 'axios';
import Loading from "../../Utils/Loading";
import './style.css'

class Login extends Component {

    constructor(props){
        super(props);

        this.state = {
            loading: false,
            username: '',
            password: '',
            errors: {
                general: '',
                username: '',
                password: ''
            },
            recoveredError : null,
            recovered : false
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.openBodyBarre = this.openBodyBarre.bind(this)
        this.recoverPwd = this.recoverPwd.bind(this)
    }

    validateInput(){
        let minLength = 4;
        return ( this.state.password.length >= minLength );
    }

    openBodyBarre(){
        window.location.href = "BodyBarreApp://"
    }

    recoverPwd = async () => {
        let user = this.state.username;
        if(user !== ""){
            let data = {
                user: user,
            }
            await axios.post("/api/user/recoverPassword", data).then(async (res) => {
                if(res.data.Success && res.data.valid){
                    this.setState({
                        recovered : true,
                        recoveredError : null
                    })
                }
                else{
                    this.setState({
                        recoveredError : res.data.Error
                    })
                }
            }).catch(() => {
                this.setState({
                    recoveredError : "Algo salio mal"
                })
            })
        }
        else{
            this.setState({
                recoveredError : "Ingresa tu correo electrónico"
            })
        }
    }

    handleSubmit(e){
        e.preventDefault();

        if(this.validateInput()) {

            this.setState({loading: true});

            axios.post('/api/user/login', {
                user: this.state.username,
                pwd: this.state.password,
                device: {}
            })
                .then(response => {
                    if (response.status === 200)
                        //window.location.reload();
                        window.location.replace('/home');
                })
                .catch(error => {
                    console.log('response-fail: '+ error.response);
                    this.setState({loading: false});
                });
        }
    }

    handleInputChange(e) {
        const target = e.target;
        const value = target.type === 'checkbox'
            ? target.checked
            : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    render() {

        const errorEmail = (this.state.errors.username !== '')
            ? <span className="invalid-feedback" role="alert">
                {this.state.errors.username}
            </span>
            : '';

        const errorPassword = (this.state.errors.password !== '')
            ? <span className="invalid-feedback" role="alert">
                {this.state.errors.password}
            </span>
            : '';

        const errors = (this.state.errors.general !== '')
            ? <span className="invalid-feedback" role="alert">
                {this.state.errors.general}
            </span>
            : '';

        const render = (
            <Form onSubmit={this.handleSubmit}>
                <Form.Group controlId="username">
                    <Form.Control
                        onChange={this.handleInputChange}
                        value={this.state.username}
                        name="username"
                        type="username"
                        className='input-login'
                        placeholder="Email" />
                    {errorEmail}
                </Form.Group>

                <Form.Group controlId="password">
                    <Form.Control
                        onChange={this.handleInputChange}
                        value={this.state.password}
                        name="password"
                        type="password"
                        className='input-login'
                        placeholder="Password" />
                    {errorPassword}
                    <span className="invalid-feedback" role="alert">
                    </span>
                </Form.Group>

                <Button className="btn-block btn-login" type="submit">
                    LOG IN
                </Button>
                <Link
                    to="/signup"
                    className="signup-link"
                >
                    SIGN UP
                </Link>
                <span
                    onClick={this.recoverPwd}
                    className="forgotlink"
                >
                    OLVIDE MI CONTRASEÑA
                </span>
                {this.state.recovered ?
                    <p className="success">SE HA ENVIADO UN MAIL CON LAS INSTRUCCIONES PARA RECUPERAR SU CONTRASEÑA</p> : null
                }
                {this.state.recoveredError ?
                    <p className="error">{this.state.recoveredError}</p> : null
                }
            </Form>);

        return (<>
            {(this.state.loading) ? <Loading /> : ''}
            {errors}
            {render}
        </>);

    }
}

export default Login;
