import React, { Component } from 'react';
import { Dropdown } from 'react-bootstrap';
import Sidebar from '../../components/Sidebar';
import Navigation from '../../components/Navigation/Navigation';
import ModalAdd from '../../components/Admin/ModalAdd';
import ModalBan from '../../components/Admin/ModalBan';
import axios from 'axios';
import './stylePromos.css';

class Promos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            search: '',
            promotions: [],
            show: false,
            showBan: false,
            close: false,
            typeUser: 4,
            idClient: '',
            nameClient: '',
            status: '',
            birthdate: '',
            dateClient: '',
            studio: ''
        };
        this.getPromos = this.getPromos.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.convertDateToString = this.convertDateToString.bind(this);
    }

    componentDidMount() {
        this.getPromos();
    }

    getPromos = async () => {
        return await axios.post('/api/payment/promos')
            .then(res => {
                this.setState({ promotions: res.data.promo, loading: false });
            })
            .catch(err => {
                this.setState({ loading: false });
            });
    };
    handleShow = async (e) => {
        await this.setState({
            show: true
        });
    };
    handleShowBan= async (e, id, code, status) => {
        await this.setState({
            showBan: true,
            idPromo: id,
            namePromo: code,
            status: status
        });
    };
    handleClose(e) {
        this.setState({ show: false, showBan: false, showInfo: false });
    }

    convertDateToString(date) {
        var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
        return [year, month, day].join('-');
    }

    renderObject() {
        if (this.state.promotions.length === 0) return null;
        let promotions = this.state.promotions;
        return promotions.map((item, index) => {
            return (
                <tr key={index} className="clientes-body">
                    <td>{item.code}</td>
                    <td>
                        {item.ammount ? item.ammount : '--'}
                    </td>
                    <td>
                        {item.percentage ? item.percentage : '--'}
                    </td>
                    <td>
                        {item.status === 1 ?
                            <button
                                className="btn-normal ban"
                                onClick={(e) => this.handleShowBan(e, item.id, item.code, item.status)}
                            >
                                Desctivar
                            </button>
                        :
                            <button
                                className="btn-normal activate"
                                onClick={(e) => this.handleShowBan(e, item.id, item.code, item.status)}
                            >
                                Activar
                            </button>
                        }
                    </td>
                </tr>
            );
        });
    }

    renderDash() {
        return (
          <div className="content">
                <Navigation user={this.props.user}/>
                <Sidebar
                    active="promos"
                    type={this.props.type}
                />
                <div className="main">
                    <h4 className="client">Promociones</h4>
                    <div className="btn-add-div">
                        <Dropdown>
                            <Dropdown.Toggle variant="info" id="dropdown-basic">
                                +
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item href="#">
                                    <button
                                        className="btn-normal add"
                                        onClick={this.handleShow}
                                    >
                                        Generar nuevo c&oacute;digo
                                    </button>
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>

                    </div>
                    <div className="table-content">
                        <table>
                            <thead>
                            <tr>
                                <th>
                                    C&oacute;digo
                                </th>
                                <th>
                                    Cantidad
                                </th>
                                <th>
                                    Porcentaje
                                </th>
                                <th>

                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.renderObject()}
                            </tbody>
                        </table>
                    </div>
                    <ModalAdd
                        name="Promoción"
                        show={this.state.show}
                        close={this.handleClose}
                        typeUser={this.state.typeUser}
                        packageValue={2300}
                    />
                    <ModalBan
                        id={this.state.idPromo}
                        name={this.state.namePromo}
                        status={this.state.status === 0 ? 1 : 0}
                        nameCategorie={this.state.status === 0 ? 'activar' : 'desactivar'}
                        show={this.state.showBan}
                        close={this.handleClose}
                        section="promos"
                    />
                </div>
            </div>
        );
    }

    render() {
        return this.renderDash();
    };

}

export default Promos;
