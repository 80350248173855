import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';

class Subscriptions extends Component {

    render() {
        return (
          <div className="content">
              <div className="main">
                  <a href="/home">
                      <img
                          alt=""
                          src="https://bbarrecnt.sfo3.cdn.digitaloceanspaces.com/app/logo_bb.png"
                          className="file-head"
                      />
                  </a>
                  <Row className="justify-content-md-center">
                      <Col xs={12} md={8} lg={8}>
                          <h4 className="text-center">
                              Aviso de privacidad
                          </h4>
                          <p className="text-justify">
                              BODY BARRE MEXICO SA DE CV<br />
                              Aviso de privacidad y datos personales
                          </p>
                          <p className="text-justify">
                              Estimado cliente,

                              En BODY BARRE MEXICO SA de CV y sus filiales, estamos conscientes de la importancia de proteger
                              correctamente sus datos personales, es por ello que de conformidad con lo establecido en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares (“LFPDPPP”), que BODY BARRE MEXICO, con domicilio en Seneca 55 Colonia Polanco, CP, 11560 en la Ciudad de México, México, pone a su disposición el siguiente Aviso de Privacidad, a fin de que tenga pleno conocimiento sobre el tratamiento que se le dará a sus datos personales, así como facilitarle el ejercicio de los derechos que la LFPDPPP le otorga.
                          </p>
                          <p className="text-justify">
                              ¿Para qué fines utilizaremos sus datos personales?
                              Los datos personales que BODY BARRE MEXICO recaba y solicita de usted, serán utilizados para las siguientes finalidades, las cuales son necesarias para el servicio que ofrece BODY BARRE MEXICO y el correcto funcionamiento de la aplicación BODY BARRE MEXICO (BB) (en adelante la “App BODY BARRE”):
                          </p>
                          <p className="text-justify">
                              <ol>
                                  <li>Acceder a la plataforma de la “App BODY BARRE” y servicios que ofrece BODY BARRE.</li>
                                  <li>Poder navegar y utilizar la plataforma “App BODY BARRE”.</li>
                                  <li>Agendar y pagar por las clases, servicios y videos que ofrece BODY BARRE</li>
                                  <li>Prestar el servicio de la clase seleccionada conforme a su solicitud y pago.</li>
                                  <li>Video-grabar las clases para BODY BARRE + clases en vivo.</li>
                              </ol>

                          </p>
                          <p className="text-justify">
                              1.- Datos Personales tratados y transparencia.
                          </p>

                          <p className="text-justify">
                              Los datos personales generales que recopilamos de nuestros clientes son: nombre completo, teléfono, género, correo electrónico, fecha de nacimiento y contacto de emergencia incluyendo nombre completo y teléfono del mismo. Asimismo, en el caso de que el cliente opte por pagar los servicios que le presta BODY BARRE a través de cargos recurrentes o pagos domiciliados, se recopilará la información referente a su cuenta bancaria, tarjeta de crédito o tarjeta de débito.
                          </p>

                          <p className="text-justify">
                              Asimismo, para garantizar que las actividades de acondicionamiento físico que realice en las instalaciones de BODY BARRE  sean las adecuadas a su estado de salud, BODY BARRE  podrá recabar los siguientes datos personales sensibles:
                              resultado de pruebas de rendimiento físico, peso, estatura, porcentaje de grasa corporal y masa muscular, alergias, posibles cardiopatías, padecimientos crónico degenerativos, padecimientos contagiosos, padecimientos neurológicos, padecimientos osteo-musculares, los derivados de investigaciones de entendimiento de la etiología de la obesidad en México y posibles factores de reversión, y en caso de aplicar, las limitantes fisiológicas que pudieran condicionar las actividades de acondicionamiento físico.
                              Cabe mencionar que todos los datos personales generales antes reseñados son indispensables para que BODY BARRE  pueda brindarle a sus clientes los servicios contratados, por lo que en caso de revocación del consentimiento o el ejercicio del derecho de cancelación para el tratamiento de dichos datos personales sensibles dará lugar a la rescisión del contrato que tenga celebrado con BODY BARRE  sin responsabilidad para este último, lo anterior derivado de la importancia que BODY BARRE da a prestar un servicio adecuado y personalizado para cada uno de los usuarios, siendo fundamental velar por la salud de las personas que realizan actividades de acondicionamiento físico en nuestras instalaciones.
                          </p>
                          <p className="text-justify">
                              Imagen. Podemos capturar su imagen visual, semejanza y grabación de voz (por ejemplo, a través de fotografías y/o videos) si visita nuestros estudios que video-graben las clases usted acepta que participará en las clases en vivo BODY BARRE LIVE.
                          </p>
                          <p className="text-justify">
                              2.- Finalidades del tratamiento: Sus datos personales son utilizados (tratados) para las siguientes finalidades:
                          </p>
                          <p className="text-justify">
                                  (i) suscripción del contrato,
                                  (ii) identificación y verificación;
                                  (iii) contacto;
                                  (iv) identificación al momento de acceso a las instalaciones; y
                                  (v) conocimiento de su estudio general de salud. Para el caso de los datos personales sensibles, estos serán utilizados únicamente para las siguientes finalidades:
                                  (i) para hacer recomendaciones del acondicionamiento físico apropiado para usted;
                                  (ii) para el correcto manejo de alguna situación de emergencia que requiera de la información con que cuenta BODY BARRE sobre su estado de salud. Asimismo, sus datos personales serán utilizados las siguientes finalidades comerciales y de promoción:
                                  (i) para enviarle información relativa a nuestros productos o servicios,
                                  (ii) realizar encuestas sobre la calidad de nuestros servicios;
                                  (iii) para enviarle ofertas y promociones de BODY BARRE como de las empresas filiales; y/o
                                  (iv) promoción de sorteos, eventos y trivias organizadas por BODY BARRE  o sus empresas subsidiarias y afiliadas. En caso de que usted no desee que sus datos personales sean tratados para las finalidades comerciales y de promoción antes descrita, usted podrá contactarnos en cualquier momento, para limitar dicho tratamiento, a través de nuestro correo electrónico BodyBarremx@gmail.com o directamente en nuestras oficinas ubicadas en Seneca 55 Colonia Polanco, CP, 11560 en la Ciudad de México, México
                          </p>
                          <p className="text-justify">
                              La negativa para el uso de sus datos personales para estas finalidades no podrá ser un motivo para que le neguemos los servicios que ofrece BODY BARRE, y de conformidad con la Ley, contara con un plazo de cinco días hábiles para manifestarla.
                          </p>
                          <p className="text-justify">
                              Existirá la posibilidad de acudir a clases de BODY BARRE  donde se le avise que dicha clase será video-grabada para reproducirse en BODY BARRE LIVE o BODY BARRE App con clases en vivo, en el entendido de que podremos captar su imagen propia y/o voz durante la sesión impartida.
                          </p>
                          <p className="text-justify">
                              En caso de comprar clases que sean video grabadas usted acepta, autoriza y renuncia a los derechos que pudiera tener sobre dichas grabaciones. En caso de que usted no desee ser video-grabado le sugerimos que no compre clases en el estudio que tenga la modalidad de video-grabación para BODY BARRE LIVE o BODY BARRE App clases en vivo.
                          </p>
                          <p className="text-justify">
                              Su imagen podrá ser captada como parte de un cuadro general, nunca será grabado de forma protagónica.

                              Usted tendrá conocimiento de las clases que se video-graben para BODY BARRE LIVE, clases en vivo y BODY BARRE App.
                          </p>
                          <p className="text-justify">
                              3.- Mecanismos de Seguridad para garantizar el correcto resguardo de sus datos personales y para dar pleno cumplimiento a las obligaciones que la LFPDPPP (Ley Federal de Protección de Datos Personales en Posesión de Particulares) establece en la materia, se le informa que BODY BARRE MEXICO SA DE CV, tiene implementadas las medidas de seguridad administrativas, técnicas y físicas necesarias y suficientes para la correcta protección de los datos.
                          </p>
                          <p className="text-justify">
                              4.- Revocación del Consentimiento Usted podrá revocar en cualquier momento su consentimiento para el tratamiento que BODY BARRE hace de sus datos personales por medio de un documento que deberá presentar por escrito directamente en nuestras oficinas ubicadas en Seneca 55 Colonia Polanco, CP, 11560 en la Ciudad de México, en un horario de lunes a viernes de 8:00 a 15:00 horas y de 15:00 a 17:00 horas, o en cualquier momento o a través de nuestro correo electrónico bodybarremx@gmail.com, misma que deberá contener por lo menos : (a) nombre u otro medio para comunicarle la respuesta a su solicitud; (b) los documentos que acrediten su identidad; (c) la descripción clara y precisa de los datos personales respecto de los que revoca su consentimiento para el tratamiento, (d) la manifestación expresa para revocar su consentimiento al tratamiento de sus datos personales y por tanto, para que no se suspenda su uso; y (e) cualquier otro elemento que facilite la localización de los datos personales.
                          </p>
                          <p className="text-justify">
                              5.- Ejercicio de derechos ARCO Usted puede ejercer ante BODY BARRE, en cualquier momento, sus derechos de acceso, rectificación, cancelación y oposición al tratamiento de sus datos personales mediante solicitud por escrito que deberá ser enviada directamente a nuestras oficinas ubicadas en Seneca 55 Colonia Polanco, CP, 11560 en la Ciudad de México, en un horario de lunes a viernes de 8:00 a 15:00 horas y de 15:00 a 17:00 horas, o en cualquier momento o a través de nuestro correo electrónico bodybarremx@gmail.com, misma que deberá contener por lo menos: (a) nombre y domicilio u otro medio para comunicarle la respuesta a su solicitud; (b) los documentos que acrediten su identidad o, en su caso, la representación legal; (c) la descripción clara y precisa de los datos personales respecto de los que se solicita ejercer alguno de los derechos ARCO, (d) la manifestación expresa del derecho (acceso, rectificación, cancelación u oposición) que quiere ejercer sobre tratamiento de sus datos personales; y (e) cualquier otro elemento que facilite la localización de los datos personales.
                          </p>
                          <p className="text-justify">
                              6.- Transferencias BODY BARRE MÉXICO SA DE CV,  podrá transferir sus datos personales con sus empresas subsidiarias o filiales del mismo grupo empresarial para las finalidades comerciales y de promoción descritas en el punto 2 del presente Aviso de Privacidad. En el caso los datos sensibles descritos en el punto 1 del presente Aviso de Privacidad, BODY BARRE no realizará ningún tipo de transferencia con sus empresas subsidiarias o filiales ni con cualquier otro tercero. En ningún caso BODY BARRE transferirá los datos personales de sus clientes a un tercero, diferente a las empresas subsidiarias o filiales descritas en el punto anterior, sin el consentimiento previo de los titulares.
                          </p>
                          <p className="text-justify">
                              7.- Cambios al Aviso de Privacidad BODY BARRE  se reserva el derecho de cambiar el contenido del presente Aviso de Privacidad en cualquier momento. En caso de que exista algún cambio en este Aviso de Privacidad, se le comunicará a través de nuestra BODY BARRE App y/o por correo electrónico.
                          </p>
                          <p className="text-justify">
                              Tenga en cuenta que cualquier información que otorgue a BODY BARRE en chat rooms, foros, recuadros de mensajes de opinión, comentarios y noticias, así como los datos personales que Usted revele en estas áreas públicas se volverán información pública y Usted debe tener cuidado cuando decida revelar cualquier información en dichas situaciones. Asimismo, tendrá la opción de compartir su ubicación a través de BODY BARRE App para mantenerse en el radar de su grupo de amigos, o en su caso de manera pública, por lo que al momento de compartir su ubicación en BODY BARRE App usted otorga su consentimiento para dicha finalidad y deslinda de toda responsabilidad a BODY BARRE.
                          </p>
                          <p className="text-justify">
                              ¿Cómo mantenemos sus datos seguros?
                              BODY BARRE  tiene medidas de seguridad en el lugar para proteger contra la pérdida, mal uso y alteración de la información bajo el control de BODY BARRE. Solamente empleados autorizados, agentes y contratistas (que han acordado mantener la información segura y confidencial) tienen acceso a esta información.
                          </p>
                          <p className="text-justify">
                              Todos los boletines de noticias que envía BODY BARRE  le otorgan la opción de no recibir más correos electrónicos, a excepción de los mensajes de correo electrónico de servicios, que son una parte integral de nuestro servicio, tales como las confirmaciones de órdenes de contratación del servicio, correos electrónicos de facturas y avisos a los clientes, tales como las relativas a la gestión de datos y propósitos similares. Utilizamos las medidas de seguridad física, electrónica y de gestión para tratar de evitar que a los datos personales no tengan acceso personas no autorizadas, y para asegurarnos de que los datos personales que proporciona, se gestionen de forma segura. Esto significa que a veces podemos solicitar una prueba de identidad antes de revelar datos personales a Usted. Sólo las estadísticas globales sobre el número de usuarios y sus patrones de tráfico se darán a los anunciantes.
                          </p>
                          <p className="text-justify">
                              A pesar de estos esfuerzos, BODY BARRE no puede garantizar que un tercero (hacker) no obtenga acceso a las comunicaciones entre los clientes, de BODY BARRE o entre los usuarios y BODY BARRE, en dado caso, y conforme al artículo 20 de la Ley Federal de Protección de Datos Personales en Posesión de Particulares, BODY BARRE le avisará de forma inmediata, en la medida de lo posible, sobre esta violación a la seguridad.
                          </p>
                          <p className="text-justify">
                              Si usted considera que su derecho a la protección de sus datos personales ha sido lesionado por alguna conducta u omisión de nuestra parte, o presume alguna violación a las disposiciones previstas en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, su Reglamento y demás ordenamientos aplicables, podrá interponer su inconformidad o denuncia ante el Instituto Federal de Acceso a la Información y Protección de Datos (IFAI). Para mayor información, le sugerimos visitar su página oficial de Internet www.ifai.org.mx.u
                          </p>
                      </Col>
                  </Row>

              </div>
          </div>
        );
    };

}

export default Subscriptions;
