import React, { Component } from 'react';
import {Col, Form, Row} from "react-bootstrap";
import axios from 'axios';

class ReporteUsuarios extends Component {
    constructor(props) {
        super(props);
        this.months = ['','Enero','Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre']
        this.state = {
            usuarios : [],
            acumulado : [],
            year : new Date().getFullYear(),
            rawBase : [],
            minRaw : 1,
            maxRaw : 12,
            offset : 0,
            loading : true
        };
    }

    componentDidMount() {
        document.querySelector('.main').addEventListener("scroll", this.trackScrolling);
        this.search()
    }

    componentWillUnmount() {
        document.querySelector('.main').removeEventListener("scroll", this.trackScrolling);
    }

    trackScrolling = (e) => {
        const container = document.querySelector('.main');
        const wrappedElement = document.querySelector('.table-users');
        if (wrappedElement && this.isBottom(container, wrappedElement) && !this.state.loading) {
            this.search();
        }
    }

    isBottom(container, el) {
        return Math.floor(el.getBoundingClientRect().bottom) <= Math.floor(container.getBoundingClientRect().bottom);
    }

    handleInputChange(name, value) {
        this.setState({
            [name]: value
        });
    }

    formatMoney = (n, c, d, t) => {
        c = isNaN(c = Math.abs(c)) ? 2 : c;
        d = d === undefined ? "." : d;
        t = t === undefined ? "," : t;
        if(isNaN(n)){
            return n
        }
        let s = n < 0 ? "-" : "";
        let i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c)));
        let k = (i.length);
        let j = k > 3 ? k % 3 : 0;
        return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
    }

    getRawBase(arr){
        let raw = [];
        let min = 1;
        let max = 12
        arr.map((el, index) => {
            raw.push(0)
            if(index === 0){
                min = el.month;
            }
            if(index === arr.length - 1){
                max = el.month;
            }
        })
        this.setState({
            rawBase : raw,
            minRaw : min,
            maxRaw : max
        })
    }

    handleChange = (e) => {
        this.setState({
            year: e.currentTarget.value
        });
    }

    search = async () => {
        this.setState({
            loading : true
        })
        let year = this.state.year;
        let offset = this.state.offset;
        await axios.post('/api/reportes/usuarios', {
            year : year,
            offset : offset
        }).then( res => {
            if(res.data.Success){
                let usuarios = [...this.state.usuarios];
                let newUsuarios = res.data.search[1];//usuarios.concat()
                this.getRawBase(res.data.search[0])
                this.setState({
                    acumulado : res.data.search[0],
                    usuarios : newUsuarios,
                    offset : offset + 50,
                    loading : false
                })
            }
            else{
                this.setState({
                    usuarios : [],
                    acumulado : [],
                    loading : false
                })
            }
        }).catch(err => {
            this.setState({
                usuarios : [],
                acumulado : [],
                loading : false
            })
        })
    }

    drawYears(){
        let options = [];
        let currYear = new Date().getFullYear();
        for(let k = currYear; k >= 2020; k--){
            options.push(
                <option key={k} value={k}>{k}</option>
            )
        }
        return options;
    }

    drawTitles(arr){
        return arr.map((el, idex) => {
            return (
                <th key={idex}>{this.months[el.month]}</th>
            )
        })
    }

    drawData(arr, name, money = false){
        return arr.map( (el, index) => {
            return (
                <td key={index}>{money ? "$ " + this.formatMoney(el[name]) : el[name]}</td>
            )
        })
    }

    drawAmmounts(arr) {
        return arr.map( (el, index) => {
            return (
                <td key={index}>$ {this.formatMoney(el)}</td>
            )
        })
    }

    drawUsers(){
        let users = [...this.state.usuarios];
        let id = null;
        let row = null;
        let name = null
        return users.map( (el, index) => {
            let returnEl = null
            if(el.id !== id){
                if(id){
                    returnEl = (
                        <tr key={index}>
                            <td>{name}</td>
                            {this.drawAmmounts(row)}
                        </tr>
                    )
                }
                row = [...this.state.rawBase];
                id = el.id;
                name = el.name
            }
            row[(el.month - this.state.minRaw)] = el.total
            return returnEl
        })
    }

    render() {
        let ingresos = this.state.ingresos;
        return (
            <div className="main">
                <h4 className="client">Usuarios</h4>
                <Row className="reporte">
                    <Col xs={12} md={4} lg={2}>
                        <Form.Group controlId="year">
                            <select name="year" id="year" className="form-control" onChange={ this.handleChange }>
                                {this.drawYears()}
                            </select>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={4} lg={2}>
                        <button className="btn btn-success btn-block" onClick={(e) => this.search()}>
                            Buscar
                        </button>
                    </Col>
                </Row>
                <Row className="reporte">
                    <Col xs={12} className="reporte-element table-content">
                        <div>
                            <table className="table table-sm">
                                <thead>
                                    <tr>
                                        <th></th>
                                        {this.drawTitles(this.state.acumulado)}
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            Usuarios por periodo
                                        </td>
                                        {this.drawData([...this.state.acumulado],"users")}
                                    </tr>
                                    <tr>
                                        <td>
                                            Total Acumulado
                                        </td>
                                        {this.drawData([...this.state.acumulado],"total",true)}
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </Col>
                    <Col xs={12} className="reporte-element table-content">
                        <div>
                            <table className="table table-sm table-users">
                                <thead>
                                    <tr>
                                        <th>
                                            Usuario
                                        </th>
                                        {this.drawTitles(this.state.acumulado)}
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.drawUsers()}
                                </tbody>
                            </table>
                        </div>
                    </Col>
                </Row>
                <hr/>
            </div>
        )
    };

}

export default ReporteUsuarios;
