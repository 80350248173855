import React, {Component} from 'react';
import './style.css'
import moment from "moment";

class Header extends Component {

    constructor(props) {
        super(props);
    }

    drawClassList(){
        let clases = [...this.props.clases];
        return clases.map( (el) => {
            if((el.id_category === 2 && !this.props.membership) || el.id_category !== 2) {
                return (
                    <p>
                        Clases
                        <span
                            style={{
                                color: el['category.color']
                            }}
                        >
                            {" " + el['category.name']}
                        </span>
                        : <span>{el.available}</span> hasta <span>{moment(el.due_date).local().format("DD/MMM/YYYY")}</span>
                    </p>
                )
            }
        })
    }

    render() {

        return (
            <div className="user-header">
                <div className="clases">
                    {this.drawClassList()}
                    {this.props.membership ?
                        <p>
                            Clases <span>Live</span>:
                            <span>{" Ilimitadas "}</span>
                            hasta
                            <span>{" " + moment(this.props.membership.due_time).local().format("DD/MMM/YYYY")}</span>
                        </p>
                        : null
                    }
                </div>
            </div>
        );

    }
}

export default Header;
