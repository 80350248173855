import React, { Component } from "react";
import "./style.css";
import axios from "axios";
import Swal from "sweetalert2";
import { PayPalButton } from "react-paypal-button-v2";

class PrePayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      methods: ["paypal"],
      accepted: false,
      error: false,
      orderID: null,
    };
    this.handleChackbox = this.handleChackbox.bind(this);
  }

  formatMoney = (n, c, d, t) => {
    c = isNaN((c = Math.abs(c))) ? 2 : c;
    d = d === undefined ? "." : d;
    t = t === undefined ? "," : t;
    if (isNaN(n)) {
      return n;
    }
    let s = n < 0 ? "-" : "";
    let i = String(parseInt((n = Math.abs(Number(n) || 0).toFixed(c))));
    let k = i.length;
    let j = k > 3 ? k % 3 : 0;
    return (
      s +
      (j ? i.substr(0, j) + t : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) +
      (c
        ? d +
          Math.abs(n - i)
            .toFixed(c)
            .slice(2)
        : "")
    );
  };

  componentDidMount() {
    this.getLocate();
  }

  handleChackbox(e) {
    let checked = e.target.checked;
    this.setState({
      accepted: checked,
    });
  }

  getLocate = async () => {
    axios
      .get("https://us-central1-bodybarreapp.cloudfunctions.net/locate")
      .then((res) => {
        if (res.data.error) {
          return false;
        } else if (res.data.country === "MX") {
          let methods = [...this.state.methods];
          methods.push("card");
          this.setState({ methods: methods });
        }
      })
      .catch(() => {});
  };

  handleCardPurchase() {
    Swal.fire({
      title: "Confirmación de compra",
      html: `<span>Estás a punto de adquirir <b>${this.props.description}</b>.<span></br><span>Para confirmar, por favor presiona Ok.</span></br><span>Para cambiarlo presiona Cancelar<span>`,
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: `OK`,
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        this.props.choosePaymentMethod("card");
      }
    });
  }
  handlePayPalPurchase() {
    Swal.fire({
      title: "Confirmación de compra",
      html: `<span>Estás a punto de adquirir <b>${this.props.description}</b>.<span></br><span>Para confirmar, por favor presiona Ok.</span></br><span>Para cambiarlo presiona Cancelar<span>`,
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: `OK`,
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        this.props.choosePaymentMethod("paypal");
      }
    });
  }

  addPayment = async (orderID) => {
    let dataPago = {
      username: this.props.username,
      pkg: this.props.selectedPackage
        ? this.props.selectedPackage
        : this.props.selectedMembership,
      subtotal: this.props.ammount,
      discount: this.props.discount,
      total: this.props.ammount - this.props.discount,
      code: this.props.discountID,
      currency: this.props.currency,
      description: this.props.description,
      paymentId: null,
      PayerID: null,
      paymentData: {
        paymentId: null,
      },
      order: orderID,
      paymentMethod: 1,
      isMembership: this.props.selectedPackage ? false : true,
      status: 0,
    };
    return axios
      .post("/api/payment/addPayment", dataPago)
      .then((response) => {
        this.setState({ orderID: orderID });
        return true;
      })
      .catch(() => {
        return false;
      });
  };

  validatePayment = async (orderID) => {
    return axios
      .post("/api/payment/validatePayment", { order: orderID })
      .then((response) => {
        if (response.status === 200) {
          window.location = "https://bodybarre.com.mx/home?b=true";
          //window.location = "http://localhost:3000/home?b=true";
        }
      })
      .catch(() => {
        return false;
      });
  };

  render() {
    return (
      <div className="packages">
        {!this.props.discountSuccess && this.props.promotionable ? (
          <div>
            <input
              className="form-control input-code"
              name="name"
              onChange={(e) => this.props.changeDiscountCode(e.target.value)}
              value={this.props.discountCode}
              placeholder="CÓDIGO DE DESCUENTO"
            />
            <button
              className="btn code-btn btn-block"
              onClick={() => this.props.getDiscountCode()}
            >
              APLICAR CÓDIGO
            </button>
            {this.props.discountError ? (
              <p className="error">{this.props.discountError}</p>
            ) : null}
          </div>
        ) : (
          <div>
            <p className="success">{this.props.discountSuccess}</p>
          </div>
        )}
        <div className="resume">
          <p className="resumeElement">
            <span className="resumeDescription">{this.props.description}</span>
            <span className="resumeAmmount">
              ${this.formatMoney(this.props.ammount)} {this.props.currency}
            </span>
          </p>
          {this.props.discount ? (
            <p className="resumeElement">
              <span className="resumeDescription">DESCUENTO</span>
              <span className="resumeAmmount">
                -${this.formatMoney(this.props.discount)} {this.props.currency}
              </span>
            </p>
          ) : null}
          <p className="resumeElement resumeElementTotal">
            <span className="resumeDescription resumeTotal">TOTAL</span>
            <span className="resumeAmmount resumeTotal">
              $
              {this.formatMoney(
                Math.round((this.props.ammount - this.props.discount) * 100) /
                  100
              )}{" "}
              {this.props.currency}
            </span>
          </p>
        </div>
        <label className="checkbox checkbox-terms">
          <input
            type="checkbox"
            checked={this.state.accepted}
            onChange={this.handleChackbox}
          />
          Acepto los{" "}
          <a href="/terms" target="_blank">
            T&eacute;rminos y Condiciones
          </a>
        </label>
        <p className="payment-method">Selecciona un método de pago</p>
        {this.state.accepted ? (
          <div className="payment-options">
            <PayPalButton
              currency={this.props.currency}
              amount={this.props.ammount - this.props.discount}
              createOrder={async (data, actions) => {
                const order = actions.order.create({
                  purchase_units: [
                    {
                      amount: {
                        currency_code: this.props.currency,
                        value: this.props.ammount - this.props.discount,
                        breakdown: {
                          item_total: {
                            currency_code: this.props.currency,
                            value: this.props.ammount - this.props.discount,
                          },
                        },
                      },
                      items: [
                        {
                          name: this.props.description,
                          unit_amount: {
                            currency_code: this.props.currency,
                            value: this.props.ammount - this.props.discount,
                          },
                          quantity: 1,
                        },
                      ],
                    },
                  ],
                });
                return order;
              }}
              onApprove={async (data, actions) => {
                await this.addPayment(data.orderID);
                return actions.order.capture().then(async (details) => {
                  await this.validatePayment(data.orderID);
                  return true;
                });
              }}
              options={{
                currency: "MXN",
                clientId: process.env.REACT_APP_PAYPAL_CLIENT,
              }}
            />
          </div>
        ) : null}
        {this.state.error ? (
          <p className="error">
            Debes aceptar los T&eacute;rminos y Condiciones
          </p>
        ) : null}
      </div>
    );
  }
}

export default PrePayment;
