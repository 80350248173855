import React, { Component, Fragment } from 'react';
import Navigation from '../../../components/Navigation/Navigation';
import Sidebar from '../../../components/Sidebar';
import Icon from 'react-ionicons';
import axios from 'axios';
import Preparar from '../../../components/Classes/Preparar';
import './style.css';
import {Col, Form,Row} from "react-bootstrap";
import Checkbox from '@material-ui/core/Checkbox';
import MUIDataTable from "mui-datatables";
import moment from 'moment';
import Swal from 'sweetalert2';

class ClassDetail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            clase: null,
            isOnline: false,
            preparar: false,
            starts: null,
            estatus: 'Programada',
            transmition: null,
            transmiting: false,
            instructors: [],
            instructor: null
        };
        this.triggerPreparar = this.triggerPreparar.bind(this);
        this.start = this.start.bind(this);
        this.end = this.end.bind(this);
    }

    componentDidMount() {
        this.getAllInstructor();
        this.getClassInfo();
    }

    triggerPreparar = async () => {
        this.setState({
            preparar: !this.state.preparar
        });
    };

    start = async () => {
        if (this.state.clase['transmition.status'] === 2) {
            return false;
        }
        await axios.get('/api/clase/live', {
            params: {
                transmition: this.state.transmition
            }
        }).then((res) => {
            if (res.data.Success) {
                this.getClassInfo();
            }
        });
    };

    end = async () => {
        await axios.get('/api/clase/live', {
            params: {
                transmition: this.state.transmition,
                stop: true
            }
        }).then((res) => {
            if (res.data.Success) {
                this.getClassInfo();
            }
        });
    };

    countdown = async (start) => {
        var x = setInterval(() => {
            var now = moment().local();

            // Find the distance between now and the count down date
            var distance = start.diff(now, 'seconds');

            // Time calculations for days, hours, minutes and seconds
            var days = Math.floor(distance / (60 * 60 * 24));
            var hours = Math.floor((distance % (60 * 60 * 24)) / (60 * 60));
            var minutes = Math.floor((distance % (60 * 60)) / (60));
            var seconds = Math.floor((distance % (60)));

            // Display the result in the element with id="demo"
            let countdown = days + 'd ' + hours + 'h ' + minutes + 'm ' + seconds + 's ';

            if (distance < 0) {
                this.setState({
                    estatus: 'Retrasada'
                });
                clearInterval(x);
            } else {
                this.setState({
                    estatus: countdown
                });
            }
            // If the count down is finished, write some text
        }, 1000);
    };

    setReservationStatus = async (reservation, status, res) => {
        let clase = this.state.clase
        let usuariosClase = [...clase.reservations];
        let reservationObj = usuariosClase[reservation];
        return await axios.post('/api/clase/setReservationStatus',{
            status : status,
            user : reservationObj.id_user,
            clase : clase.id,
            reservacion: res
        }).then((res) => {
            if(res.data.Success && res.data.updated) {
                return true
            }
            return false
        }).catch( () => {
            return false
        })
    }

    updateInstructorClase = async(clase, instructor) =>{
        return await axios.post('/api/clase/updateClaseInstructor',{
            classe:clase, instructor:instructor
        }).then((res) => {
            if(res.data.Success) {
                return true
            }
            return false
        }).catch( () => {
            return false
        })
    }

    getAllInstructor = async () => {
        await axios.get('/api/instructor/getAllInstructors')
        .then(
            (res)=>{
                if (res.data.Success) {
                    this.setState({ instructors: res.data.instructors });
                }else{
                    this.setState({ instructors: null });
                }
            }
        )
        .catch()
    }

    drawInstructor = () => {
        let options = [];
        let maestras = this.state.instructors;

        for (let index = 0; index < maestras.length; index++) {
            const element = maestras[index];
            options.push(<option value={element.id}>{element.fullname}</option>)

        }
        return options;

    }

    getClassInfo = async () => {
        await axios.get('/api/clase/getById', {
            params: {
                clase: this.props.clase
            }
        }).then((res) => {
            if (res.data.Success) {
                let scheduled = moment(res.data.clase.start).local();
                let started = (res.data.clase['transmition.start']) ? moment(res.data.clase['transmition.start']).local() : null;
                let ended = (res.data.clase['transmition.end']) ? moment(res.data.clase['transmition.end']).local() : null;
                let estatus = res.data.clase['transmition.transmition_status.description'];
                this.setState({
                    clase: res.data.clase,
                    instructor: res.data.clase.id_instructor,
                    isOnline: res.data.clase.online,
                    starts: scheduled,
                    started: started,
                    ended: ended,
                    estatus: estatus,
                    transmiting: (started && ended) ? false : (started) ? true : false,
                    transmition: res.data.clase['transmition.id']
                });
                if (res.data.clase['transmition.status'] === 1) {
                    this.countdown(scheduled);
                }
            } else {
                window.location = '/classes';
            }
        });
    };

    async handleCheck(e, index, reser){
        let clase = this.state.clase
        let usuariosClase = [...clase.reservations];
        let status = 1
        let res = reser;

        if(e.target.checked){
            status = 3
        }
        let updated = await this.setReservationStatus(index, status, res)
        if(updated){
            usuariosClase[index].status = status;
            clase.reservations = usuariosClase
            this.setState({
                clase : clase
            })
        }
    }

    handleSelect = async (e) => {
        let inst = parseInt(e.target.value);
        this.setState({
            instructor: inst
        });
    };

    async updateInstructor(){
        let clase = this.state.clase.id;
        let classe = this.state.clase;

        let instructor = this.state.instructor;
        if(this.state.clase.id_instructor === instructor){
            Swal.fire({
                title: '',
                html: `<span>Has seleccionado a la misma maestra</b>.<span></br><span>Por favor selecciona otra maestra para actualizar esta clase.<span>`,

                icon: 'question'
              })
        }else{
            let updated = await this.updateInstructorClase(clase, instructor);
            if(updated){
                classe.id_instructor = instructor;
                this.setState({
                    instructor:instructor,
                    clase : classe
                });
            }
        }
    }

    renderObject(){
        if( this.state.clase === null ){
            return null;
        }

        let usuariosClase = this.state.clase.reservations;
        let data = [];

        let fecha = moment();
        let fechaClase = moment(this.state.clase.start);
        let duracion = moment.duration(fecha.subtract(fechaClase));

        for (let index = 0; index < usuariosClase.length; index++) {
            data.push([
                (index + 1),
                usuariosClase[index].user.profile.name+' '+usuariosClase[index].user.profile.last_name,
                usuariosClase[index].user.email,
                usuariosClase[index].code,
                usuariosClase[index].place,
                '',
                usuariosClase[index].status === 3,
                usuariosClase[index].id
            ]);
        }

        const columns = ['', 'Nombre de la alumna', 'Email', 'Código de acceso', 'Numero de Mat',{
            name:"",
            options:{
                download:false,
                customBodyRender : (value, tableMeta, updateValue) => {
                    let index = tableMeta.rowData[0] - 1;
                    let checked = tableMeta.rowData[6];
                    let reser = tableMeta.rowData[7];
                    return (
                        <div>
                            <Checkbox
                                style={{color:'gray'}}
                                onChange={(e)=>{this.handleCheck(e, index, reser)}}
                                checked={checked}
                                disabled={ ( (duracion.days() > 1) || (duracion.hours() > 1) || (duracion.minutes() >= 30) ) ? true : false }
                            >
                            Checkbox
                            </Checkbox>
                        </div>
                    )
                }
            }
        },{
            name:"a",
            options:{
                display: 'excluded',
            }
        },{
            name:"a",
            options:{
                display: 'excluded',
                
            }
        },true];

        const options = {
            filter: true,
            filterType: "dropdown",
            selectableRows: 'none',
            rowHover: true,
            print: false,
            download: false,
            responsive: 'simple',
            tableBodyHeight:"",
            tableBodyMaxHeight:"",
            setRowProps: (row, dataIndex, rowIndex) => {
                return {
                    className: row[6] ? "green-row" : "",
                    style: row[6] ? {backgroundColor: '#01bc96', color : 'white'} : {},
                }
            },
            textLabels: {
                body: {
                  noMatch: "No se encontraron registros",
                  toolTip: "Ordenar",
                  columnHeaderTooltip: column => `Ordenar por ${column.label}`
                },
                pagination: {
                  next: "Siguiente página",
                  previous: "Página anterior",
                  rowsPerPage: "Registros por página:",
                  displayRows: "de",
                },
                toolbar: {
                  search: "Buscar",
                  viewColumns: "Ver columnas",
                  filterTable: "Filtrar tabla",
                },
                filter: {
                  all: "TODOS",
                  title: "FILTROS",
                  reset: "RESET",
                },
                viewColumns: {
                  title: "Mostrar columnas",
                  titleAria: "Mostrar/Ocultar columnas",
                },
                selectedRows: {
                  text: "Registro(s) seleccionado(s)",
                  delete: "Delete",
                  deleteAria: "Delete Selected Rows",
                }
            }
        }
        return (
            <Fragment>
                <Row>
                    <Col xs={12} md={4} lg={2}>
                        <Form.Group controlId='instructor'>
                            <span>Maestra: </span>
                            <select name="instructor" id="instructor" className="form-control" onChange={this.handleSelect} defaultValue={this.state.clase.id_instructor}  disabled={ ( (duracion.days() > 1) || (duracion.hours() > 1) || (duracion.minutes() >= 30) ) ? true : false }>
                               {this.drawInstructor()}
                            </select>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={4} lg={2}>
                        <br></br>
                        <button className="btn btn-success btn-block" onClick={(e)=>{ this.updateInstructor() }} disabled={ ( (duracion.days() > 1) || (duracion.hours() > 1) || (duracion.minutes() >= 30) ) ? true : false }>
                            Guardar
                        </button>
                    </Col>
                </Row>
                <MUIDataTable
                    title={""}
                    data={data}
                    columns={columns}
                    options={options}
                />
            </Fragment>

        );
    }

    renderDash() {
        return (
          <div className="content">
              <Navigation user={this.props.user}/>
              <Sidebar
                  active="classes"
                  type={this.props.type}
              />
              <div className="main">

                  <h2>Detalle de la clase</h2>
                  <div className="content-dashboard">
                      {(this.state.isOnline) ?
                        (this.state.preparar) ?
                          <Preparar triggerPreparar={this.triggerPreparar}
                                    getClassInfo={this.getClassInfo}
                                    clase={this.props.clase}
                          />
                          :
                          <div>
                              <p
                                className="countdown"
                              >
                                  {this.state.estatus}
                              </p>
                              <div
                                className="button-action"
                              >
                                  <button
                                    onClick={this.triggerPreparar}
                                  >
                                      <Icon
                                        icon="md-link"
                                        fontSize="35px"
                                        color="#FFF"
                                      />
                                  </button>
                                  <p>Preparar Clase</p>
                                  {(this.state.clase['transmition.url']) ?
                                    <span>
                                            <Icon
                                              icon="md-checkmark"
                                              fontSize="15px"
                                              color="#FFF"
                                            />
                                        </span>
                                    :
                                    null
                                  }
                              </div>
                              <div
                                className={'button-action'}
                              >
                                  <button
                                    className={(this.state.clase['transmition.url']) ? '' : 'disabled'}
                                    onClick={this.start}
                                  >
                                      <Icon
                                        icon="md-play"
                                        fontSize="35px"
                                        color="#FFF"
                                      />
                                  </button>
                                  <p>Iniciar Transmisión</p>
                                  {(this.state.transmiting) ?
                                    <span
                                      className="transmiting"
                                    >
                                        </span>
                                    :
                                    null
                                  }
                              </div>
                              <div
                                className="button-action"
                              >
                                  <button
                                    className={(this.state.transmiting) ? '' : 'disabled'}
                                    onClick={this.end}
                                  >
                                      <Icon
                                        icon="ios-square"
                                        fontSize="35px"
                                        color="#nFFF"
                                      />
                                  </button>
                                  <p>Terminar Transmisión</p>
                              </div>
                          </div>
                        :
                        <Fragment>

                            <Row className="reporte" style={ { height:"90%", width:'98%',display: 'table', tableLayout:'fixed' } }>
                                {this.renderObject()}
                            </Row>
                        </Fragment>

                      }
                  </div>
              </div>
          </div>
        );
    }

    render() {
        return this.renderDash();
    };

}

export default ClassDetail;
