import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';

class Rules extends Component {

    render() {
        return (
            <div className="content">
                <div className="main">
                    <a href="/home">
                        <img
                            alt=""
                            src="https://bbarrecnt.sfo3.cdn.digitaloceanspaces.com/app/logo_bb.png"
                            className="file-head"
                        />
                    </a>

                    <Row className="justify-content-md-center">
                        <Col xs={12} md={8} lg={8}>
                            <h4 className="text-center">
                                Reglamento Interno
                            </h4>
                            <p className="text-justify">
                                REGLAMENTO INTERNO DE BODY BARRE, S.A. DE C.V., PARA SUS USUARIOS
                            </p>
                            <p className="text-justify">
                                <ol style={{listStyleType : "upper-roman"}}>
                                    <li>
                                        BODY BARRE PRESTA LOS SIGUIENTES SERVICIOS A SUS USUARIOS:
                                        <ol>
                                            <li>
                                                INSTALACIONES EN DIFERENTES PARTES DE LA CIUDAD, ACTUALES Y EN UN FUTURO.
                                                UBICACIONES:
                                            </li>
                                            <li>
                                                DIFERENTES HORARIOS A ESCOGER SEGÚN SUS NECESIDADES
                                            </li>
                                            <li>
                                                CLASES DE BARRA FIJA DE ALTO RENDIMIENTO
                                            </li>
                                            <li>
                                                SUPERVISIÓN Y ASESORAMIENTO DE EJERCICIOS FÍSICOS
                                            </li>
                                        </ol>
                                    </li>
                                    <li>
                                        Darse de alta en nuestra “BODY BARRE App”será necesario seguir el procedimiento
                                        que se mencionará al ingresar a nuestro sitio, donde obtendrá una cuenta con la cual
                                        podrá tener acceso a toda la información necesaria para el mejor uso de nuestras
                                        instalaciones.
                                    </li>
                                    <li>
                                        El usuario acepta proporcionar la información que se le requiera teniendo en
                                        consideración lo dispuesto en la LEY FEDERAL DE PROTECCIÓN DE DATOS PERSONALES
                                    </li>
                                    <li>
                                        El usuario acepta proporcionar los datos relativos a su forma de pago vía electrónica,
                                        para realizar los pagos correspondientes a lo contratado.
                                    </li>
                                    <li>
                                        Queda a discreción de BODY BARRE aceptar la forma de pago del usuario.
                                    </li>
                                    <li>
                                        El usuario se hará cargo de su contraseña y será el único responsable del uso que se dé
                                        de la misma.
                                    </li>
                                </ol>
                            </p>
                            <p className="text-justify">
                                ¿CÓMO INSCRIBIRME A LAS CLASES?<br/><br/>
                                Al ingresar a “BODY BARRE App” con su cuenta de usuario y contraseña, BODY BARRE le
                                permitirá apartar un cupo dentro de las clases de “BODY BARRE App” que se encuentren
                                disponibles en el sistema, al momento en que usted indique qué clase desee tomar, usted estará
                                en la lista de asistentes a dicha clase. En el momento en que usted indique y solicite se le aparte
                                un cupo dentro de dicha clase usted estará confirmando su asistencia a la misma y por lo tanto,
                                usted autoriza a BODY BARRE para que realice el cargo automático a la tarjeta de crédito o medio
                                de pago electrónico que usted proporcionó a BODY BARRE al momento de crear su cuenta de
                                usuario, por concepto de la clase a la que usted asista.
                            </p>
                            <p className="text-justify">
                                NUESTRA “BODY BARRE App”, FORMA DE UTILIZAR PARA SU MÁXIMO APROVECHAMIENTO

                            </p>
                            <p className="text-justify">
                                Es importante leer con cuidado las instrucciones para tener un eficiente servicio de nuestro sitio.
                                Toda la información que usted plasme en nuestro sitio será validada en cualquier momento por
                                BODY BARRE, S.A DE C.V. y será responsabilidad del usuario la veracidad de la misma.
                            </p>

                            <p className="text-justify">
                                <ol>
                                    <li>
                                        El uso de las instalaciones será única y exclusivamente bajo la supervisión del instructor.
                                    </li>
                                    <li>
                                        Asistir con ropa y calzado adecuado
                                    </li>
                                    <li>
                                        No maltratar el equipo
                                    </li>
                                    <li>
                                        No fumar
                                    </li>
                                    <li>
                                        No alterar el orden
                                    </li>
                                    <li>
                                        Únicamente podrá ingresar al estudio la persona que cuente con la credencial que lo
                                        acredite como alumno activo.
                                    </li>
                                    <li>
                                        Respetar el área destinada para la venta de alimentos
                                    </li>
                                    <li>
                                        Al alumno que resulte responsable de descompostura por mal uso del equipo, mobiliario o
                                        instalaciones se le cobrara el costo de las reparaciones o reposiciones respectivas.
                                    </li>
                                    <li>
                                        Cualquier instructor o encargado del gimnasio tiene la autoridad para hacer vale este
                                        reglamento, así como pedirle a cualquier alumno que abandone el gimnasio por no acatar
                                        estas disposiciones.
                                    </li>
                                    <li>
                                        No está permitido darle acceso a terceras personas a “BODY BARRE App” utilizando las
                                        claves de los usuarios, BODY BARRE, S.A. DE C.V. podrá en su momento cancelar las claves
                                        de los usuarios que infrinjan ésta disposición.
                                    </li>
                                    <li>
                                        Es responsabilidad del usuario utilizar la página web para los fines exclusivos que BODY
                                        BARRE S.A. DE C.V. señale en el presente reglamento.
                                    </li>
                                    <li>
                                        Cualquier otro uso que se le dé será responsabilidad del usuario y se atendrá a las
                                        consecuencias del malestar o daño que haya causado, que puede ser desde una
                                        amonestación o si el hecho lo amerita ponerlo a disposición de las autoridades
                                        competentes, en el caso de infringir la ley.
                                    </li>
                                    <li>
                                        Toda la información que se menciona en nuestra “BODY BARRE App” es confidencial, por
                                        lo que no está permitido transferirla a terceras personas.
                                    </li>
                                    <li>
                                        El uso de nuestra “BODY BARRE App” no tiene ningún costo.
                                    </li>
                                    <li>
                                        Podrán asistir a nuestras clases presenciales las personas mayores de 16 años.
                                    </li>
                                    <li>
                                        El pago de las tarifas se realizarán por el propio interesado en la cuenta de entidad
                                        bancaria que BODY BARRE S.A. DE C.V. tiene considerado para tal fin.
                                    </li>
                                    <li>
                                        El pago de las cuotas establecidas se realizará antes de poder reservar dia, estudio, horario
                                        y Mat
                                    </li>
                                    <li>
                                        El usuario podrá disfrutar, de acuerdo a las normas de uso establecidas en la instalación.
                                    </li>
                                    <li>
                                        El usuario podrá presentar sugerencias o reclamaciones que estime convenientes en
                                        nuestro sitio web en el buzón correspondiente.
                                    </li>
                                    <li>
                                        Velar por el buen estado de conservación de las instalaciones y servicios, impidiendo o
                                        denunciado todo acto que vaya en deterioro de las mismas y advirtiendo cuando observen
                                        anomalías en la instalación o en el material de las mismas.
                                    </li>
                                    <li>
                                        Se prohíbe comer y fumar en todo el recinto, así como utilizar envases de vidrio.
                                    </li>
                                    <li>
                                        Hacer uso de la instalación con la ropa y calzado deportivo adecuados.
                                    </li>
                                    <li>
                                        Se prohíbe acceder a las instalaciones con animales de compañía.
                                    </li>
                                    <li>
                                        Guardar el debido respeto a los demás usuarios y al personal de la instalación, así como
                                        atender en todo momento las indicaciones del mismo, cuyo cometido es supervisar toda
                                        actividad que se realice en el recinto.
                                    </li>
                                    <li>
                                        El incumplimiento de las obligaciones que se derivan de la condición de usuario podrá
                                        llevar consigo la pérdida de tal condición. Dichos incumplimientos podrán ser clasificados
                                        como leves y graves, según se detalla en los apartados siguientes:
                                        <ol>
                                            <li>
                                                Incumplimientos leves;
                                                <ol style={{listStyleType : "lower-alpha"}}>
                                                    <li>
                                                        El incumplimiento de algunas de las obligaciones de los usuarios, cuando su
                                                        consecuencia no dé lugar a la calificación de grave.
                                                    </li>
                                                    <li>
                                                        El trato incorrecto a cualquier usuario o personal.
                                                    </li>
                                                    <li>
                                                        Causar daños leves de forma involuntaria a la instalación o equipamiento.
                                                    </li>
                                                </ol>
                                            </li>
                                            <li>
                                                Incumplimientos graves;
                                                <ol style={{listStyleType : "lower-alpha"}}>
                                                    <li>
                                                        El incumplimiento reiterado de algunas de las obligaciones de los usuarios.
                                                    </li>
                                                    <li>
                                                        El maltrato de palabra u obra a otros usuarios o empleados de la instalación.
                                                    </li>
                                                    <li>
                                                        Causar daños graves de forma voluntaria a la instalación o equipamiento de la misma.
                                                    </li>
                                                    <li>
                                                        Originar por imprudencia o negligencia accidentes graves a sí mismo u otras personas.
                                                    </li>
                                                    <li>
                                                        El falsear intencionadamente los datos relativos a la identidad, edad, estado de salud.
                                                    </li>
                                                    <li>
                                                        La reincidencia en incumplimientos resueltos como leves.
                                                    </li>
                                                    <li>
                                                        Hurtar, robar o deteriorar material de la instalación o de las pertenencias de otros
                                                        usuarios.
                                                    </li>
                                                    <li>
                                                        Introducir en la instalación animal o producto que deteriore la misma.
                                                    </li>

                                                </ol>
                                            </li>
                                            <li>
                                                Consecuencias de los incumplimientos:
                                                <ol style={{listStyleType : "lower-alpha"}}>
                                                    <li>
                                                        Los incumplimientos leves se corregirán con apercibimiento por escrito.
                                                    </li>
                                                    <li>
                                                        Los incumplimientos graves se corregirán con la pérdida de la condición de usuario.
                                                    </li>
                                                </ol>
                                            </li>
                                        </ol>
                                    </li>
                                </ol>
                            </p>

                            <p className="text-justify">
                                RESPONSABILIDAD<br/><br/>
                                El usuario está consciente de que el ejercicio físico conlleva riesgos y el ejercicio en barra fija no es
                                la excepción, que pueden ser desde lesiones leves, como torceduras, desgarres, etc. Así como
                                también lesiones graves, como pueden ser infartos, parálisis, entre otras varias; por lo que desde
                                este momento el usuario acepta los riesgos y exime de cualquier responsabilidad a BODY
                                BARRE,S.A. DE C.V. así como también a los socios, accionistas, empleados.
                            </p>
                            <p className="text-justify">
                                Si por alguna razón alguno de nuestros instructores considera, por su experiencia y preparación,
                                que en algún momento no deba de tomar clase alguno de nuestros usuarios por no considerarlo
                                apto en ese momento, sin que medie mayor diálogo el usuario tendrá que acatar dicha
                                disposición.
                            </p>
                            <p className="text-justify">
                                El usuario manifiesta encontrarse en óptimas condiciones físicas al día de hoy, por lo que no tiene
                                contraindicaciones para llevar a cabo esta rutina de ejercicio.
                            </p>
                            <p className="text-justify">
                                El usuario firmará de conformidad el presente reglamento, después de haberlo leído.
                            </p>
                        </Col>
                    </Row>

                </div>
            </div>
        );
    };

}

export default Rules;
