import moment from 'moment';
import React, { Component, Fragment } from 'react'
import Navigation from '../../components/Navigation/Navigation';
import Sidebar from '../../components/Sidebar';
import {Col, Form,Row, Card} from "react-bootstrap";
import axios from 'axios'
import Swal from 'sweetalert2'


class DiscountClasses extends Component{

    constructor(props){
        super(props);
        this.state = {
            clase: false,
            mail: null,
            alumna: null,
            detail: false
        }
        this.handleMailSearch = this.handleMailSearch.bind(this);
    }
    handleMailSearch(e){
        this.setState({
            [e.target.name] : e.target.value
        });
    }
    
    drawDetail() {
        let cuadros = [];
        let reserve = [];
        let reservaciones = {...this.state.detail.reservaciones};
        let classes = {...this.state.detail.clases};
        let botonesTransfer = {
            1: <button className="btn btn-success" onClick={() => { this.pasaClase(1) } }>{'Transferir'}</button>,
            2: <button className="btn btn-success" onClick={() => { this.pasaClase(2) } }>{'Transferir'}</button>,
            3: <button className="btn btn-success" onClick={() => { this.pasaClase(3) } }>{'Transferir'}</button>
        }
        
        for (var [key, value] of Object.entries(classes)) {            
            moment.locale('es-mx')
            cuadros.push(
                <div key={key} className="col-sm-3 col-lg-3">
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col sm="6">
                                    <div><h4 style={{color:value['category.color']}}>{value['category.name']}</h4></div>
                                    <div>Disponibles: <h4 style={{color:value['category.color']}}>{value['available']}</h4></div>
                                </Col>
                                <Col sm="6">
                                    <div>Vigencia: <h4 style={{color:value['category.color']}}>{ (value['available'] > 0) ? moment(value['due_date']).format('DD  MMM YYYY') : '-' }</h4></div>
                                    <div>Usadas: <h4 style={{color:value['category.color']}}>{value['used']}</h4></div>
                                    <div>{ (value['available'] > 0) ? botonesTransfer[value['category.id']] : '' }</div>                                                                       
                                </Col>
                            </Row>                            
                        </Card.Body>                        
                    </Card>
                </div>
            )
        }

        return(
            <div className="container-fluid">
                <Row>
                    <Col lg="12">
                        <div className="form-row" style={{width:'100%'}}>
                            <div className="form-group col-lg-3">
                                <label for="inptName"> Nombre Alumna:</label>
                                <input id="inptName" className="form-control" disabled value={this.state.alumna['profile.name'] + ' ' + this.state.alumna['profile.last_name']}></input>
                            </div>
                            <div className="form-group col-lg-3">
                                <label for="inptName"> Mail:</label>
                                <input id="inptmeil" className="form-control" disabled value={this.state.alumna['email']}></input>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    {cuadros}
                </Row>
                
            </div>
        );
    }

    renderDash(){
        let fecha = moment().locale('es');
        return(
            <div className="content">
                <Navigation user={this.props.user}/>
                <Sidebar active={this.props.select} type={this.props.type}/>
                <div className="main">
                    <h2>Descuento de clases a alumnas</h2>
                    <div className="container-fluid" style={{width:"100%"}}>
                            <div className="row" style={{width:"100%"}}>
                                <Form.Row className="align-items-center" style={{width:"100%"}}>
                                    <label>Correo de la alumna:</label>
                                    <div className="col-lg-3">
                                        <label className="sr-only" for="usernameinput">Correo</label>
                                        <div className="input-group mb-2">
                                            <div className="input-group-prepend">
                                                <div className="input-group-text">@</div>
                                            </div>
                                            <input type="email" className="form-control" id="usernameinput" name="mail" onChange={this.handleMailSearch} placeholder="Correo" required/>
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        <button type="submit" className="btn btn-primary mb-2" onClick={this.getUserData}>Buscar</button>
                                    </div>
                                </Form.Row>
                            </div>
                            { fecha.format('DD MMMM YYYY') }
                            <div className="row"> { (this.state.detail) ? this.drawDetail() : '' } </div>
                            <div className="row"> {  } </div>
                        </div>
                </div>
            </div>
        )
    }
    render(){
        return(
            this.renderDash()
        )
    };
}

export default DiscountClasses;