import React from 'react';

const PersonIcon= ({color, width, height, classNameName = null})=>{
    return(
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width={width} 
            height={height}
            fill="none" 
            viewBox="0 0 233.78 233.78"
        >
            <circle 
                stroke={color}
                strokeWidth='12px'
                cx="943.05" 
                cy="428.85" 
                r="110.89" 
                transform="translate(-830.49 -302.32) rotate(-0.58)"
            />
                <path
                    strokeLinejoin='round'
                    strokeLinecap='round'
                    fill='none' 
                    stroke={color}
                    strokeWidth='6px'
                    className="cls-2" 
                    d="M884.53,490.45a59,59,0,0,1,117.93,0" 
                    transform="translate(-826.16 -311.96)"
                />
                <path
                    strokeLinejoin='round'
                    strokeLinecap='round'
                    fill='none' 
                    stroke={color}
                    strokeWidth='6px'
                    className="cls-2" 
                    d="M971.25,387a27.75,27.75,0,1,1-27.75-27.75A27.76,27.76,0,0,1,971.25,387Z" 
                    transform="translate(-826.16 -311.96)"
                />
        </svg>
    )
}
export default PersonIcon;
