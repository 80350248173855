import React from 'react';
import PersonIcon from './PersonIcon';
import LogoIcon from './LogoIcon';


const Icon= ({iconName, color, width, height, className = null})=>{
    return(
        <div>
            { iconName === "PersonIcon" ?
                <PersonIcon
                    color={color}
                    width={width}
                    height={height}
                    className={className}
                />
            : iconName === "LogoIcon" ?
                <LogoIcon
                    color={color}
                    width={width}
                    height={height}
                    className={className}
                />
            : null }
        </div>
    )
}
export default Icon;
