import React, { Component } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from 'react-router-dom';
import Loading from './components/Utils/Loading';
import Login from './containers/Login/Login';
import LoginUser from './containers/LoginUser';
import MesasanaLogin from './containers/MesasanaLogin';
import MesasanaSignup from './containers/MesasanaSignup';
import SignUpUser from './containers/SignUpUser';
import RecoverPassword from './containers/RecoverPassword';
import Dashboard from './containers/Dashboard/Dashboard';
import Home from './containers/Home'
import Packages from "./containers/Packages";
import Client from './containers/Client';
import Promos from './containers/Promos';
import Users from './containers/Users';
import Classes from './containers/Classes';
import Subscriptions from './containers/Subscriptions';
import ClassDetail from './containers/Classes/Detail';
import Privacy from './containers/Privacy';
import Terms from './containers/Terms';
import Rules from './containers/Rules';
import Routines from './containers/Routines/';
import Contact from './containers/Contact'
import Press from './containers/Press';
import Reportes from './containers/Reportes'
import axios from 'axios';
import moment from "moment";
import WalkIn from './containers/WalkIn'
import InfoAlumnas from './containers/InfoAlumnas';
import DiscountClasses from './containers/DiscountClasse';

import './App.css';
import "react-datepicker/dist/react-datepicker.css";

class App extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            is_logged: false,
            user: null,
            type : null,
            profile : null,
            clases : [],
            live : 0,
            membership : null
        };
    }

    async authVerify() {
        return await axios.post('/api/user/valid')
            .then(async res => {
                if (res) {
                    let result = res.data;
                    this.setState({
                        is_logged: result.auth,
                        user: result.user,
                        type : result.type,
                        loading: false
                    });
                    if(result.auth) {
                        await this.getProfile()
                        await this.getUserClases()
                        await this.getUserMemberships()
                    }
                }
            }).catch(err => {
                console.error(err);
                this.setState({
                    loading: false
                });
            });
    }

    validateRoute(elem, permiso) {
        return this.state.is_logged && this.state.type <= permiso
          ? elem
          : (<Redirect to={{ pathname: '/' }}/>);
    }

    validateUserRoute(elem) {
        return this.state.is_logged && this.state.type === 4 ?
                elem
            : this.state.is_logged && this.state.type !== 4 ?
                <Redirect to={{ pathname: '/web' }}/>
            : (<Redirect to={{ pathname: '/' }}/>);
    }

    async getProfile () {
        let data = {
            username : this.state.user
        }
        await axios.post("/api/user/profile", data)
            .then(  async (res) => {
                if(res.data.Success){
                    this.setState({profile : res.data.profile})
                }
            }).catch( (error) => {
            })
    }

    getUserClases = async () => {
        let data = {
            user : this.state.user
        }
        await axios.post("/api/user/clases", data)
            .then(  (res) => {
                if(res.data.Success){
                    this.setState({
                        clases : res.data.clases,
                    })
                }
            }).catch( (error) => {
            })
    }

    getUserMemberships = async () => {
        let data = {
            user : this.state.user
        }
        await axios.post("/api/user/membership", data)
            .then(  (res) => {
                if(res.data.Success){
                    this.setState({membership : res.data.membership})
                }
            }).catch( (error) => {
            })
    }

    componentDidMount() {
        this.authVerify();
    }

    render() {
        if(this.state.loading)
          return <Loading/>;

        return <Router>
            <Switch>
                <Route exact path="/">
                    {this.state.is_logged
                        ? (<Redirect to={{ pathname: '/home' }}/>)
                        : <LoginUser/>
                    }
                </Route>
                <Route exact path="/signup">
                    {this.state.is_logged
                        ? (<Redirect to={{ pathname: '/home' }}/>)
                        : <SignUpUser/>
                    }
                </Route>

                <Route exact path="/mesasana">
                    {this.state.is_logged
                        ? (<Redirect to={{ pathname: '/' }}/>)
                        : <MesasanaLogin/>
                    }
                </Route>

                <Route exact path="/mesasana/signup">
                    {this.state.is_logged
                        ? (<Redirect to={{ pathname: '/' }}/>)
                        : <MesasanaSignup/>
                    }
                </Route>

                <Route exact path="/recoverPassword">
                    {this.state.is_logged
                        ? (<Redirect to={{ pathname: '/home' }}/>)
                        : <RecoverPassword/>
                    }
                </Route>

                <Route exact path="/home">
                    {this.validateUserRoute(
                        <Home
                            user={this.state.user}
                            username={this.state.user}
                            type={this.state.type}
                            profile={this.state.profile}
                            clases={this.state.clases}
                            live={this.state.live}
                            membership={this.state.membership}
                            dueDate={this.state.dueDate}
                        />)}
                </Route>
                <Route exact path="/packages">
                    {this.validateUserRoute(
                        <Packages
                            user={this.state.user}
                            username={this.state.user}
                            type={this.state.type}
                            profile={this.state.profile}
                            clases={this.state.clases}
                            live={this.state.live}
                            membership={this.state.membership}
                            dueDate={this.state.dueDate}
                        />)}
                </Route>
                <Route exact path="/admin">
                    {this.state.is_logged
                        ? (<Redirect to={{ pathname: '/web' }}/>)
                        : <Login/>
                    }
                </Route>
                <Route path="/privacy">
                    <Privacy/>
                </Route>
                <Route path="/terms">
                    <Terms/>
                </Route>
                <Route path="/rules">
                    <Rules/>
                </Route>

                <Route path="/press">
                    {this.validateRoute(
                        <Press
                            user={this.state.user}
                            type={this.state.type}
                        />, 1)}
                </Route>

                <Route path="/web">
                    {this.state.is_logged ?
                        this.validateRoute(
                            <Dashboard
                                user={this.state.user}
                                type={this.state.type}
                            />, 3)
                    :
                        <Login/>
                    }

                </Route>
                <Route path="/client">
                    {this.validateRoute(
                        <Client
                            user={this.state.user}
                            type={this.state.type}
                        />, 1)}
                </Route>
                <Route path="/promos">
                    {this.validateRoute(
                        <Promos
                            user={this.state.user}
                            type={this.state.type}
                        />, 1)}
                </Route>
                <Route path="/routines">
                    {this.validateRoute(
                        <Routines
                            user={this.state.user}
                            type={this.state.type}
                        />, 1)}
                </Route>
                <Route path="/admins">
                    {this.validateRoute(
                        <Users
                            user={this.state.user}
                            type={this.state.type}
                            select="admin"
                        />, 1)}
                </Route>
                <Route path="/partner">
                    {this.validateRoute(
                        <Users
                            user={this.state.user}
                            type={this.state.type}
                            select="partner"
                        />, 1)}
                </Route>
                <Route path="/receptionist">
                    {this.validateRoute(
                        <Users
                            user={this.state.user}
                            type={this.state.type}
                            select="receptionist"
                        />, 1)}
                </Route>
                <Route path="/classes" exact>
                    {this.validateRoute(
                        <Classes
                            user={this.state.user}
                            type={this.state.type}
                            select="classes"
                            profile={this.state.profile}
                        />, 3)}
                </Route>
                <Route path="/subscription" exact>
                    {this.validateRoute(
                        <Subscriptions
                            user={this.state.user}
                            type={this.state.type}
                        />, 1)}
                </Route>
                <Route path="/contact" exact>
                    <Contact />
                </Route>
                <Route path="/class/detail/:clase" exact render={
                    props => {
                        return this.validateRoute(
                            <ClassDetail
                                user={this.state.user}
                            type={this.state.type}
                                clase={props.match.params.clase}
                            />, 3)
                    }
                }/>

                <Route path="/walk-in">
                    {
                        this.validateRoute(<WalkIn
                            user={this.state.user}
                            type={this.state.type}
                            select="walk-in"
                        />,3)
                    }

                </Route>
                <Route path="/infoalumnas">
                    {
                        this.validateRoute(
                            <InfoAlumnas
                                user={this.state.user}
                                type={this.state.type}
                                select="InfoAlumnas"
                            />,3
                        )
                    }
                </Route>
                <Route path="/discountClase">
                    {
                        this.validateRoute(
                            <DiscountClasses
                            user={this.state.user}
                            type={this.state.type}
                            select="discountclasse"/>,3
                        )
                    }
                </Route>
                <Route path="/reporte/ingresos">
                    {this.validateRoute(<
                        Reportes
                        user={this.state.user}
                            type={this.state.type}
                        select="reporte_ingresos"
                        reporte={1}
                    />, 1)}
                </Route>
                <Route path="/reporte/lives">
                    {this.validateRoute(
                        <Reportes
                            user={this.state.user}
                            type={this.state.type}
                            select="reporte_ingresos"
                            reporte={1}
                        />, 1)}
                </Route>
                <Route path="/reporte/usuarios">
                    {this.validateRoute(
                        <Reportes
                            user={this.state.user}
                            type={this.state.type}
                            select="reporte_usuarios"
                            reporte={2}
                        />, 1)}
                </Route>
                <Route path="/reporte/redenciones">
                    {this.validateRoute(
                        <Reportes
                            user={this.state.user}
                            type={this.state.type}
                            select="reporte_codigos"
                            reporte={4}
                        />, 1)}
                </Route>
                <Route path="/reporte/analisisVideos">
                    {this.validateRoute(
                        <Reportes
                            user={this.state.user}
                            type={this.state.type}
                            select="reporte_analisisvideos"
                            reporte={5}
                        />, 1)}
                </Route>
                <Route path="/reporte/reporteGoGlow">
                    {this.validateRoute(
                        <Reportes
                            user={this.state.user}
                            type={this.state.type}
                            select="reporte_goglow"
                            reporte={6}
                        />, 1)}
                </Route>
                <Route path="/reporte/ingresosReporteSistemas">
                    {this.validateRoute(
                        <Reportes
                            user={this.state.user}
                            type={this.state.type}
                            select="reporte_ingresos_sistemas"
                            reporte={7}
                        />, 1)}
                </Route>
                <Route path="/reporte/presenciales">
                    {this.validateRoute(
                        <Reportes
                            user={this.state.user}
                            type={this.state.type}
                            select="reporte_presenciales"
                            reporte={8}
                        />, 1)}
                </Route>
                
                <Route path="/">
                    <Redirect to={{ pathname: '/web' }}/>)
                </Route>
            </Switch>
        </Router>;
    }
}

export default App;
