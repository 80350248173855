import React, {Component} from 'react';
import axios from "axios";

class PaymentCard extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount () {
        //console.log("no")
        this.initPayment();
    }

    generateToken = async () => {
        // Generar token
        var chars =
            "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
        var token = "";
        for (var i = 0; i < 10; i++) {
            token += chars[Math.floor(Math.random() * chars.length)];
        }
        return token;
    }

    addPayment = async (checkoutId, redirect) => {
        //console.log(this.props.username)
        let dataPago = {
            username: this.props.username,
            pkg: (this.props.selectedPackage) ? this.props.selectedPackage : this.props.selectedMembership,
            subtotal: this.props.ammount,
            discount: this.props.discount,
            total : this.props.ammount - this.props.discount,
            code: this.props.discountID,
            currency: this.props.currency,
            description: this.props.description,
            paymentId: checkoutId,
            PayerID: null,
            paymentData : {
                checkoutId : checkoutId,
            },
            paymentMethod : 2,
            isMembership : (this.props.selectedPackage) ? false : true,
            status : 0
        }
        axios.post("/api/payment/addPayment", dataPago).then(response => {
            window.location.href = redirect
        })
    }

    initPayment = async () => {

        let items = [this.props.description]
        //let items = "Clase Live Ilimitadas y On Demand, 30 días"
        if(this.props.discount){
            items.push(
                "Descuento de -$" + (Math.round((this.props.discount) * 100) / 100).toFixed(2)
            )
        }
        let data = {
            externalId : await this.generateToken(),
            items : items,
            total : this.props.ammount - this.props.discount
        };
        axios.post('/api/payment/getBillpocketUrl', data)
            .then(response => {
                let data = response.data.token
                this.addPayment(data.checkoutId, data.url)
            }).catch(err => {
            //console.log({ ...err }, 3)
        })
    }


    render() {

        return (
            <div className="packages">

            </div>
        );

    }
}

export default PaymentCard;
