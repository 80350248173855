import React, { Component } from 'react';
import Sidebar from '../../components/Sidebar';
import Navigation from '../../components/Navigation/Navigation';
import ModalAdd from '../../components/Admin/ModalAdd';
import ModalBan from '../../components/Admin/ModalBan';
import ModalEdit from '../../components/Admin/ModalEdit';
import axios from 'axios';
import './styleRoutines.css';

class Routines extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading : true,
            search: '',
            routines: [],
            show: false,
            showBan: false,
            close: false,
            typeUser: 4,
            idClient: '',
            nameClient: '',
            status: '',
            birthdate: '',
            dateClient: '',
            studio: ''
        };
        this.getPromos = this.getPromos.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.handleShowBan = this.handleShowBan.bind(this);
        this.handleShowEdit = this.handleShowEdit.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.convertDateToString = this.convertDateToString.bind(this);
    }

    componentDidMount() {
        this.getPromos();
    }

    getPromos = async () => {
        return await axios.get('/api/routines/getAllVideos')
            .then(res => {
                this.setState({ routines: res.data.videos, loading: false });
            })
            .catch(err => {
                //console.log('error')
                this.setState({ loading: false });
            });
    };

    handleShow = async (e) => {
        await this.setState({
            show: true
        });
    };

    handleShowBan= async (e, id, name, status) => {
        await this.setState({
            showBan: true,
            idRout: id,
            nameRout: name,
            status: status
        });
    };

    handleShowEdit= async (e, id, name, desc) => {
        await this.setState({
            showEdit: true,
            idRout: id,
            nameRout: name,
            description: desc
        });
    };

    handleClose(e) {
        this.setState({ show: false, showBan: false, showEdit: false });
    }

    convertDateToString(date) {
        var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
        return [year, month, day].join('-');
    }

    renderObject() {
        if (this.state.routines.length === 0) return null;
        let routines = this.state.routines;
        return routines.map((item, index) => {
            return (
                <tr key={index} className="clientes-body">
                    <td>{item.name}</td>
                    <td>
                        {item.description ? item.description : '--'}
                    </td>
                    <td>
                        {item['instructor.fullname']}
                    </td>
                    <td>
                        <a href={item.url}>{item.url}</a>
                    </td>
                    <td>
                        <button
                            className="btn-normal ban space-btn"
                            onClick={(e) => this.handleShowEdit(e, item.id, item.name, item.description)}
                        >
                            Editar
                        </button>
                        <button
                            className="btn-normal delete"
                            onClick={(e) => this.handleShowBan(e, item.id, item.name, item.status)}
                        >
                            Borrar
                        </button>
                    </td>
                </tr>
            );
        });
    }

    renderDash() {
        return (
          <div className="content">
                <Navigation user={this.props.user}/>
                <Sidebar
                    active="routines"
                    type={this.props.type}
                />
                <div className="main">
                    <h4 className="client">Rutinas</h4>
                    <div className="btn-add-div">
                        <button
                            className="btn-normal add"
                            onClick={this.handleShow}
                        >
                            Crear Rutina
                        </button>
                    </div>
                    <div className="table-content content-routines">
                        <table>
                            <thead>
                            <tr>
                                <th>
                                    Nombre
                                </th>
                                <th>
                                    Descripci&oacute;n
                                </th>
                                <th>
                                    Instrutor
                                </th>
                                <th>
                                    URL
                                </th>
                                <th>
                                    Acciones
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.renderObject()}
                            </tbody>
                        </table>
                    </div>
                    <ModalAdd
                        name="Rutina"
                        show={this.state.show}
                        close={this.handleClose}
                        typeUser={this.state.typeUser}
                    />
                    <ModalBan
                        section="rutina"
                        id={this.state.idRout}
                        name={this.state.nameRout}
                        status={this.state.status === 0 ? 1 : 0}
                        nameCategorie={this.state.status === 0 ? 'activar' : 'borrar'}
                        show={this.state.showBan}
                        close={this.handleClose}
                        typeUser={this.state.typeUser}
                    />
                    <ModalEdit
                        id={this.state.idRout}
                        name={this.state.nameRout}
                        description={this.state.description}
                        nameCategorie={'Editar Rutina'}
                        show={this.state.showEdit}
                        close={this.handleClose}
                    />
                </div>
            </div>
        );
    }

    render() {
        return this.renderDash();
    };

}

export default Routines;
